import React from 'react';
import classnames from 'classnames';
import { Icon, IconName, IconSize, IconTheme } from '../Icon/Icon';
import './Button.scss';

export type ButtonClick = (e: React.SyntheticEvent) => void;

interface ButtonProps {
  children?: React.ReactNode;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'account' | 'stripped';
  buttonName?: string;
  className?: string;
  disabled?: boolean;
  iconName?: IconName;
  iconSize?: IconSize;
  iconTheme?: IconTheme;
  onClick?: ButtonClick;
  type?: 'button' | 'submit' | 'reset';
}

export default function Button(props: ButtonProps) {
  const {
    children,
    buttonType = 'secondary',
    buttonName,
    className,
    disabled = false,
    iconName,
    iconSize,
    iconTheme,
    onClick,
    type = 'button',
  } = props;

  return (
    <button
      name={buttonName}
      type={type}
      onClick={onClick}
      disabled={disabled}
      tabIndex={0}
      className={classnames('button', className, {
        'button--primary': buttonType === 'primary',
        'button--secondary': buttonType === 'secondary',
        'button--tertiary': buttonType === 'tertiary',
        'button--account': buttonType === 'account',
        'button--stripped': buttonType === 'stripped',
        'button--with-icon': iconName,
      })}
    >
      {iconName && (
        <Icon
          name={iconName}
          size={iconSize}
          className="button__icon"
          theme={buttonType === 'account' ? 'glow' : iconTheme}
        />
      )}
      <span className="button__label">{children}</span>
    </button>
  );
}
