import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as ExhibitionActor } from '../../machines/ExhibitionMachine';
import { ChatConvo } from '../Chat/ChatConvo';
import { Loader } from '../Loader/Loader';
import { ExhibitionHeading } from '../Exhibition/ExhibitionHeading';
import { ChatStreamItem } from './ChatStreamItem';
import { LeaderboardList } from './LeaderboardList';
import { LeaderboardItem } from './LeaderboardItem';
import { ScrollToBottom } from '../ScrollToBottom/ScrollToBottom';

import './Exhibition.scss';

export function Exhibition({ actor }: { actor: ExhibitionActor }) {
  const [state] = useActor(actor);
  const { t } = useTranslation();

  const messages = state.context.messages;
  const hots = state.context.hots;
  const nots = state.context.nots;

  switch (true) {
    case state.matches('hot.loading'):
    case state.matches('chat.loading'):
      return <Loader theme="full-page" />;

    case state.matches('chat.ready'):
      return (
        <main className="exhibition">
          <ScrollToBottom />
          <div className="chat-panel">
            <div className="chat-wrapper">
              {messages
                .sort((a, b) => {
                  return b.time.getTime() - a.time.getTime();
                })
                .map((m, i) => {
                  return (
                    <ChatConvo className="exhibition-body" key={i}>
                      <ChatStreamItem
                        children={m.body}
                        className="exhibition-item"
                      />
                    </ChatConvo>
                  );
                })}
            </div>
          </div>
        </main>
      );

    case state.matches('hot.ready'):
      return (
        <main className="exhibition">
          <div className="hot-or-not-panel">
            <div className="hot-or-not-wrapper">
              <ExhibitionHeading iconName="thumbs-up">
                <strong>{t('profile.rating.hot')}</strong>{' '}
                {t('exhibition.top5')}
              </ExhibitionHeading>
              <LeaderboardList>
                {hots.map((p, i) => {
                  return (
                    <LeaderboardItem
                      key={i}
                      name={p.name}
                      image={p.images[0].sm}
                    />
                  );
                })}
              </LeaderboardList>
            </div>
            <div className="hot-or-not-wrapper">
              <ExhibitionHeading iconName="thumbs-down">
                <strong>{t('profile.rating.not')}</strong>{' '}
                {t('exhibition.top5')}
              </ExhibitionHeading>
              <LeaderboardList>
                {nots.map((p, i) => {
                  return (
                    <LeaderboardItem
                      key={i}
                      name={p.name}
                      image={p.images[0].sm}
                    />
                  );
                })}
              </LeaderboardList>
            </div>
          </div>
        </main>
      );

    default:
      return null;
  }
}
