import React from 'react';
import './Form.scss';

interface FormProps {
  children?: React.ReactNode;
  className?: string;
  onSubmit?: (e: React.SyntheticEvent) => void;
}

export function Form({ children, className, onSubmit }: FormProps) {
  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) {
          onSubmit(e);
        }
      }}
    >
      {children}
    </form>
  );
}
