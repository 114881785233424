import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import { Gallery } from './Gallery';
import Button from '../Button/Button';
import { StatusTag } from '../StatusTag/StatusTag';
import { Tag } from '../Tag/Tag';
import { GenderTag } from '../GenderTag/GenderTag';
import { SexualityTag } from '../SexualityTag/SexualityTag';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';
import { ProfileDescriptionItem } from './DescriptionItem';
import { Gender, Sexuality } from '../../types';
import './Profile.scss';

export function Selfie({ actor }: { actor: RomanceActor }) {
  const [state, send] = useActor(actor);
  const profile = state.context.profile;
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {t('title')} - {profile.name}
        </title>
      </Helmet>
      <ScrollToTop />
      <Gallery betweenCount={t('profile.imageCountOf')}>
        {profile.images.map((img) => (
          <img key={img.id} src={img.lg} alt={profile.name} />
        ))}
      </Gallery>
      <div className="profile__selfie-buttons">
        <Button
          buttonType="tertiary"
          className="profile__selfie-button"
          onClick={() => send({ type: 'editPhotos' })}
        >
          {t('profile.selfie.editPhotos')}
        </Button>
        <Button
          buttonType="tertiary"
          className="profile__selfie-button"
          onClick={() => send({ type: 'editProfile' })}
        >
          {t('profile.selfie.editProfile')}
        </Button>
      </div>
      <div className="profile__content">
        <h2 className="profile__heading">
          <span className="profile__heading-name heading">{profile.name}</span>
          <StatusTag
            className="profile__heading-status"
            statusState={profile.isDead ? 'offline' : 'user'}
          >
            {t('profile.meta.currentUser')}
          </StatusTag>
        </h2>
        {profile.headline && (
          <p className="profile__headline item-heading">{profile.headline}</p>
        )}
        <ul className="profile__stats">
          {!Number.isNaN(profile.age) && (
            <li className="profile__stats-item">
              <Tag>
                {profile.age} {t('profile.meta.ageSuffix')}
              </Tag>
            </li>
          )}
          {profile.gender !== Gender.none && (
            <li className="profile__stats-item">
              <Tag>
                <GenderTag gender={profile.gender} />
              </Tag>
            </li>
          )}
          {profile.sexuality !== Sexuality.none && (
            <li className="profile__stats-item">
              <Tag>
                <SexualityTag sexuality={profile.sexuality} />
              </Tag>
            </li>
          )}
        </ul>
      </div>
      <ul className="profile-description">
        {profile.who && (
          <ProfileDescriptionItem itemLabel={t('profile.description.whoLabel')}>
            {profile.who}
          </ProfileDescriptionItem>
        )}
        {profile.what && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.whatLabel')}
          >
            {profile.what}
          </ProfileDescriptionItem>
        )}
        {profile.why && (
          <ProfileDescriptionItem itemLabel={t('profile.description.whyLabel')}>
            {profile.why}
          </ProfileDescriptionItem>
        )}
        {profile.how && (
          <ProfileDescriptionItem itemLabel={t('profile.description.howLabel')}>
            {profile.how}
          </ProfileDescriptionItem>
        )}
        {profile.when && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.whenLabel')}
          >
            {profile.when}
          </ProfileDescriptionItem>
        )}
        {profile.else && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.elseLabel')}
          >
            {profile.else}
          </ProfileDescriptionItem>
        )}
      </ul>
      <div className="profile__last-login">
        <p>
          {t('profile.loginDate')}{' '}
          {profile.lastOnline
            ? t('date.lastLogin', { date: profile.lastOnline })
            : t('profile.loginUnavailable')}
        </p>
      </div>
    </div>
  );
}
