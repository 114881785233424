import React from 'react';
import './LeaderboardItem.scss';
import classNames from 'classnames';

interface LeaderboardItemProps {
  className?: string;
  image: string;
  name: string;
}

export function LeaderboardItem(props: LeaderboardItemProps) {
  const { image, name, className } = props;

  return (
    <li className={classNames(className, 'leaderboard-item')}>
      <span className="leaderboard-item__avatar">
        <img
          className="leaderboard-item__avatar-image"
          src={image}
          alt={name}
        />
      </span>
      <span className="leaderboard-item__name">{name}</span>
    </li>
  );
}
