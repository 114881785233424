import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as PreludeActor } from '../../machines/PreludeMachine';
import { Persona } from '../Persona/Persona';
import { HeaderMarketing } from '../Header/HeaderMarketing';
import { FauxneHeader } from '../Header/FauxneHeader';
import { Loader } from '../Loader/Loader';

import './Prelude.scss';

export function Prelude({ actor }: { actor: PreludeActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();
  const time = state.context.time;

  switch (true) {
    case state.matches('set.ready'):
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <HeaderMarketing signOutClick={() => send({ type: 'done' })} />
            <main className="prelude page">
              <div className="prelude__introduction">
                <h1 className="heading prelude__title">{t('prelude.title')}</h1>
                <p className="intro prelude__description">
                  {t('prelude.description')}
                </p>
              </div>
              <ul>
                {state.context.profiles.map((profile) => (
                  <Persona
                    key={profile.id}
                    name={profile.name}
                    image={profile.images[0].sm}
                    age={profile.age}
                    gender={profile.gender}
                    sexuality={profile.sexuality}
                    tagIcon="chat"
                    tagContent={profile.unreadCount}
                    frequency={profile.order}
                    onClick={() => send({ type: 'setProfile', profile })}
                  />
                ))}
              </ul>
            </main>
          </div>
        </div>
      );

    default:
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <Loader theme="full-page" />
          </div>
        </div>
      );
  }
}
