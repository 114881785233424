import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as DeleteProfileActor } from '../../machines/DeleteProfileMachine';

import Button from '../Button/Button';

import { Confirmation } from '../Confirmation/Confirmation';
import { Feedback } from '../Feedback/Feedback';
import { Loader } from '../Loader/Loader';

export function DeleteProfile({ actor }: { actor: DeleteProfileActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  switch (true) {
    case state.matches('confirm'):
      return (
        <Confirmation pageType="wide-button" className="confirmation-delete">
          <h1 className="delete-profile__heading">
            {t('deleteProfile.confirm.title')}
          </h1>
          <p>{t('deleteProfile.confirm.intro')}</p>
          {state.matches('confirm.error') && (
            <Feedback>{t('deleteProfile.confirm.error')}</Feedback>
          )}
          <Button
            buttonType="primary"
            onClick={() => send({ type: 'confirmDelete' })}
          >
            {t('deleteProfile.confirm.buttonConfirm')}
          </Button>
          <Button
            buttonType="secondary"
            onClick={() => send({ type: 'cancelDelete' })}
          >
            {t('deleteProfile.confirm.buttonDeny')}
          </Button>
        </Confirmation>
      );

    case state.matches('deleting'):
      return <Loader theme="full-page" />;

    case state.matches('done'):
      return (
        <Confirmation className="delete-profile">
          <h1 className="delete-profile__heading">
            {t('deleteProfile.confirmed.title')}
          </h1>
          <p>{t('deleteProfile.confirmed.intro')}</p>
          <Button
            buttonType="secondary"
            onClick={() => send({ type: 'finishDelete' })}
          >
            {t('deleteProfile.confirmed.buttonBack')}
          </Button>
        </Confirmation>
      );

    default:
      return null;
  }
}
