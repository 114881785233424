import moment from 'moment';
import { createMachine, assign, ActorRefFrom, send, sendParent } from 'xstate';
import { assertEventType, withDelay } from './utils';
import { ProfileWithAge, Gender, Sexuality, Store } from '../types';
import {
  Context as RomanceContext,
  UpdateContext,
  DeleteProfile,
} from './RomanceMachine';
import {
  machine as uploadPhotoMachine,
  createServices as createUploadPhotoServices,
  mapParentContextToContext as mapContextToUploadPhotoMachine,
} from './UploadPhotoMachine';

const MIN_AGE = 18;

export function mapParentContextToContext(context: RomanceContext): Context {
  return {
    time: context.time,
    is2Fik: context.user.is2Fik,
    profile: context.profile,
    born: context.profile.born?.toISOString().substr(0, 10) || '',
  };
}

export interface Context {
  time: Date;
  is2Fik: boolean;
  profile: ProfileWithAge;
  born: string;
}

interface SetName {
  type: 'setName';
  name: string;
}

interface SetHeadline {
  type: 'setHeadline';
  headline: string;
}

interface SetWho {
  type: 'setWho';
  who: string;
}

interface SetWhy {
  type: 'setWhy';
  why: string;
}

interface SetHow {
  type: 'setHow';
  how: string;
}

interface SetWhat {
  type: 'setWhat';
  what: string;
}

interface SetWhen {
  type: 'setWhen';
  when: string;
}

interface SetElse {
  type: 'setElse';
  else: string;
}

interface SetGender {
  type: 'setGender';
  gender: Gender;
}

interface SetSexuality {
  type: 'setSexuality';
  sexuality: Sexuality;
}

interface SetBorn {
  type: 'setBorn';
  born: string;
}

interface SaveProfile {
  type: 'saveProfile';
}

interface EditProfile {
  type: 'editProfile';
}

type Event =
  | SetName
  | SetHeadline
  | SetWho
  | SetWhy
  | SetHow
  | SetWhat
  | SetWhen
  | SetElse
  | SetGender
  | SetSexuality
  | SetBorn
  | UpdateContext
  | SaveProfile
  | EditProfile
  | DeleteProfile;

export const machine = createMachine<Context, Event>(
  {
    id: 'editProfileMachine',
    initial: 'edit',
    states: {
      edit: {
        type: 'parallel',
        states: {
          photo: {
            initial: 'init',
            states: {
              init: {
                always: [
                  { target: 'done', cond: 'hasPhotos' },
                  { target: 'ready' },
                ],
              },
              ready: {
                invoke: {
                  id: 'uploadPhotoMachine',
                  src: 'uploadPhotoMachine',
                  onDone: 'done',
                  data: (context, event) =>
                    mapContextToUploadPhotoMachine(
                      context,
                      { aspect: 1 },
                      true
                    ),
                },
                on: {
                  updateContext: { actions: 'sendToUploadPhotoMachine' },
                },
              },
              done: {
                type: 'final',
              },
            },
          },
          fields: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              setName: { target: '.valid', actions: 'assignName' },
              setHeadline: { actions: 'assignHeadline' },
              setWho: { actions: 'assignWho' },
              setWhy: { actions: 'assignWhy' },
              setHow: { actions: 'assignHow' },
              setWhat: { actions: 'assignWhat' },
              setWhen: { actions: 'assignWhen' },
              setElse: { actions: 'assignElse' },
              setGender: { actions: 'assignGender' },
              setSexuality: { actions: 'assignSexuality' },
              setBorn: { target: '.valid', actions: 'assignBorn' },
              deleteProfile: { actions: 'deleteProfile', cond: 'isNot2Fik' },
              updateContext: { actions: 'assignContext' },
            },
          },
        },
        on: {
          saveProfile: [
            { target: 'saving', cond: 'hasRequiredFields' },
            { target: '.fields.invalid' },
          ],
        },
      },
      saving: {
        invoke: {
          id: 'saveProfile',
          src: 'saveProfile',
          onDone: 'final',
          onError: 'failure',
        },
      },
      failure: {
        on: {
          editProfile: 'edit',
        },
      },
      final: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      assignName: assign({
        profile: (context, event) => {
          assertEventType(event, 'setName');
          return { ...context.profile, name: event.name };
        },
      }),
      assignHeadline: assign({
        profile: (context, event) => {
          assertEventType(event, 'setHeadline');
          return { ...context.profile, headline: event.headline };
        },
      }),
      assignWho: assign({
        profile: (context, event) => {
          assertEventType(event, 'setWho');
          return { ...context.profile, who: event.who };
        },
      }),
      assignWhy: assign({
        profile: (context, event) => {
          assertEventType(event, 'setWhy');
          return { ...context.profile, why: event.why };
        },
      }),
      assignHow: assign({
        profile: (context, event) => {
          assertEventType(event, 'setHow');
          return { ...context.profile, how: event.how };
        },
      }),
      assignWhat: assign({
        profile: (context, event) => {
          assertEventType(event, 'setWhat');
          return { ...context.profile, what: event.what };
        },
      }),
      assignWhen: assign({
        profile: (context, event) => {
          assertEventType(event, 'setWhen');
          return { ...context.profile, when: event.when };
        },
      }),
      assignElse: assign({
        profile: (context, event) => {
          assertEventType(event, 'setElse');
          return { ...context.profile, else: event.else };
        },
      }),
      assignGender: assign({
        profile: (context, event) => {
          assertEventType(event, 'setGender');
          return { ...context.profile, gender: event.gender };
        },
      }),
      assignSexuality: assign({
        profile: (context, event) => {
          assertEventType(event, 'setSexuality');
          return { ...context.profile, sexuality: event.sexuality };
        },
      }),
      assignBorn: assign({
        born: (context, event) => {
          assertEventType(event, 'setBorn');
          return event.born;
        },
      }),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');

        // We only want the images and upload status. Without this
        // we'll overwrite changes in state that our user may have edited.
        const { profile, time } = mapParentContextToContext(event.context);

        return {
          time,
          profile: {
            ...context.profile,
            images: profile.images,
            upload: profile.upload,
          },
        };
      }),
      deleteProfile: sendParent((context, event) => event),
      sendToUploadPhotoMachine: send(
        (context: Context, event: Event) => event,
        { to: 'uploadPhotoMachine' }
      ),
    },
    guards: {
      isNot2Fik: (context) => !context.is2Fik,
      hasRequiredFields: (context) => {
        const cutoff = moment(context.time).subtract(MIN_AGE, 'years');
        const born = moment(context.born, 'YYYY-MM-DD');

        return (
          context.profile.name !== '' && born.isValid() && born.isBefore(cutoff)
        );
      },
      hasPhotos: (context) => {
        return (
          context.profile.images.filter((i) => i.id !== 'default').length > 0
        );
      },
    },
  }
);

export function createServices(store: Store) {
  return {
    uploadPhotoMachine: uploadPhotoMachine.withConfig({
      services: createUploadPhotoServices(store),
    }),
    saveProfile: (context: Context) => {
      const document = {
        id: context.profile.id,
        name: context.profile.name,
        headline: context.profile.headline,
        who: context.profile.who,
        why: context.profile.why,
        how: context.profile.how,
        what: context.profile.what,
        when: context.profile.when,
        else: context.profile.else,
        gender: context.profile.gender,
        sexuality: context.profile.sexuality,
        born: moment(context.born, 'YYYY-MM-DD').toDate(),
        state: context.profile.state === '2fik' ? '2fik' : 'user',
      };

      return withDelay(store.updateDocument('profiles', document), store.delay);
    },
  };
}

export type Actor = ActorRefFrom<typeof machine>;
