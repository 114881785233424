import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import Button from '../Button/Button';
import { Confirmation } from '../Confirmation/Confirmation';

export function SignUpReminder({ actor }: { actor: RomanceActor }) {
  const [, send] = useActor(actor);
  const { t } = useTranslation();

  return (
    <main className="signup">
      <Confirmation pageType="full">
        <h1 className="heading">{t('reminder.titleSignUp')}</h1>
        <p>{t('reminder.bodySignUp')}</p>
        <Button
          buttonType="primary"
          onClick={() => send({ type: 'viewBrowse' })}
        >
          {t('reminder.buttonTitle')}
        </Button>
      </Confirmation>
    </main>
  );
}
