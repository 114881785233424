import React from 'react';
import './LeaderboardList.scss';
import classNames from 'classnames';

interface LeaderboardListProps {
  children?: React.ReactNode;
  className?: string;
}

export function LeaderboardList(props: LeaderboardListProps) {
  const { children, className } = props;

  return (
    <ol className={classNames(className, 'leaderboard-list')}>{children}</ol>
  );
}
