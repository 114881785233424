import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as ChatActor } from '../../machines/ChatMachine';
import { HeaderOverlay } from '../Header/HeaderOverlay';
import { ChatConvo } from './ChatConvo';
import { ChatAlert } from './ChatAlert';
import { ChatBubble } from './ChatBubble';
import { ChatReceipt } from './ChatReceipt';
import { ChatInput } from './ChatInput';
import { ChatLoader } from './ChatLoader';
import { ChatMeta } from './ChatMeta';
import { TextInput } from '../Form/TextInput';
import { Feedback } from '../Feedback/Feedback';
import './Chat.scss';

function isNewDay(d1: Date, d2: Date) {
  return (
    d1.getUTCDate() !== d2.getUTCDate() ||
    d1.getUTCMonth() !== d2.getUTCMonth() ||
    d1.getUTCFullYear() !== d2.getUTCFullYear()
  );
}

function isBetween(
  before: Date,
  between?: Date,
  after?: Date
): between is Date {
  return (
    between !== undefined &&
    before.getTime() <= between.getTime() &&
    (after === undefined || between.getTime() <= after.getTime())
  );
}

export function Chat({ actor }: { actor: ChatActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();
  const body = state.context.body;
  const profile = state.context.profile;
  const messages = state.context.messages;
  const lastRead = state.context.lastRead?.time;
  const hasChat = state.context.hasChat;

  return (
    <main className="chat">
      <Helmet>
        <title>
          {t('title')} - {profile.name}
        </title>
      </Helmet>
      <div className="chat-panel">
        <HeaderOverlay
          profileIcon={true}
          profileIconAvatar={profile.images[0].xs}
          profileIconName={profile.name}
          userStatus={profile.status}
          profileClick={() => send({ type: 'viewProfile', profile })}
          onClick={() => send({ type: 'closePanel' })}
        >
          {profile.name}
        </HeaderOverlay>
        <div className="chat-wrapper">
          <ChatConvo className="chat-panel__convo">
            {messages.map((m, i, ms) => (
              <div
                className={
                  'chat-panel__item ' +
                  (m.from === profile.id
                    ? 'chat-panel--reciever'
                    : 'chat-panel--sender')
                }
                key={i}
              >
                {(i === 0 || isNewDay(m.time, ms[i - 1].time)) && (
                  <ChatMeta>{t('date.chat', { date: m.time })}</ChatMeta>
                )}
                <ChatBubble
                  className="chat-panel__bubble"
                  type={m.from === profile.id ? 'reciever' : 'sender'}
                >
                  {m.body}
                </ChatBubble>
                {isBetween(
                  m.time,
                  lastRead,
                  i < ms.length - 1 ? ms[i + 1].time : undefined
                ) && (
                  <ChatReceipt>
                    {t('date.read', { date: lastRead })}
                  </ChatReceipt>
                )}
              </div>
            ))}
            {
              // Hide the typing indicator in the rare case of the user
              // navigates away and the lose focus event doesn't fire.
              // This way we'll at least not show the typing indicator
              // while the user is offline.
              state.matches('typingIndicator.on') &&
                hasChat &&
                profile.isOnline && <ChatLoader />
            }
            {profile.isBlockingYou && (
              <ChatAlert
                iconName="heartbreak"
                alertTitle={t('chat.blockedChatStrong')}
                alertDescription={t('chat.blockedChatTitle')}
              />
            )}
            {!hasChat && (
              <ChatAlert
                iconName="block"
                alertTitle={t('chat.disabledChatStrong')}
                alertDescription={t('chat.disabledChatTitle')}
              />
            )}
          </ChatConvo>
        </div>
        <div className="chat-panel__input">
          {state.matches('message.error') && (
            <Feedback msgType="strong" className="chat-panel__feedback">
              {t('chat.chatError')}
            </Feedback>
          )}
          <ChatInput
            disabled={profile.isBlockingYou}
            onSubmit={() => send({ type: 'save' })}
          >
            <TextInput
              type="text"
              placeholder={t('chat.chatInput')}
              className="chat-input__input"
              value={body}
              disabled={profile.isBlockingYou || !hasChat}
              fauxDisabled={state.matches('message.saving')}
              onFocus={() => send({ type: 'startTyping' })}
              onBlur={() => send({ type: 'finishTyping' })}
              onChange={(event) => {
                send({ type: 'setBody', body: event.target.value });
              }}
            />
          </ChatInput>
        </div>
      </div>
    </main>
  );
}
