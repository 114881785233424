import React from 'react';
import './ProfileIcon.scss';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
import { ProfileStatus } from '../../types';

export type UserProfileStatus = ProfileStatus;
export type UserProfileName = string;

interface IconProps {
  className?: string;
  image?: string;
  name?: UserProfileName;
  userStatus?: ProfileStatus;
  onClick?: (e: React.SyntheticEvent) => void;
}

export function ProfileIcon(props: IconProps) {
  const { className, image, name, userStatus, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={classNames(className, 'profile-icon', {
        'profile-icon--online': userStatus === 'online',
        'profile-icon--inactive': userStatus === 'inactive',
        'profile-icon--offline': userStatus === 'offline',
      })}
    >
      <div className="profile-icon__image-container">
        <img className="profile-icon__image" src={image} alt={name} />
      </div>
      <Icon
        size="sm"
        name="circle"
        theme="default"
        className="profile-icon__icon"
      />
    </button>
  );
}
