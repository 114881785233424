import { createMachine, ActorRefFrom } from 'xstate';
import { assertEventType, withDelay } from './utils';
import { Store } from '../types';

export interface Context {
  token: string;
}

interface CancelOptOut {
  type: 'cancelOptOut';
}

interface FinishOptOut {
  type: 'finishOptOut';
}
interface SaveOptOut {
  type: 'saveOptOut';
}

type Event = CancelOptOut | FinishOptOut | SaveOptOut;

export const machine = createMachine<Context, Event>(
  {
    id: 'optOutMachine',
    initial: 'confirm',
    states: {
      confirm: {
        on: {
          saveOptOut: 'optingOut',
          cancelOptOut: 'final',
        },
      },
      optingOut: {
        invoke: {
          id: 'saveOptOut',
          src: 'saveOptOut',
          onDone: { target: 'done', actions: 'cleanUpUrl' },
          onError: 'error',
        },
      },
      done: {
        on: {
          finishOptOut: 'final',
        },
      },
      error: {
        on: {
          finishOptOut: 'final',
        },
      },
      final: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      cleanUpUrl: () => {
        window.history.replaceState(null, '', '/');
      },
    },
  }
);

export function createServices(store: Store) {
  return {
    saveOptOut: (context: Context, event: Event) => {
      assertEventType(event, 'saveOptOut');
      return withDelay(store.optOut(context.token), store.delay);
    },
  };
}

export type Actor = ActorRefFrom<typeof machine>;
