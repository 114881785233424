import { ActorRefFrom, assign, createMachine, send } from 'xstate';
import { assertEventType } from './utils';
import { Image, ProfileWithAge, Store } from '../types';
import {
  machine as uploadPhotoMachine,
  createServices as createUploadPhotoServices,
  mapParentContextToContext as mapContextToUploadPhotoMachine,
} from './UploadPhotoMachine';

import { Context as ParentContext, UpdateContext } from './RomanceMachine';

export function mapParentContextToContext(context: ParentContext): Context {
  return {
    profile: context.profile,
  };
}

export interface Context {
  profile: ProfileWithAge;
}

interface RemovePhoto {
  type: 'removePhoto';
  photo: Image;
}

interface UploadPhoto {
  type: 'uploadPhoto';
}

type Event = RemovePhoto | UploadPhoto | UpdateContext;

export const machine = createMachine<Context, Event>(
  {
    id: 'editPhotosMachine',
    initial: 'edit',
    states: {
      edit: {
        initial: 'idle',
        states: {
          idle: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              removePhoto: 'removing',
            },
          },
          removing: {
            invoke: {
              id: 'removePhoto',
              src: 'removePhoto',
              onDone: 'idle',
              onError: 'idle.invalid',
            },
          },
        },
        on: {
          uploadPhoto: 'upload',
          updateContext: {
            actions: ['assignContext'],
          },
        },
      },
      upload: {
        invoke: {
          id: 'uploadPhotoMachine',
          src: 'uploadPhotoMachine',
          data: (context, event) =>
            mapContextToUploadPhotoMachine(context, { aspect: 1 }, false),
          onDone: 'edit',
        },
        on: {
          updateContext: {
            actions: ['assignContext', 'sendToUploadPhotoMachine'],
          },
        },
      },
    },
  },
  {
    actions: {
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(event.context);
      }),
      sendToUploadPhotoMachine: send(
        (context: Context, event: Event) => event,
        { to: 'uploadPhotoMachine' }
      ),
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;

export function createServices(store: Store) {
  return {
    uploadPhotoMachine: uploadPhotoMachine.withConfig({
      services: createUploadPhotoServices(store),
    }),
    removePhoto: (context: Context, event: Event) => {
      assertEventType(event, 'removePhoto');

      // Should we delete the photos in storage now as well?
      return store.removeFromPhotos(context.profile, event.photo);
    },
  };
}
