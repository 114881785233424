import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as UpdatesActor } from '../../machines/UpdatesMachine';
import { HeaderOverlay } from '../Header/HeaderOverlay';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { Confirmation } from '../Confirmation/Confirmation';
import Button from '../Button/Button';

import './Updates.scss';

export function Updates({ actor }: { actor: UpdatesActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  const updates = state.context.updates;

  return (
    <main className="updates">
      <Helmet>
        <title>
          {t('title')} - {t('header.updatesHeaderTitle')}
        </title>
      </Helmet>
      <HeaderOverlay onClick={() => send({ type: 'closePanel' })}>
        {t('header.updatesHeaderTitle')}
      </HeaderOverlay>
      {updates.length > 0 ? (
        <ul className="updates__list">
          {updates.map((u) => (
            <li key={u.profile.id}>
              <NotificationItem
                notifType="update"
                profileName={u.profile.name}
                profileImage={u.profile.images[0].xs}
                profileFave={u.profile.isFavorite}
                onClick={() =>
                  send({ type: 'viewProfile', profile: u.profile })
                }
                notifSeen={u.isRead ? true : false}
                notifDate={t('date.fromNow', { date: u.time })}
                notifAction={
                  u.hot === undefined
                    ? t('updates.online')
                    : u.hot
                    ? t('updates.hot')
                    : t('updates.not')
                }
              />
            </li>
          ))}
        </ul>
      ) : (
        <Confirmation pageType="full">
          <h1 className="heading confirmation__heading">
            {t('updates.emptyUpdatesTitle')}
          </h1>
          <p>{t('updates.emptyUpdatesDescription')}</p>
          <Button
            className="sign-out__button form__button"
            buttonType="primary"
            onClick={() => send({ type: 'closePanel' })}
          >
            {t('updates.emptyUpdatesButton')}
          </Button>
        </Confirmation>
      )}
    </main>
  );
}
