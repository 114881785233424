import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import './HeaderMarketing.scss';

import { Logo } from '../Logo/Logo';
import Button from '../Button/Button';
import { ButtonClick } from '../Button/Button';

interface HeaderMarketingProps {
  className?: string;
  homeClick?: ButtonClick;
  signInClick?: ButtonClick;
  signUpClick?: ButtonClick;
  signOutClick?: ButtonClick;
}

export function HeaderMarketing(props: HeaderMarketingProps) {
  const {
    className,
    homeClick,
    signInClick,
    signUpClick,
    signOutClick,
  } = props;
  const { t } = useTranslation();

  return (
    <header
      className={classnames('header-marketing header--sticky', className)}
    >
      <Button
        buttonType="stripped"
        onClick={homeClick}
        className="header-marketing__logo"
      >
        <Logo />
      </Button>

      <nav className="header-marketing__nav">
        {signInClick && (
          <Button className="header-marketing__nav-item" onClick={signInClick}>
            {t('header.signIn')}
          </Button>
        )}
        {signUpClick && (
          <Button className="header-marketing__nav-item" onClick={signUpClick}>
            {t('header.signUp')}
          </Button>
        )}
        {signOutClick && (
          <Button className="header-marketing__nav-item" onClick={signOutClick}>
            {t('header.signOut')}
          </Button>
        )}
      </nav>
    </header>
  );
}
