import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as ProfileActor } from '../../machines/ProfileMachine';
import { Gallery } from './Gallery';
import Button from '../Button/Button';
import { Loader } from '../Loader/Loader';
import { Feedback } from '../Feedback/Feedback';
import { StatusTag } from '../StatusTag/StatusTag';
import { Tag } from '../Tag/Tag';
import { GenderTag } from '../GenderTag/GenderTag';
import { SexualityTag } from '../SexualityTag/SexualityTag';
import { IconButton } from '../IconButton/IconButton';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';
import { ProfileDescriptionItem } from './DescriptionItem';
import { Gender, Sexuality } from '../../types';
import './Profile.scss';

export function Profile({ actor }: { actor: ProfileActor }) {
  const [state, send] = useActor(actor);
  const profile = state.context.profile;
  const hasChat = state.context.hasChat;
  const { t } = useTranslation();

  return (
    <main className="profile">
      <Helmet>
        <title>
          {t('title')} - {profile.name}
        </title>
      </Helmet>
      <ScrollToTop />
      <Gallery betweenCount={t('profile.imageCountOf')}>
        {profile.images.map((img) => (
          <img key={img.id} src={img.lg} alt={profile.name} />
        ))}
      </Gallery>
      <div className="profile__rating">
        {profile.isHot === undefined ? (
          <div className="profile__rating-buttons">
            <Button
              iconName="flame"
              buttonType="primary"
              className="profile__rating-button"
              onClick={() => send({ type: 'saveHot' })}
            >
              {t('profile.rating.hot')}
            </Button>
            <Button
              iconName="thumbs-down"
              buttonType="secondary"
              className="profile__rating-button"
              onClick={() => send({ type: 'saveNot' })}
            >
              {t('profile.rating.not')}
            </Button>
          </div>
        ) : profile.isHot ? (
          <div className="profile__rating-buttons">
            <Button
              iconName="flame"
              buttonType="primary"
              className="profile__rating-button"
            >
              {t('profile.rating.hot')}
            </Button>
            <Button
              iconName="undo"
              buttonType="secondary"
              className="profile__undo-button"
              onClick={() => send({ type: 'deleteRank' })}
            />
          </div>
        ) : (
          <div className="profile__rating-buttons">
            <Button
              iconName="undo"
              buttonType="primary"
              className="profile__undo-button"
              onClick={() => send({ type: 'deleteRank' })}
            />
            <Button
              iconName="thumbs-down"
              buttonType="secondary"
              className="profile__rating-button"
            >
              {t('profile.rating.not')}
            </Button>
          </div>
        )}
        {state.matches('rank.saving') && (
          <Feedback className="profile__rating-feedback">
            <Loader size="xs" theme="inline" /> {t('profile.rating.saveRating')}
          </Feedback>
        )}
        {state.matches('rank.deleting') && (
          <Feedback className="profile__rating-feedback">
            <Loader size="xs" theme="inline" />{' '}
            {t('profile.rating.deleteRating')}
          </Feedback>
        )}
        {state.matches('rank.idle.invalid') && (
          <Feedback className="profile__rating-feedback">
            {t('profile.rating.errorRating')}
          </Feedback>
        )}
      </div>
      <div className="profile__content">
        <h2 className="profile__heading">
          <span className="profile__heading-name heading">{profile.name}</span>
          <StatusTag
            className="profile__heading-status"
            statusState={profile.status}
          >
            {profile.status === 'online'
              ? t('profile.status.online')
              : profile.status === 'inactive'
              ? t('profile.status.inactive')
              : t('profile.status.offline')}
          </StatusTag>
        </h2>
        {profile.headline && (
          <p className="profile__headline item-heading">{profile.headline}</p>
        )}
        <ul className="profile__stats">
          {!Number.isNaN(profile.age) && (
            <li className="profile__stats-item">
              <Tag>
                {profile.age} {t('profile.meta.ageSuffix')}
              </Tag>
            </li>
          )}
          {profile.gender !== Gender.none && (
            <li className="profile__stats-item">
              <Tag>
                <GenderTag gender={profile.gender} />
              </Tag>
            </li>
          )}
          {profile.sexuality !== Sexuality.none && (
            <li className="profile__stats-item">
              <Tag>
                <SexualityTag sexuality={profile.sexuality} />
              </Tag>
            </li>
          )}
        </ul>

        <div className="profile__actions">
          <IconButton
            buttonType="profile"
            iconName="chat"
            className="profile__action"
            disabled={profile.isDead || !hasChat}
            onClick={() => send({ type: 'viewChat', profile })}
          >
            {t('profile.actions.chat')}
          </IconButton>
          {profile.isFavorite ? (
            <IconButton
              buttonType="profile"
              iconName="heartbreak"
              className="profile__action"
              onClick={() => send({ type: 'removeFavorite' })}
            >
              {t('profile.actions.unfave')}
            </IconButton>
          ) : (
            <IconButton
              buttonType="profile"
              iconName="favourite"
              className="profile__action"
              onClick={() => send({ type: 'addFavorite' })}
            >
              {t('profile.actions.fave')}
            </IconButton>
          )}
          {profile.isBlocked ? (
            <IconButton
              buttonType="profile"
              iconName="block"
              className="profile__action"
              onClick={() => send({ type: 'removeBlock' })}
            >
              {t('profile.actions.unblock')}
            </IconButton>
          ) : (
            <IconButton
              buttonType="profile"
              iconName="block"
              className="profile__action"
              onClick={() => send({ type: 'addBlock' })}
            >
              {t('profile.actions.block')}
            </IconButton>
          )}
          {state.matches('favorite.saving') && (
            <div className="profile__actions-overlay">
              <Feedback className="profile__actions-feedback">
                <Loader size="xs" theme="inline" />{' '}
                {t('profile.actions.saveFave')}
              </Feedback>
            </div>
          )}
          {state.matches('favorite.idle.invalid') && (
            <div className="profile__actions-overlay">
              <Feedback className="profile__actions-feedback">
                <Loader size="xs" theme="inline" />{' '}
                {t('profile.actions.faveError')}
              </Feedback>
            </div>
          )}
          {state.matches('block.saving') && (
            <div className="profile__actions-overlay">
              <Feedback className="profile__actions-feedback">
                <Loader size="xs" theme="inline" />{' '}
                {t('profile.actions.saveBlock')}
              </Feedback>
            </div>
          )}
          {state.matches('block.idle.invalid') && (
            <div className="profile__actions-overlay">
              <Feedback className="profile__actions-feedback">
                <Loader size="xs" theme="inline" />{' '}
                {t('profile.actions.blockError')}
              </Feedback>
            </div>
          )}
        </div>
      </div>
      <ul className="profile-description">
        {profile.who && (
          <ProfileDescriptionItem itemLabel={t('profile.description.whoLabel')}>
            {profile.who}
          </ProfileDescriptionItem>
        )}
        {profile.what && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.whatLabel')}
          >
            {profile.what}
          </ProfileDescriptionItem>
        )}
        {profile.why && (
          <ProfileDescriptionItem itemLabel={t('profile.description.whyLabel')}>
            {profile.why}
          </ProfileDescriptionItem>
        )}
        {profile.how && (
          <ProfileDescriptionItem itemLabel={t('profile.description.howLabel')}>
            {profile.how}
          </ProfileDescriptionItem>
        )}
        {profile.when && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.whenLabel')}
          >
            {profile.when}
          </ProfileDescriptionItem>
        )}
        {profile.else && (
          <ProfileDescriptionItem
            itemLabel={t('profile.description.elseLabel')}
          >
            {profile.else}
          </ProfileDescriptionItem>
        )}
      </ul>
      {state.context.is2Fik && (
        <div className="profile__ban">
          {state.matches('ban.idle.invalid') && (
            <Feedback className="profile__ban-feedback">
              {t('profile.ban.error')}
            </Feedback>
          )}
          <div className="profile__ban-warning">{t('profile.ban.warning')}</div>
          {state.matches('ban.idle.valid') && (
            <Button
              onClick={() => send({ type: 'saveBan' })}
              buttonType="primary"
              className="profile__ban-button"
            >
              {t('profile.ban.button')}
            </Button>
          )}
          {state.matches('ban.saving') && (
            <Button
              disabled={true}
              className="profile__ban-button profile--ban-saving"
            >
              {t('profile.ban.saving')}
            </Button>
          )}
          {state.matches('ban.done') && (
            <Button
              disabled={true}
              className="profile__ban-button profile--ban-complete"
            >
              {t('profile.ban.complete')}
            </Button>
          )}
        </div>
      )}
      <div className="profile__last-login">
        <p>
          {t('profile.loginDate')}{' '}
          {profile.lastOnline
            ? t('date.lastLogin', { date: profile.lastOnline })
            : t('profile.loginUnavailable')}
        </p>
      </div>
    </main>
  );
}
