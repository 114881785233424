import {
  createMachine,
  assign,
  ActorRefFrom,
  Sender,
  send,
  sendParent,
} from 'xstate';
import { assertEventType } from './utils';
import {
  LastReadDocument,
  Message,
  Profile,
  ProfileWithAge,
  Store,
} from '../types';

import {
  Context as RomanceContext,
  UpdateContext,
  ClosePanel,
  ViewProfile,
} from './RomanceMachine';

export function mapParentContextToContext(
  context: RomanceContext,
  profile: Profile,
  body: string
): Context {
  return {
    hasChat: context.config.chat,
    body,
    from: context.profile,
    profile,
    messages: context.messages
      .filter((m): m is Message => {
        return m.profile !== undefined;
      })
      .filter((m) => m.profile.id === profile.id),
  };
}

export interface Context {
  hasChat: boolean;
  body: string;
  from: ProfileWithAge;
  profile: Profile;
  messages: Message[];
  lastRead?: LastReadDocument;
}

interface LoadLastRead {
  type: 'loadLastRead';
  lastRead: LastReadDocument;
}

interface CheckForUnread {
  type: 'checkForUnread';
}

interface StartTyping {
  type: 'startTyping';
}

interface FinishTyping {
  type: 'finishTyping';
}

interface StartTypingIndicator {
  type: 'startTypingIndicator';
}

interface FinishTypingIndicator {
  type: 'finishTypingIndicator';
}

interface SetBody {
  type: 'setBody';
  body: string;
}

interface Save {
  type: 'save';
}

export type Event =
  | LoadLastRead
  | CheckForUnread
  | StartTyping
  | FinishTyping
  | StartTypingIndicator
  | FinishTypingIndicator
  | SetBody
  | Save
  | UpdateContext
  | ClosePanel
  | ViewProfile;

export const machine = createMachine<Context, Event>(
  {
    id: 'chatMachine',
    type: 'parallel',
    invoke: [
      {
        id: 'watchTyping',
        src: 'watchTyping',
      },
      {
        id: 'watchLastRead',
        src: 'watchLastRead',
      },
    ],
    states: {
      typing: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              startTyping: 'on',
            },
          },
          on: {
            invoke: {
              id: 'saveTyping',
              src: 'saveTyping',
            },
            on: {
              finishTyping: 'off',
            },
          },
          off: {
            invoke: {
              id: 'saveNotTyping',
              src: 'saveNotTyping',
            },
            on: {
              startTyping: 'on',
            },
          },
        },
      },
      message: {
        initial: 'ready',
        states: {
          ready: {
            on: {
              save: {
                cond: 'hasBody',
                target: 'saving',
              },
            },
          },
          saving: {
            invoke: {
              id: 'saveMessage',
              src: 'saveMessage',
              onDone: {
                target: 'ready',
                actions: 'assignEmptyBody',
              },
              onError: {
                target: 'error',
              },
            },
          },
          error: {},
        },
      },
      lastRead: {
        initial: 'first',
        // On entry check the condition and then anytime a message is loaded we go to saving.
        states: {
          first: {
            always: [
              { target: 'saving', cond: 'hasUnreadMessages' },
              { target: 'idle' },
            ],
          },
          idle: {},
          saving: {
            invoke: {
              id: 'saveLastRead',
              src: 'saveLastRead',
              onDone: {
                target: 'idle',
              },
              onError: {
                target: 'error',
              },
            },
          },
          error: {},
        },
        on: {
          checkForUnread: {
            target: '.saving',
            cond: 'hasUnreadMessages',
          },
        },
      },
      typingIndicator: {
        initial: 'off',
        states: {
          off: {
            on: {
              startTypingIndicator: 'on',
            },
          },
          on: {
            on: {
              finishTypingIndicator: 'off',
            },
          },
        },
      },
      assign: {
        on: {
          updateContext: { actions: ['assignContext', 'checkForUnread'] },
          loadLastRead: {
            actions: 'assignLastRead',
          },
          setBody: {
            actions: 'assignBody',
          },
        },
      },
    },
    on: {
      closePanel: { actions: 'sendToParent' },
      viewProfile: { actions: 'sendToParent' },
    },
  },
  {
    guards: {
      hasBody: (context) => {
        return context.body.trim().length > 0 && !context.profile.isBlockingYou;
      },
      hasUnreadMessages: (context) => {
        return context.messages.some(
          (m) => m.profile !== undefined && !m.profile.isBlocked && !m.isRead
        );
      },
    },
    actions: {
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(
          event.context,
          event.context.profiles.find((p) => p.id === context.profile.id) ||
            context.profile,
          context.body
        );
      }),
      assignLastRead: assign({
        lastRead: (context, event) => {
          assertEventType(event, 'loadLastRead');
          return event.lastRead;
        },
      }),
      assignBody: assign({
        body: (context, event) => {
          assertEventType(event, 'setBody');
          return event.body;
        },
      }),
      assignEmptyBody: assign({
        body: (context) => '',
      }),
      checkForUnread: send({ type: 'checkForUnread' }),
      sendToParent: sendParent((context, event) => event),
    },
  }
);

export function createServices(store: Store) {
  return {
    watchTyping: (context: Context) => {
      return (callback: Sender<Event>) => {
        return store.watchTypingDocument(
          `${context.profile.id}_${context.from.id}`,
          ({ typing }) => {
            typing
              ? callback({
                  type: 'startTypingIndicator',
                })
              : callback({
                  type: 'finishTypingIndicator',
                });
          }
        );
      };
    },
    watchLastRead: (context: Context) => {
      return (callback: Sender<Event>) => {
        return store.watchLastReadDocument(
          `${context.profile.id}_${context.from.id}`,
          (lastRead) => {
            callback({
              type: 'loadLastRead',
              lastRead,
            });
          }
        );
      };
    },
    saveMessage: (context: Context) => {
      return store.addDocument(
        'messages',
        {
          body: context.body,
          to: context.profile.id,
          from: context.from.id,
        },
        'time'
      );
    },
    saveLastRead: (context: Context) => {
      const doc = {
        id: `${context.from.id}_${context.profile.id}`,
        to: context.profile.id,
        from: context.from.id,
      };

      return store.saveDocument('reads', doc, 'time');
    },
    saveNotTyping: (context: Context) => {
      const doc = {
        id: `${context.from.id}_${context.profile.id}`,
        to: context.profile.id,
        from: context.from.id,
        typing: false,
      };

      return store.saveDocument('typing', doc);
    },
    saveTyping: (context: Context) => {
      const doc = {
        id: `${context.from.id}_${context.profile.id}`,
        to: context.profile.id,
        from: context.from.id,
        typing: true,
      };

      return store.saveDocument('typing', doc);
    },
  };
}

export type Actor = ActorRefFrom<typeof machine>;
