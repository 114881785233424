import classNames from 'classnames';

import './TextInput.scss';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fauxDisabled?: boolean;
  value?: string;
  type?:
    | 'text'
    | 'email'
    | 'search'
    | 'password'
    | 'number'
    | 'tel'
    | 'url'
    | 'date';
}

export function TextInput({
  className,
  name,
  value,
  type,
  onChange,
  fauxDisabled,
  ...props
}: TextInputProps) {
  return (
    <input
      className={classNames(className, 'form__text-input', {
        [`form__text-input--disabled`]: fauxDisabled,
      })}
      name={name}
      value={value}
      type={type}
      onChange={onChange}
      {...props}
    />
  );
}
