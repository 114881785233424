import React from 'react';
import './Listing.scss';
import classNames from 'classnames';
import { StatusTag } from '../StatusTag/StatusTag';
import { ProfileStatus } from '../../types';

interface ListingProps {
  className?: string;
  name: string;
  image: string;
  status: ProfileStatus;
  onClick?: (e: React.SyntheticEvent) => void;
}

export function Listing(props: ListingProps) {
  const { className, name, image, status, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={classNames(className, 'listing', {
        'listing--active': status === 'user',
      })}
    >
      <div className="listing-container">
        <img className="listing__image" src={image} alt={name} />
        <StatusTag className="listing__status" statusState={status}>
          {name}
        </StatusTag>
      </div>
    </button>
  );
}
