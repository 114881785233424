import { useTranslation } from 'react-i18next';
import { Sexuality } from '../../types';

export function SexualityTag({ sexuality }: { sexuality: Sexuality }) {
  const { t } = useTranslation();

  switch (sexuality) {
    case Sexuality.allosexual:
      return <>{t('sexuality.allosexual')}</>;
    case Sexuality.androsexual:
      return <>{t('sexuality.androsexual')}</>;
    case Sexuality.asexual:
      return <>{t('sexuality.asexual')}</>;
    case Sexuality.aromantic:
      return <>{t('sexuality.aromantic')}</>;
    case Sexuality.bicurious:
      return <>{t('sexuality.bicurious')}</>;
    case Sexuality.bisexual:
      return <>{t('sexuality.bisexual')}</>;
    case Sexuality.demisexual:
      return <>{t('sexuality.demisexual')}</>;
    case Sexuality.fluid:
      return <>{t('sexuality.fluid')}</>;
    case Sexuality.gay:
      return <>{t('sexuality.gay')}</>;
    case Sexuality.gynesexual:
      return <>{t('sexuality.gynesexual')}</>;
    case Sexuality.heterosexual:
      return <>{t('sexuality.heterosexual')}</>;
    case Sexuality.homosexual:
      return <>{t('sexuality.homosexual')}</>;
    case Sexuality.lesbian:
      return <>{t('sexuality.lesbian')}</>;
    case Sexuality.pansexual:
      return <>{t('sexuality.pansexual')}</>;
    case Sexuality.sapiosexual:
      return <>{t('sexuality.sapiosexual')}</>;
    case Sexuality.straight:
      return <>{t('sexuality.straight')}</>;
    case Sexuality.none:
      return <>{t('sexuality.none')}</>;
  }
}
