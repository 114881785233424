import { createMachine, ActorRefFrom, assign } from 'xstate';
import { Crop as CropObj } from 'react-image-crop';

import { assertEventType } from './utils';
import { ProfileWithAge, Store, UploadState } from '../types';

import { Context as RomanceContext, UpdateContext } from './RomanceMachine';
import { Context as EditPhotosContext } from './EditPhotosMachine';
import { Context as EditProfileContext } from './EditProfileMachine';

const MB = 1024 * 1024;

export function mapParentContextToContext(
  context: EditProfileContext | EditPhotosContext | RomanceContext,
  crop: CropObj,
  isNew: boolean
): Context {
  return {
    profile: context.profile,
    crop,
    isNew,
  };
}

export interface Context {
  profile: ProfileWithAge;
  crop: CropObj;
  isNew: boolean;
}

interface Upload {
  type: 'upload';
  file: File;
}

interface Crop {
  type: 'crop';
}

interface UpdateCrop {
  type: 'updateCrop';
  crop: CropObj;
}

interface Cancel {
  type: 'cancel';
}

type Event = Upload | Crop | UpdateContext | UpdateCrop | Cancel;

const transistions = [
  { cond: 'isReadyUpload', target: 'ready.upload' },
  { cond: 'isProcessingUploading', target: 'uploading' },
  { cond: 'isProcessingResizing', target: 'resizing' },
  { cond: 'isCrop', target: 'crop' },
  { cond: 'isProcessingCropping', target: 'cropping' },
  { cond: 'isReadyError', target: 'ready.error.upload' },
];

export const machine = createMachine<Context, Event>(
  {
    id: 'uploadPhotoMachine',
    initial: 'init',
    states: {
      init: {
        always: transistions,
      },
      ready: {
        initial: 'upload',
        states: {
          upload: {},
          error: {
            initial: 'upload',
            states: {
              upload: {},
              crop: {},
              size: {},
            },
          },
        },
        on: {
          // TODO: We probably don't want sub states here.
          updateContext: { cond: 'isProcessingUploading', target: 'uploading' },
          upload: [
            { target: '.error.size', cond: 'isFileTooLarge' },
            { target: 'uploading' },
          ],
        },
      },
      uploading: {
        invoke: {
          id: 'uploadFile',
          src: 'uploadFile',
          onDone: 'resizing',
          onError: 'ready.error.upload',
        },
        on: {
          updateContext: { cond: 'isProcessingResizing', target: 'resizing' },
        },
      },
      resizing: {
        on: {
          updateContext: [
            {
              cond: 'isCrop',
              target: 'crop',
              actions: 'assignContext',
            },
            {
              cond: 'isReadyError',
              target: 'ready.error.upload',
              actions: 'assignContext',
            },
          ],
        },
      },
      crop: {
        on: {
          updateContext: { cond: 'isProcessingCropping', target: 'cropping' },
          updateCrop: { actions: 'assignCrop' },
          crop: 'cropping',
          cancel: 'cancelling',
        },
      },
      cropping: {
        // TODO: A very weird crash happens if we go the final state as a
        // response to an updateContext event in this node. Right now this
        // means that the machine stay in this state if it starts in it but
        // that will be very rare.
        invoke: {
          id: 'crop',
          src: 'crop',
          onDone: 'done',
          onError: 'ready.error.crop',
        },
      },
      cancelling: {
        invoke: {
          id: 'cancel',
          src: 'cancel',
          onDone: 'done',
          onError: 'done',
        },
      },
      done: {
        type: 'final',
      },
    },
  },
  {
    guards: {
      isReadyUpload: isUploadState('ready.upload'),
      isProcessingUploading: isUploadState('processing.upload'),
      isProcessingResizing: isUploadState('processing.resize'),
      isCrop: isUploadState('ready.crop'),
      isProcessingCropping: isUploadState('processing.crop'),
      isReadyError: isUploadState('ready.error'),
      isFileTooLarge: (context, event) => {
        assertEventType(event, 'upload');

        return event.file.size > 10 * MB;
      },
    },
    actions: {
      assignCrop: assign((context, event) => {
        assertEventType(event, 'updateCrop');
        return { crop: event.crop };
      }),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(
          event.context,
          context.crop,
          context.isNew
        );
      }),
    },
  }
);

function isUploadState(state: string) {
  return (context: Context, event: Event) => {
    const profile =
      event.type === 'updateContext' ? event.context.profile : context.profile;

    return profile.id === context.profile.id && profile.upload.state === state;
  };
}

export type Actor = ActorRefFrom<typeof machine>;

export function createServices(store: Store) {
  return {
    uploadFile: async (context: Context, event: Event) => {
      // TODO: Explore offering cancelling.
      if (event.type === 'upload') {
        const upload: UploadState = {
          state: 'processing.upload',
        };

        const uploading = {
          id: context.profile.id,
          upload,
        };

        await Promise.all([
          store.updateDocument('profiles', uploading),
          store.addFile(`upload/${context.profile.id}`, event.file),
        ]);
      }
    },
    crop: async (context: Context, event: Event) => {
      if (event.type === 'crop') {
        const upload: UploadState = {
          state: 'processing.crop',
        };

        const uploading = {
          id: context.profile.id,
          upload,
        };

        await store.updateDocument('profiles', uploading);

        await store.crop(
          context.profile.id,
          context.crop.x || 0,
          context.crop.y || 0,
          context.crop.width || 100,
          context.crop.height || 100
        );
      }
    },
    cancel: async (context: Context, event: Event) => {
      const upload: UploadState = {
        state: 'ready.upload',
      };

      const uploading = {
        id: context.profile.id,
        upload,
      };

      await Promise.all([
        store.deleteDocument('uploads', uploading),
        store.updateDocument('profiles', uploading),
      ]);
    },
  };
}
