import React from 'react';
import './Confirmation.scss';
import classNames from 'classnames';
import Button from '../Button/Button';

interface ConfirmationProps {
  className?: string;
  pageType?: 'default' | 'full' | 'wide-button';
  children?: React.ReactNode;
}

export function Confirmation({
  className,
  pageType,
  children,
}: ConfirmationProps) {
  return (
    <div
      className={classNames(className, 'confirmation', 'page', {
        'confirmation--full': pageType === 'full',
        'confirmation--wide-button': pageType === 'wide-button',
      })}
    >
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child, {
            className: classNames(child.props.className, {
              [`confirmation__heading heading`]: child.type === 'h1',
              [`confirmation__paragraph paragraph`]: child.type === 'p',
              [`confirmation__button`]: child.type === Button,
            }),
          })
      )}
    </div>
  );
}
