import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import Button from '../Button/Button';
import { Confirmation } from '../Confirmation/Confirmation';

export function SignUpConfirmation({ actor }: { actor: RomanceActor }) {
  const [, send] = useActor(actor);
  const { t } = useTranslation();

  return (
    <main className="signup-confirmation">
      <Confirmation pageType="full">
        <h1 className="heading">{t('signUp.confirmation.title')}</h1>
        <p>{t('signUp.confirmation.description')}</p>
        <strong>{t('signUp.confirmation.descriptionStrong')}</strong>
        <Button
          onClick={() => send({ type: 'createProfile' })}
          buttonType="primary"
        >
          {t('signUp.confirmation.buttonTitle')}
        </Button>
      </Confirmation>
    </main>
  );
}
