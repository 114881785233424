import { createMachine, assign, ActorRefFrom } from 'xstate';
import { Store } from '../types';

interface Context {
  isHotComplete: boolean;
  isNotComplete: boolean;
  isProfileComplete: boolean;
  isMessageComplete: boolean;
  isRankComplete: boolean;
  isLastReadComplete: boolean;
  isConfigComplete: boolean;
}

export interface LoadHotComplete {
  type: 'loadHotComplete';
}

export interface LoadNotComplete {
  type: 'loadNotComplete';
}

export interface LoadProfileComplete {
  type: 'loadProfileComplete';
}

export interface LoadMessageComplete {
  type: 'loadMessageComplete';
}

export interface LoadRankComplete {
  type: 'loadRankComplete';
}

export interface LoadLastReadComplete {
  type: 'loadLastReadComplete';
}

export interface LoadConfigComplete {
  type: 'loadConfigComplete';
}

type Event =
  | LoadHotComplete
  | LoadNotComplete
  | LoadProfileComplete
  | LoadMessageComplete
  | LoadRankComplete
  | LoadLastReadComplete
  | LoadConfigComplete;

export const machine = createMachine<Context, Event>(
  {
    id: 'loadingMachine',
    initial: 'delay',
    context: {
      isHotComplete: false,
      isNotComplete: false,
      isProfileComplete: false,
      isMessageComplete: false,
      isRankComplete: false,
      isLastReadComplete: false,
      isConfigComplete: false,
    },
    states: {
      delay: {
        after: {
          loadDelay: [
            { target: 'done', cond: 'isDoneLoading' },
            { target: 'delay' },
          ],
        },
      },
      done: {
        type: 'final',
      },
    },
    on: {
      loadHotComplete: { actions: 'assignToFlags' },
      loadNotComplete: { actions: 'assignToFlags' },
      loadProfileComplete: { actions: 'assignToFlags' },
      loadMessageComplete: { actions: 'assignToFlags' },
      loadLastReadComplete: { actions: 'assignToFlags' },
      loadRankComplete: { actions: 'assignToFlags' },
      loadConfigComplete: { actions: 'assignToFlags' },
    },
  },
  {
    actions: {
      assignToFlags: assign<Context, Event>({
        isHotComplete: (context, event) =>
          context.isHotComplete || event.type === 'loadHotComplete',
        isNotComplete: (context, event) =>
          context.isNotComplete || event.type === 'loadNotComplete',
        isProfileComplete: (context, event) =>
          context.isProfileComplete || event.type === 'loadProfileComplete',
        isMessageComplete: (context, event) =>
          context.isMessageComplete || event.type === 'loadMessageComplete',
        isLastReadComplete: (context, event) =>
          context.isLastReadComplete || event.type === 'loadLastReadComplete',
        isRankComplete: (context, event) =>
          context.isRankComplete || event.type === 'loadRankComplete',
        isConfigComplete: (context, event) =>
          context.isConfigComplete || event.type === 'loadConfigComplete',
      }),
    },
    guards: {
      isDoneLoading: (context) =>
        context.isProfileComplete &&
        context.isMessageComplete &&
        context.isRankComplete &&
        context.isLastReadComplete &&
        context.isConfigComplete,
    },
  }
);

export function createDelays(store: Store) {
  return {
    loadDelay: store.delay,
  };
}

export type Actor = ActorRefFrom<typeof machine>;
