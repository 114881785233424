import { createMachine, assign, ActorRefFrom, Sender, send } from 'xstate';
import { assertEventType, getProfileWithAge, withDelay } from './utils';
import { ProfileDocument, ProfileWithAge, Store, User } from '../types';

import { LoadTime } from './AppMachine';
import {
  machine as loadingMachine,
  createDelays as createLoadingDelays,
} from './LoadingMachine';

export interface Context {
  user: User;
  time: Date;
  profiles: ProfileWithAge[];
}

interface LoadProfile {
  type: 'loadProfile';
  profile: ProfileDocument;
}

interface UnloadProfile {
  type: 'unloadProfile';
  profile: ProfileDocument;
}

interface LoadProfileComplete {
  type: 'loadProfileComplete';
}

interface SetProfile {
  type: 'setProfile';
  profile: ProfileDocument;
}

interface GetProfile {
  type: 'done.invoke.getProfile';
  data: ProfileDocument;
}

interface Done {
  type: 'done';
}

type Event =
  | LoadTime
  | LoadProfile
  | UnloadProfile
  | LoadProfileComplete
  | SetProfile
  | GetProfile
  | Done;

export const machine = createMachine<Context, Event>(
  {
    id: 'preludeMachine',
    initial: 'init',
    states: {
      init: {
        always: [{ cond: 'is2Fik', target: 'set' }, { target: 'get' }],
      },
      get: {
        invoke: {
          id: 'getProfile',
          src: 'getProfile',
          onDone: 'done',
        },
      },
      set: {
        entry: 'setLocationToVisible',
        initial: 'loading',
        states: {
          loading: {
            invoke: {
              id: 'loadingMachine',
              src: 'loadingMachine',
              onDone: 'ready',
            },
            on: {
              loadProfileComplete: { actions: 'sendToLoadingMachine' },
            },
          },
          ready: {},
        },
        invoke: {
          id: 'watchProfiles',
          src: 'watchProfiles',
        },
        on: {
          setProfile: 'done',
          done: 'done',
        },
      },
      done: {
        entry: 'setLocationToHidden',
        type: 'final',
        data: (context: Context, event: Event) => {
          if (event.type === 'done.invoke.getProfile') {
            return event.data;
          }

          if (event.type === 'setProfile') {
            return event.profile;
          }

          assertEventType(event, 'done');
        },
      },
    },
    on: {
      loadTime: { actions: 'assignToTime' },
      loadProfile: { actions: 'assignProfile' },
      unloadProfile: { actions: 'removeProfile' },
    },
  },
  {
    actions: {
      sendToLoadingMachine: send((context, event) => event, {
        to: 'loadingMachine',
      }),
      assignProfile: assign({
        profiles: (context, event) => {
          assertEventType(event, 'loadProfile');

          const profile = getProfileWithAge(context.time, event.profile);

          const profiles = context.profiles.filter((p) => p.id !== profile.id);

          const insertAt = profiles.findIndex(
            (p) =>
              p.order < profile.order ||
              (p.order === profile.order &&
                p.name.localeCompare(profile.name) > 0)
          );

          return insertAt >= 0
            ? [
                ...profiles.slice(0, insertAt),
                profile,
                ...profiles.slice(insertAt),
              ]
            : [...profiles, profile];
        },
      }),
      assignToTime: assign({
        time: (context, event) => {
          assertEventType(event, 'loadTime');
          return event.time;
        },
      }),
      removeProfile: assign({
        profiles: (context, event) => {
          assertEventType(event, 'unloadProfile');
          return context.profiles.filter((p) => p.id !== event.profile.id);
        },
      }),
    },
    guards: {
      is2Fik: (context) => context.user.is2Fik,
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;

export function createServices(store: Store) {
  return {
    loadingMachine: loadingMachine.withConfig({
      delays: createLoadingDelays(store),
      guards: {
        isDoneLoading: (context) => context.isProfileComplete,
      },
    }),
    getProfile: (context: Context) => {
      return withDelay(
        store.getProfileDocument(`${context.user.id}`),
        store.delay
      );
    },
    watchProfiles: (context: Context) => {
      return (
        callback: Sender<LoadProfile | UnloadProfile | LoadProfileComplete>
      ) => {
        return store.watchProfileDocuments(
          [{ type: 'where', fieldName: 'state', fieldValue: '2fik' }],
          (profile) => {
            callback({ type: 'loadProfile', profile });
          },
          (profile) => {
            callback({ type: 'unloadProfile', profile });
          },
          () => {
            callback({ type: 'loadProfileComplete' });
          }
        );
      };
    },
  };
}

export function createActions(store: Store) {
  return {
    setLocationToVisible: (context: Context) => {
      updateLocation(store, {
        id: context.user.id,
        persona: { state: 'visible' },
      });
    },
    setLocationToHidden: (context: Context, event: Event) => {
      if (event.type === 'setProfile') {
        updateLocation(store, {
          id: context.user.id,
          profile: event.profile.id,
          persona: { state: 'hidden' },
        });
      }
    },
  };
}

interface Location {
  id: string;
  profile?: string;
  persona: { state: 'hidden' | 'visible' };
}

function updateLocation(store: Store, location: Location) {
  store.updateDocument('location', location);
}
