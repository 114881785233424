import React from 'react';
import './ChatStreamItem.scss';
import classNames from 'classnames';

interface ChatStreamItemProps {
  children?: React.ReactNode;
  className?: string;
}

export function ChatStreamItem(props: ChatStreamItemProps) {
  const { children, className } = props;

  return (
    <div className={classNames(className, 'chat-stream-item')}>{children}</div>
  );
}
