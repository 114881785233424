import isEmail from 'validator/lib/isEmail';
import { assign, createMachine, ActorRefFrom } from 'xstate';
import { Store } from '../types';
import { EMAIL_KEY, assertEventType, withDelay } from './utils';

interface Context {
  name: string;
  email: string;
  is18: boolean;
  isAgreedTo: boolean;
}

interface SetName {
  type: 'setName';
  name: string;
}

interface SetEmail {
  type: 'setEmail';
  email: string;
}

interface SendSignInLink {
  type: 'sendSignInLink';
}

interface SetAge {
  type: 'setAge';
}

interface SetAgreedTo {
  type: 'setAgreedTo';
}
interface Done {
  type: 'done';
}

type Event = SetName | SetEmail | SendSignInLink | SetAge | SetAgreedTo | Done;

const machine = createMachine<Context, Event>(
  {
    id: 'magicLinkMachine',
    initial: 'idle',
    context: {
      name: '',
      email: '',
      is18: false,
      isAgreedTo: false,
    },
    states: {
      idle: {
        initial: 'valid',
        states: {
          valid: {},
          invalid: {},
        },
        on: {
          setName: {
            actions: 'assignName',
          },
          setEmail: {
            actions: 'assignEmail',
          },
          sendSignInLink: [
            {
              target: 'sending',
              cond: 'isValidForm',
            },
            { target: 'agreeToTOS', cond: 'goToTOS' },
            { target: '.invalid' },
          ],
        },
      },
      agreeToTOS: {
        on: {
          setAge: {
            actions: 'assignAge',
          },
          setAgreedTo: {
            actions: 'assignAgreedTo',
          },
          sendSignInLink: {
            target: 'sending',
            cond: 'isTermsAccepted',
          },
        },
      },
      sending: {
        invoke: {
          id: 'sendSignInLink',
          src: 'sendSignInLink',
          onDone: {
            target: 'success',
            actions: 'saveEmailToLocalStorage',
          },
          onError: {
            target: 'failure',
          },
        },
      },
      success: {
        on: {
          done: 'done',
        },
      },
      failure: {
        on: {
          done: 'done',
        },
      },
      done: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      assignName: assign({
        name: (context, event) => {
          assertEventType(event, 'setName');
          return event.name;
        },
      }),
      assignEmail: assign({
        email: (context, event) => {
          assertEventType(event, 'setEmail');
          return event.email;
        },
      }),
      assignAge: assign({
        is18: (context, event) => {
          assertEventType(event, 'setAge');
          return !context.is18;
        },
      }),
      assignAgreedTo: assign({
        isAgreedTo: (context, event) => {
          assertEventType(event, 'setAgreedTo');
          return !context.isAgreedTo;
        },
      }),
      saveEmailToLocalStorage: (context) =>
        window.localStorage.setItem(EMAIL_KEY, context.email),
    },
  }
);

function isNameNotEmpty(name: string) {
  return name.trim().length > 0;
}

export function createSignUpServices(store: Store) {
  return {
    sendSignInLink: (context: Context) => {
      return withDelay(
        store.sendSignUpLinkToEmail(context.email, context.name),
        store.delay
      );
    },
  };
}

export function createSignInServices(store: Store) {
  return {
    sendSignInLink: (context: Context) => {
      return withDelay(store.sendSignInLinkToEmail(context.email), store.delay);
    },
  };
}

export function createSignUpGuards() {
  return {
    goToTOS: (context: Context) => {
      return isEmail(context.email) && isNameNotEmpty(context.name);
    },
    isTermsAccepted: (context: Context) => {
      return context.is18 && context.isAgreedTo;
    },
    isValidForm: (context: Context) => {
      return false;
    },
  };
}

export function createSignInGuards() {
  return {
    goToTOS: (context: Context) => {
      return false;
    },
    isValidForm: (context: Context) => {
      return isEmail(context.email);
    },
  };
}

export { machine };

export type Actor = ActorRefFrom<typeof machine>;
