import React from 'react';
import './DescriptionItem.scss';
import classnames from 'classnames';

interface DescriptionProps {
  itemLabel?: string;
  className?: string;
  children?: React.ReactNode;
}

export function ProfileDescriptionItem(props: DescriptionProps) {
  const { itemLabel, className, children } = props;

  return (
    <li className={classnames('profile-description__item', className)}>
      <h3 className="profile-description__label">{itemLabel}</h3>
      <p className="profile-description__content">{children}</p>
    </li>
  );
}
