import { createMachine, assign, ActorRefFrom } from 'xstate';
import { assertEventType, withDelay } from './utils';
import { ProfileWithAge, Store } from '../types';
import { Context as RomanceContext, UpdateContext } from './RomanceMachine';

export function mapParentContextToContext(context: RomanceContext): Context {
  return {
    profile: context.profile,
  };
}

export interface Context {
  profile: ProfileWithAge;
}

interface ConfirmDelete {
  type: 'confirmDelete';
}

interface CancelDelete {
  type: 'cancelDelete';
}

interface FinishDelete {
  type: 'finishDelete';
}

type Event = ConfirmDelete | CancelDelete | FinishDelete | UpdateContext;

export const machine = createMachine<Context, Event>(
  {
    id: 'deleteProfileMachine',
    initial: 'confirm',
    states: {
      confirm: {
        initial: 'valid',
        states: {
          valid: {},
          error: {},
        },
        on: {
          confirmDelete: 'deleting',
          cancelDelete: 'final',
        },
      },
      deleting: {
        invoke: {
          id: 'deleteProfile',
          src: 'deleteProfile',
          onDone: 'done',
          onError: 'confirm.error',
        },
      },
      done: {
        on: {
          finishDelete: 'final',
        },
      },
      final: {
        type: 'final',
        data: (context: Context, event: Event) => {
          return event.type === 'finishDelete';
        },
      },
    },
    on: {
      updateContext: {
        actions: 'assignContext',
      },
    },
  },
  {
    actions: {
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(event.context);
      }),
    },
  }
);

export function createServices(store: Store) {
  return {
    deleteProfile: (context: Context) => {
      const document = {
        id: context.profile.id,
        url: window.location.href,
      };

      return withDelay(
        store.saveDocument('deletes', document, 'time'),
        store.delay
      );
    },
  };
}

export type Actor = ActorRefFrom<typeof machine>;
