import './Showcase.scss';
import '../Romance/Romance.scss';
import { Gender, Sexuality } from '../../types';
import { Logo } from '../Logo/Logo';
import { Loader } from '../Loader/Loader';
import { TextLink } from '../TextLink/TextLink';
import { Fieldset } from '../Form/Fieldset';
import { TextInput } from '../Form/TextInput';
import { RadioInput } from '../Form/RadioInput';
import { CheckboxInput } from '../Form/CheckboxInput';
import { SelectInput } from '../Form/SelectInput';
import Button from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { Footer } from '../Footer/Footer';
import { HeaderApp } from '../Header/HeaderApp';
import { HeaderOverlay } from '../Header/HeaderOverlay';
import { HeaderMarketing } from '../Header/HeaderMarketing';
import { Persona } from '../Persona/Persona';
import { Icon } from '../Icon/Icon';
import { StatusTag } from '../StatusTag/StatusTag';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { Tag } from '../Tag/Tag';
import { Listing } from '../Profile/Listing';
import { AlertBanner } from '../AlertBanner/AlertBanner';
import { Feedback } from '../Feedback/Feedback';
import Avatar from '../../images/default-avatar-lg.jpg';
import GalleryImg from '../../images/gallery-temp.jpg';
import { ProfileIcon } from '../Profile/ProfileIcon';
import { Gallery } from '../Profile/Gallery';
import { ProfileDescriptionItem } from '../Profile/DescriptionItem';
import { ChatConvo } from '../Chat/ChatConvo';
import { ChatMeta } from '../Chat/ChatMeta';
import { ChatAlert } from '../Chat/ChatAlert';
import { ChatBubble } from '../Chat/ChatBubble';
import { ChatLoader } from '../Chat/ChatLoader';
import { ChatInput } from '../Chat/ChatInput';
import { Confirmation } from '../Confirmation/Confirmation';

// Exhibition Components
import { ExhibitionHeading } from '../Exhibition/ExhibitionHeading';
import { LeaderboardList } from '../Exhibition/LeaderboardList';
import { LeaderboardItem } from '../Exhibition/LeaderboardItem';
import { ChatStreamItem } from '../Exhibition/ChatStreamItem';

export function Showcase() {
  const time = new Date();
  const empty = () => {
    // Just an empty function
  };

  return (
    <section className="showcase">
      <section className="showcase__section showcase--mobile">
        <h1 className="showcase__title">Showcase</h1>
        <h2 className="showcase__subtitle">Logo</h2>
        <div className="showcase__grid">
          <Logo />
          <Logo logoSize="lg" logoType="typeform" />
        </div>
        <h2 className="showcase__subtitle">Loader</h2>
        <div className="showcase__grid">
          <Loader />
          <Loader size="sm" theme="white" />
        </div>
        <h2 className="showcase__subtitle">App Footer</h2>
        <Footer time={time} />
        <h2 className="showcase__subtitle">App Header</h2>
        <HeaderApp />
        <h2 className="showcase__subtitle">Header Marketing</h2>
        <HeaderMarketing signInClick={empty} signUpClick={empty} />
        <h2 className="showcase__subtitle">Header Marketing With Sign Out</h2>
        <HeaderMarketing signOutClick={empty} />
        <h2 className="showcase__subtitle">Header Overlay</h2>
        <HeaderOverlay>Your Account</HeaderOverlay>
        <HeaderOverlay
          profileIcon={true}
          profileIconAvatar={Avatar}
          profileIconName="Meemo"
          userStatus="online"
        >
          Meemo
        </HeaderOverlay>
        <h2 className="showcase__subtitle">Icons</h2>
        <p className="showcase__label">--xl</p>
        <Icon size="xl" name="block" />
        <Icon size="xl" name="chat" />
        <Icon size="xl" name="favourite" />
        <p className="showcase__label">--lg</p>
        <Icon size="lg" name="user" />
        <Icon size="lg" name="notifications" />
        <Icon size="lg" name="chat" />
        <p className="showcase__label">--md</p>
        <Icon size="md" name="arrow-up" />
        <Icon size="md" name="arrow-down" />
        <Icon size="md" name="arrow-left" />
        <Icon size="md" name="arrow-right" />
        <Icon size="md" name="check" />
        <Icon size="md" name="close" />
        <Icon size="md" name="favourite" />
        <Icon size="md" name="flame" />
        <Icon size="md" name="heartbreak" />
        <Icon size="md" name="photo" />
        <Icon size="md" name="sign-out" />
        <Icon size="md" name="stalk" />
        <Icon size="md" name="thumbs-down" />
        <Icon size="md" name="thumbs-up" />
        <Icon size="md" name="undo" />
        <p className="showcase__label">--glow</p>
        <Icon size="md" name="chat" theme="glow" />
        <Icon size="md" name="favourite" theme="glow" />
        <Icon size="md" name="block" theme="glow" />
        <p className="showcase__label">--notification</p>
        <Icon size="lg" name="chat" theme="notification" />
        <Icon size="lg" name="notifications" theme="notification" />
        <p className="showcase__label">--sm</p>
        <Icon size="sm" name="user" />
        <Icon size="sm" name="photo" />
        <Icon size="sm" name="favourite" />
        <Icon size="sm" name="circle" />
        <p className="showcase__label">--xs</p>
        <Icon size="xs" name="favourite" />
        <Icon size="xs" name="block" />
        <h2 className="showcase__subtitle">Form Fields</h2>
        <div className="showcase__grid">
          <p className="showcase__label">Text Input</p>
          <TextInput type="text" />
          <TextInput disabled={true} type="text" />
        </div>
        <div className="form__container">
          <Fieldset label="Where do you live?">
            <TextInput type="text" placeholder="ex. Vancovuer" />
          </Fieldset>
          <Fieldset label="What is your favourite cheese?">
            <TextInput type="text" />
          </Fieldset>
          <Fieldset label="What cheese do you want?">
            <RadioInput checked={false} name="cheese-selection" value="cheddar">
              Cheddar
            </RadioInput>
            <RadioInput checked={true} name="cheese-selection" value="gouda">
              Gouda
            </RadioInput>
            <RadioInput
              disabled={true}
              checked={false}
              name="cheese-selection"
              value="blue cheese"
            >
              Blue Cheese
            </RadioInput>
          </Fieldset>
          <Fieldset label="What cheese do you want?">
            <CheckboxInput
              checked={false}
              name="cheese-selection"
              value="cheddar"
            >
              Cheddar
            </CheckboxInput>
            <CheckboxInput checked={true} name="cheese-selection" value="gouda">
              Gouda
            </CheckboxInput>
            <CheckboxInput
              disabled={true}
              checked={false}
              name="cheese-selection"
              value="blue cheese"
            >
              Blue Cheese
            </CheckboxInput>
          </Fieldset>
          <Fieldset label="What cheese do you want?">
            <SelectInput
              buttonLabel="Select an option"
              handleSelect={(option) => {
                // Empty handler for now.
              }}
              options={[
                { jsx: <>{'Cheddar'}</>, value: 'cheddar' },
                { jsx: <>{'Gouda'}</>, value: 'gouda' },
                { jsx: <>{'Blue Cheese'}</>, value: 'blue cheese' },
                { jsx: <>{'Goat Cheese'}</>, value: 'Goat cheese' },
                { jsx: <>{'Feta'}</>, value: 'feta' },
              ]}
            />
          </Fieldset>
        </div>
        <h2 className="showcase__subtitle">Icon Buttons</h2>
        <div className="showcase__grid">
          <p className="showcase__label">Nav Items</p>
          <IconButton buttonType="nav" iconName="user">
            Account
          </IconButton>
          <IconButton buttonType="nav" iconName="chat">
            Chat
          </IconButton>
          <IconButton
            buttonType="nav"
            iconName="notifications"
            iconTheme="notification"
          >
            Updates
          </IconButton>
        </div>
        <div className="showcase__grid">
          <p className="showcase__label">Profile</p>
          <IconButton buttonType="profile" iconName="chat">
            Chat
          </IconButton>
          <IconButton buttonType="profile" iconName="favourite">
            Fave
          </IconButton>
          <IconButton buttonType="profile" iconName="heartbreak">
            Unfave
          </IconButton>
          <IconButton buttonType="profile" iconName="block">
            Bye
          </IconButton>
          <IconButton buttonType="profile" iconName="block">
            Unblock
          </IconButton>
        </div>
        <div className="showcase__grid">
          <p className="showcase__label">Labelless</p>
          <IconButton buttonType="labelless" iconName="arrow-left">
            Back
          </IconButton>
          <IconButton buttonType="labelless" iconName="arrow-up">
            Up
          </IconButton>
          <IconButton buttonType="labelless" iconName="arrow-down">
            Down
          </IconButton>
          <IconButton buttonType="labelless" iconName="arrow-right">
            Next
          </IconButton>
          <IconButton buttonType="labelless" iconName="check">
            Check
          </IconButton>
          <IconButton buttonType="labelless" iconName="close">
            Close
          </IconButton>
        </div>
        <h2 className="showcase__subtitle">Profile Icon</h2>
        <div className="showcase__grid showcase__background">
          <ProfileIcon userStatus="online" image={Avatar} name="Meemo" />
          <ProfileIcon userStatus="inactive" image={Avatar} name="Meemo" />
          <ProfileIcon userStatus="offline" image={Avatar} name="Meemo" />
        </div>
        <h2 className="showcase__subtitle">Text Links</h2>
        <div className="showcase__grid">
          <TextLink>I am a link!</TextLink>
        </div>
        <h2 className="showcase__subtitle">Buttons</h2>
        <div className="showcase__grid showcase--stretch">
          <p className="showcase__label">With Icons</p>
          <Button buttonType="primary" iconName="flame">
            Hot
          </Button>
          <Button buttonType="secondary" iconName="thumbs-down">
            Not
          </Button>
        </div>
        <div className="showcase__grid">
          <p className="showcase__label">Primary</p>
          <Button buttonType="primary">Button</Button>
          <Button buttonType="primary" iconName="flame">
            Hot
          </Button>
          <Button buttonType="primary" disabled={true}>
            Button
          </Button>
        </div>
        <div className="showcase__grid">
          <p className="showcase__label">Secondary</p>
          <Button>Button</Button>
          <Button buttonType="secondary" iconName="thumbs-down">
            Not
          </Button>
          <Button disabled={true}>Button</Button>
        </div>
        <div className="showcase__grid">
          <p className="showcase__label">Tertiary</p>
          <Button buttonType="tertiary">Button</Button>
          <Button buttonType="tertiary" iconName="sign-out">
            Sign Out
          </Button>
          <Button disabled={true} buttonType="tertiary">
            Button
          </Button>
        </div>
        <div className="showcase__background">
          <p className="showcase__label">Account</p>
          <Button buttonType="account" iconName="user">
            Edit Profile
          </Button>
          <Button buttonType="account" iconName="photo">
            Edit Photos
          </Button>
          <Button buttonType="account" iconName="sign-out">
            Sign Out
          </Button>
        </div>
        <h2 className="showcase__subtitle">Alert Banner</h2>
        <AlertBanner>Interacting as Persona: George</AlertBanner>
        <AlertBanner alertType="warning">
          Persona George is DEAD. Do not update!
        </AlertBanner>
        <h2 className="showcase__subtitle">Feedback Messages</h2>
        <Feedback>
          Uh oh! That doesn't look like a valid email. Try again.
        </Feedback>
        <br />
        <Feedback msgType="strong">
          By agreeing to these terms, you will agree that any content you upload
          to the web app could be used for performance purposes.
        </Feedback>
        <h2 className="showcase__subtitle">Chat Components</h2>
        <ChatConvo>
          <ChatMeta>Tuesday 3:55 PM</ChatMeta>
          <ChatBubble type="reciever">Hello there</ChatBubble>
          <ChatBubble type="sender">General Kenobi</ChatBubble>
          <ChatBubble type="sender">haha</ChatBubble>
          <ChatBubble type="reciever">
            Do you like cats? How many cats do you have? What is your favourite
            kind of cat?
          </ChatBubble>
          <ChatBubble type="reciever">Do you like yogurt?</ChatBubble>
          <ChatBubble type="sender">Whoa, dude, dial it in</ChatBubble>
          <ChatBubble type="sender">
            I'm looking for love, not a stalker
          </ChatBubble>
          <ChatLoader />
          <ChatAlert
            iconName="heartbreak"
            alertTitle="Blocked"
            alertDescription="You have been"
          />
          <ChatAlert
            iconName="block"
            alertTitle="Disabled"
            alertDescription="Chat has been"
          />
        </ChatConvo>

        <ChatInput />
        <h2 className="showcase__subtitle">Status Tag</h2>
        <div className="showcase__grid">
          <StatusTag statusState="online">Penelope</StatusTag>
          <StatusTag statusState="inactive">Penelope</StatusTag>
          <StatusTag statusState="offline">Penelope</StatusTag>
          <StatusTag statusState="user">This is you!</StatusTag>
        </div>
        <h2 className="showcase__subtitle">Notification Item</h2>
        <NotificationItem
          notifType="inbox"
          notifSeen={false}
          profileName="Meemo"
          profileImage={Avatar}
          notifMessage="I hope you like a variety of cheese."
          notifAction="thinks you are TOO HOT"
          profileBlocked={true}
          notifDate="1 day ago"
        />
        <NotificationItem
          notifType="inbox"
          notifSeen={false}
          profileName="Meemo"
          profileImage={Avatar}
          notifMessage="I hope you like a variety of cheese."
        />
        <NotificationItem
          notifType="update"
          notifSeen={true}
          profileName="Meemo"
          profileImage={Avatar}
          notifAction="thinks you are TOO HOT"
        />
        <h2 className="showcase__subtitle">Tags</h2>
        <div className="showcase__grid">
          <Tag iconName="chat">445</Tag>
        </div>
        <div className="showcase__grid">
          <Tag>31 years old</Tag>
        </div>
        <div className="showcase__grid">
          <Tag>Male</Tag>
        </div>
        <h2 className="showcase__subtitle">Persona</h2>
        <Persona
          name="George"
          image={Avatar}
          age={34}
          gender={Gender.man}
          sexuality={Sexuality.straight}
          tagIcon="chat"
          tagContent={445}
        />
        <Persona
          name="Josie"
          image={Avatar}
          gender={Gender.woman}
          tagIcon="chat"
          tagContent={1409}
        />
        <Persona name="Hasan" image={Avatar} age={34} gender={Gender.man} />
        <Persona name="Janet" image={Avatar} age={29} />
        <Persona
          name="Janet"
          image={Avatar}
          age={27}
          sexuality={Sexuality.bisexual}
        />
        <Persona name="Janet" image={Avatar} sexuality={Sexuality.lesbian} />
        <Persona name="Josie" image={Avatar} tagIcon="chat" tagContent="0" />

        <h2 className="showcase__subtitle">Listing</h2>
        <div className="showcase__grid">
          <Listing name="Josie" image={Avatar} status="user" />
          <Listing name="Gary" image={Avatar} status="online" />
        </div>
        <h2 className="showcase__subtitle">Profile Gallery</h2>
        <Gallery>
          <img src={GalleryImg} alt="Meow" />
          <img src={GalleryImg} alt="Meow" />
          <img src={GalleryImg} alt="Meow" />
          <img src={GalleryImg} alt="Meow" />
          <img src={GalleryImg} alt="Meow" />
        </Gallery>
        <h2 className="showcase__subtitle">Profile Desription</h2>
        <div className="showcase__background">
          <ul className="profile-description">
            <ProfileDescriptionItem itemLabel="Who? Qui?">
              Praesent elementum facilisis leo vel fringilla est ullamcorper.
              Erat nam at lectus urna duis convallis.
            </ProfileDescriptionItem>
            <ProfileDescriptionItem itemLabel="What? Quoi?">
              Enim praesent elementum facilisis leo vel fringilla. Lorem ipsum
              dolor sit amet consectetur adipiscing. Ultrices tincidunt arcu non
              sodales neque sodales ut etiam sit.
            </ProfileDescriptionItem>
            <ProfileDescriptionItem itemLabel="Why? Pourquoi?">
              Sed egestas egestas fringilla phasellus faucibus scelerisque
              eleifend donec pretium. A scelerisque purus semper eget duis at
              tellus at urna. Purus in massa tempor nec feugiat nisl pretium
              fusce id. Vel elit scelerisque mauris pellentesque. Pharetra sit
              amet aliquam id diam maecenas ultricies. Orci phasellus egestas
              tellus rutrum tellus pellentesque eu. Proin sed libero enim sed
              faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris a.
              In nulla posuere sollicitudin aliquam ultrices sagittis orci.
              Ornare arcu odio ut sem.
            </ProfileDescriptionItem>
            <ProfileDescriptionItem itemLabel="How? Comment?">
              A scelerisque purus semper eget duis at tellus at urna. Purus in
              massa tempor nec feugiat nisl pretium fusce id.
            </ProfileDescriptionItem>
            <ProfileDescriptionItem itemLabel="When? Quand?">
              Vel elit scelerisque mauris pellentesque. Pharetra sit amet
              aliquam id diam maecenas ultricies. Orci phasellus egestas tellus
              rutrum tellus pellentesque eu.
            </ProfileDescriptionItem>
            <ProfileDescriptionItem itemLabel="P.S.">
              Proin sed libero enim sed faucibus. Mauris nunc congue nisi vitae
              suscipit tellus mauris a. In nulla posuere sollicitudin aliquam
              ultrices sagittis orci. Ornare arcu odio ut sem.
            </ProfileDescriptionItem>
          </ul>
        </div>
        <h2 className="showcase__subtitle">Confirmation Page</h2>
        <Confirmation pageType="default">
          <h1 className="heading">Email Sent</h1>
          <p>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
          <Button buttonType="secondary">Go Back</Button>
        </Confirmation>
        <Confirmation pageType="wide-button">
          <h1 className="heading">Delete Profile</h1>
          <p>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
          <Button buttonType="primary">Yes, Delete it</Button>
          <Button buttonType="secondary">No, Keep it</Button>
        </Confirmation>
        <Confirmation pageType="full">
          <h1 className="heading">Confirmation Complete!</h1>
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
          <p>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
          <Button buttonType="primary">Let's Start Flirting!</Button>
        </Confirmation>
        <h2 className="showcase__subtitle">
          Paragraphs, Unordered &amp; Ordered Lists
        </h2>
        <div>
          <p className="paragraph">
            Pharetra sit amet aliquam id diam maecenas ultricies. Orci phasellus
            egestas tellus rutrum tellus pellentesque eu. Proin sed libero enim
            sed faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris
            a. In nulla posuere sollicitudin aliquam ultrices sagittis orci.
            Ornare arcu odio ut sem.
          </p>
          <p className="paragraph">
            Sed egestas egestas fringilla phasellus faucibus scelerisque
            eleifend donec pretium. A scelerisque purus semper eget duis at
            tellus at urna. Purus in massa tempor nec feugiat nisl pretium fusce
            id. Vel elit scelerisque mauris pellentesque.
          </p>
          <ul className="list list--ul">
            <li>
              Sed egestas egestas fringilla phasellus faucibus scelerisque
              eleifend donec pretium.
            </li>
            <li>A scelerisque purus semper eget duis at tellus at urna.</li>
            <li>
              Purus in massa tempor nec feugiat nisl pretium fusce id.
              <ul className="list">
                <li>
                  Orci phasellus egestas tellus rutrum tellus pellentesque eu.
                </li>
                <li>Proin sed libero enim sed faucibus.</li>
                <li>
                  Mauris nunc congue nisi vitae suscipit tellus mauris a. In
                  nulla posuere sollicitudin aliquam ultrices sagittis orci.
                  Ornare arcu odio ut sem.
                </li>
              </ul>
            </li>
            <li>
              Vel elit scelerisque mauris pellentesque. Pharetra sit amet
              aliquam id diam maecenas ultricies.
            </li>
            <li>
              Orci phasellus egestas tellus rutrum tellus pellentesque eu.
            </li>
            <li>Proin sed libero enim sed faucibus.</li>
            <li>
              Mauris nunc congue nisi vitae suscipit tellus mauris a. In nulla
              posuere sollicitudin aliquam ultrices sagittis orci. Ornare arcu
              odio ut sem.
            </li>
          </ul>
          <p className="paragraph">
            Sed egestas egestas fringilla phasellus faucibus scelerisque
            eleifend donec pretium. A scelerisque purus semper eget duis at
            tellus at urna. Purus in massa tempor nec feugiat nisl pretium fusce
            id. Vel elit scelerisque mauris pellentesque.
          </p>
          <ol className="list list--ol">
            <li>
              Sed egestas egestas fringilla phasellus faucibus scelerisque
              eleifend donec pretium.
            </li>
            <li>A scelerisque purus semper eget duis at tellus at urna.</li>
            <li>
              Purus in massa tempor nec feugiat nisl pretium fusce id.
              <ol className="list">
                <li>
                  Orci phasellus egestas tellus rutrum tellus pellentesque eu.
                </li>
                <li>Proin sed libero enim sed faucibus.</li>
                <li>
                  Mauris nunc congue nisi vitae suscipit tellus mauris a. In
                  nulla posuere sollicitudin aliquam ultrices sagittis orci.
                  Ornare arcu odio ut sem.
                </li>
              </ol>
            </li>
            <li>
              Vel elit scelerisque mauris pellentesque. Pharetra sit amet
              aliquam id diam maecenas ultricies.
            </li>
            <li>
              Orci phasellus egestas tellus rutrum tellus pellentesque eu.
            </li>
            <li>Proin sed libero enim sed faucibus.</li>
            <li>
              Mauris nunc congue nisi vitae suscipit tellus mauris a. In nulla
              posuere sollicitudin aliquam ultrices sagittis orci. Ornare arcu
              odio ut sem.
            </li>
          </ol>
        </div>
      </section>
      <section className="showcase__section showcase--display">
        <h2 className="showcase__title">Gallery Display Items</h2>
        <h2 className="showcase__subtitle">Chat Stream Components</h2>
        <ChatStreamItem>
          Vel elit scelerisque mauris pellentesque. Pharetra sit amet aliquam id
          diam maecenas ultricies.
        </ChatStreamItem>
        <ChatStreamItem>
          Orci phasellus egestas tellus rutrum tellus pellentesque eu.
        </ChatStreamItem>
        <ChatStreamItem>Proin sed libero enim sed faucibus.</ChatStreamItem>
        <ChatStreamItem>
          Mauris nunc congue nisi vitae suscipit tellus mauris a. In nulla
          posuere sollicitudin aliquam ultrices sagittis orci. Ornare arcu odio
          ut sem.
        </ChatStreamItem>
        <h2 className="showcase__subtitle">Leaderboard Components</h2>
        <ExhibitionHeading iconName="flame">
          <strong>Hot</strong> Top 5
        </ExhibitionHeading>
        <ExhibitionHeading iconName="thumbs-down">
          <strong>Not</strong> Top 5
        </ExhibitionHeading>
        <LeaderboardList>
          <LeaderboardItem image={Avatar} name="Josie" />
          <LeaderboardItem image={Avatar} name="Angelo" />
          <LeaderboardItem image={Avatar} name="Meemo" />
          <LeaderboardItem image={Avatar} name="Alfred Pennyworth" />
          <LeaderboardItem image={Avatar} name="Damien" />
        </LeaderboardList>
      </section>
    </section>
  );
}
