import React from 'react';
import { ProfileIcon } from './ProfileIcon';
import Button from '../Button/Button';
import Avatar from '../../images/default-avatar-lg.jpg';
import './EditListItem.scss';
import { ProfileStatus } from '../../types';

interface EditListProps {
  profileImage: string;
  profileName: string;
  userStatus: ProfileStatus;
  profileClick?: (e: React.SyntheticEvent) => void;
  editClick: (e: React.SyntheticEvent) => void;
  editLabel?: string;
}

export function EditListItem(props: EditListProps) {
  const {
    userStatus,
    profileImage,
    profileName,
    profileClick,
    editClick,
    editLabel,
  } = props;
  return (
    <li className="edit-list__item">
      <ProfileIcon
        onClick={profileClick}
        name={profileName}
        image={profileImage ? profileImage : Avatar}
        userStatus={userStatus}
      />
      <span className="edit-list__name">{profileName}</span>
      <Button
        className="edit-list__button"
        buttonType="tertiary"
        onClick={editClick}
      >
        {editLabel}
      </Button>
    </li>
  );
}
