import React, { FunctionComponent, SVGProps } from 'react';
import classNames from 'classnames';
import './Icon.scss';

import { ReactComponent as IconArrowDown } from '../../images/icon/arrow-down.svg';
import { ReactComponent as IconArrowUp } from '../../images/icon/arrow-up.svg';
import { ReactComponent as IconArrowLeft } from '../../images/icon/arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../../images/icon/arrow-right.svg';
import { ReactComponent as IconBlock } from '../../images/icon/block.svg';
import { ReactComponent as IconChat } from '../../images/icon/chat.svg';
import { ReactComponent as IconCheck } from '../../images/icon/check.svg';
import { ReactComponent as IconClose } from '../../images/icon/close.svg';
import { ReactComponent as IconFavourite } from '../../images/icon/favourite.svg';
import { ReactComponent as IconFlame } from '../../images/icon/flame.svg';
import { ReactComponent as IconHeartBreak } from '../../images/icon/heartbreak.svg';
import { ReactComponent as IconNotifications } from '../../images/icon/notifications.svg';
import { ReactComponent as IconPhoto } from '../../images/icon/photo.svg';
import { ReactComponent as IconSignOut } from '../../images/icon/sign-out.svg';
import { ReactComponent as IconStalk } from '../../images/icon/stalk.svg';
import { ReactComponent as IconThumbsDown } from '../../images/icon/thumbs-down.svg';
import { ReactComponent as IconThumbsUp } from '../../images/icon/thumbs-up.svg';
import { ReactComponent as IconUser } from '../../images/icon/user.svg';
import { ReactComponent as IconCircle } from '../../images/icon/circle.svg';
import { ReactComponent as IconUndo } from '../../images/icon/undo.svg';

export type IconName =
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'block'
  | 'chat'
  | 'check'
  | 'close'
  | 'favourite'
  | 'flame'
  | 'heartbreak'
  | 'notifications'
  | 'photo'
  | 'sign-out'
  | 'stalk'
  | 'thumbs-down'
  | 'thumbs-up'
  | 'user'
  | 'circle'
  | 'undo';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type IconTheme = 'default' | 'glow' | 'notification';

interface IconData {
  hasGlow: boolean;
  hasNotification: boolean;
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
}

type IconDataMap = { [key in IconName]: IconData };

const iconData: IconDataMap = {
  'arrow-up': {
    hasGlow: true,
    hasNotification: false,
    component: IconArrowUp,
  },
  'arrow-down': {
    hasGlow: true,
    hasNotification: false,
    component: IconArrowDown,
  },
  'arrow-left': {
    hasGlow: true,
    hasNotification: false,
    component: IconArrowLeft,
  },
  'arrow-right': {
    hasGlow: true,
    hasNotification: false,
    component: IconArrowRight,
  },
  block: {
    hasGlow: true,
    hasNotification: false,
    component: IconBlock,
  },
  chat: {
    hasGlow: true,
    hasNotification: true,
    component: IconChat,
  },
  check: {
    hasGlow: true,
    hasNotification: false,
    component: IconCheck,
  },
  close: {
    hasGlow: true,
    hasNotification: false,
    component: IconClose,
  },
  favourite: {
    hasGlow: true,
    hasNotification: false,
    component: IconFavourite,
  },
  flame: {
    hasGlow: true,
    hasNotification: false,
    component: IconFlame,
  },
  heartbreak: {
    hasGlow: true,
    hasNotification: false,
    component: IconHeartBreak,
  },
  notifications: {
    hasGlow: true,
    hasNotification: true,
    component: IconNotifications,
  },
  photo: {
    hasGlow: true,
    hasNotification: false,
    component: IconPhoto,
  },
  'sign-out': {
    hasGlow: true,
    hasNotification: false,
    component: IconSignOut,
  },
  stalk: {
    hasGlow: true,
    hasNotification: false,
    component: IconStalk,
  },
  'thumbs-down': {
    hasGlow: true,
    hasNotification: false,
    component: IconThumbsDown,
  },
  'thumbs-up': {
    hasGlow: true,
    hasNotification: false,
    component: IconThumbsUp,
  },
  user: {
    hasGlow: true,
    hasNotification: false,
    component: IconUser,
  },
  circle: {
    hasGlow: false,
    hasNotification: false,
    component: IconCircle,
  },
  undo: {
    hasGlow: false,
    hasNotification: false,
    component: IconUndo,
  },
};

interface IconProps {
  className?: string;
  name?: IconName;
  size?: IconSize;
  theme?: IconTheme;
}

export function Icon({
  className = '',
  name = 'favourite',
  size = 'md',
  theme = 'default',
}: IconProps) {
  const { hasGlow, hasNotification, component } = iconData[name];
  const isGlowing = hasGlow && theme === 'glow';
  const notificationActive = hasNotification && theme === 'notification';

  return React.createElement(component, {
    className: classNames('icon', `icon-${name}`, `icon--${size}`, className, {
      'icon--glow': isGlowing,
      'icon--notification': notificationActive,
    }),
  });
}
