import React from 'react';
import {
  OptionInput,
  InputName,
  InputValue,
  InputChecked,
  InputDisabled,
} from '../Form/OptionInput';

interface CheckboxProps {
  name: InputName;
  value: InputValue;
  checked: InputChecked;
  disabled?: InputDisabled;
  children?: React.ReactNode;
  onClick?: (value: string | number) => void;
}

export function CheckboxInput({
  children,
  name,
  value,
  checked,
  disabled,
  onClick,
}: CheckboxProps) {
  return (
    <OptionInput
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </OptionInput>
  );
}
