import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as EditProfileActor } from '../../machines/EditProfileMachine';
import { Actor as UploadPhotoActor } from '../../machines/UploadPhotoMachine';
import { Gender, Sexuality } from '../../types';
import Button from '../Button/Button';
import { Fieldset } from '../Form/Fieldset';
import { TextInput } from '../Form/TextInput';
import { TextArea } from '../Form/TextArea';
import { SelectInput } from '../Form/SelectInput';
import { UploadPhoto } from '../UploadPhoto/UploadPhoto';
import { Feedback } from '../Feedback/Feedback';
import { GenderTag } from '../GenderTag/GenderTag';
import { SexualityTag } from '../SexualityTag/SexualityTag';
import { Loader } from '../Loader/Loader';
import { Confirmation } from '../Confirmation/Confirmation';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';

import './EditProfile.scss';

export function EditProfile({ actor }: { actor: EditProfileActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();
  const is2Fik = state.context.is2Fik;
  const profile = state.context.profile;
  const born = state.context.born;

  const title =
    profile.state === 'new'
      ? t('editProfile.titleNew')
      : t('editProfile.title');

  switch (true) {
    case state.matches('edit'):
      return (
        <main className="edit-profile">
          <Helmet>
            <title>
              {t('title')} - {title}
            </title>
          </Helmet>
          <div className="edit-profile__wrapper page">
            <div className="edit-profile__section">
              <h1 className="heading edit-profile__heading">{title}</h1>
              <p className="edit-profile__description">
                {t('editProfile.body')}
              </p>
            </div>
            <div className="edit-profile__section">
              <h2 className="subheading edit-profile__subheading">
                {t('editProfile.form.basicInfoTitle')}
              </h2>
              {state.matches('edit.fields.invalid') && (
                <Feedback className="edit-profile__feedback">
                  <ScrollToTop />
                  {t('editProfile.form.requiredFields')}
                </Feedback>
              )}
              <div className="form__container">
                <Fieldset
                  label={t('editProfile.form.nameLabel')}
                  labelFor="name"
                  hint={t('editProfile.form.required')}
                >
                  <TextInput
                    id="name"
                    value={profile.name}
                    className={
                      state.matches('edit.fields.invalid')
                        ? 'form--error'
                        : 'form--default'
                    }
                    onChange={(event) =>
                      send({ type: 'setName', name: event.target.value })
                    }
                  />
                </Fieldset>
                <Fieldset
                  label={t('editProfile.form.birthdateLabel')}
                  labelFor="born"
                  hint={t('editProfile.form.required')}
                >
                  <TextInput
                    id="born"
                    type="date"
                    value={born}
                    className={
                      state.matches('edit.fields.invalid')
                        ? 'form--error'
                        : 'form--default'
                    }
                    placeholder={t('editProfile.form.birthdatePlaceholder')}
                    onChange={(event) =>
                      send({
                        type: 'setBorn',
                        born: event.target.value,
                      })
                    }
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.genderLabel')}>
                  <SelectInput
                    buttonLabel={t('editProfile.form.selectLabel')}
                    handleSelect={(value) => {
                      send({
                        type: 'setGender',
                        gender: value as Gender,
                      });
                    }}
                    initial={
                      profile.gender !== Gender.none
                        ? profile.gender
                        : undefined
                    }
                    options={Object.values(Gender)
                      .filter((g) => g !== Gender.none)
                      .map((value) => {
                        return { jsx: <GenderTag gender={value} />, value };
                      })}
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.sexualLabel')}>
                  <SelectInput
                    buttonLabel={t('editProfile.form.selectLabel')}
                    handleSelect={(value) => {
                      send({
                        type: 'setSexuality',
                        sexuality: value as Sexuality,
                      });
                    }}
                    initial={
                      profile.sexuality !== Sexuality.none
                        ? profile.sexuality
                        : undefined
                    }
                    options={Object.values(Sexuality)
                      .filter((g) => g !== Sexuality.none)
                      .map((value) => {
                        return {
                          jsx: <SexualityTag sexuality={value} />,
                          value,
                        };
                      })}
                  />
                </Fieldset>
              </div>
            </div>
            {profile.state === 'new' && (
              <div className="edit-profile__section">
                {state.matches('edit.photo.ready') ? (
                  <UploadPhoto
                    actor={
                      state.children.uploadPhotoMachine as UploadPhotoActor
                    }
                  />
                ) : (
                  <>
                    <h2 className="subheading edit-profile__subheading">
                      {t('editProfile.form.photoTitle')}
                    </h2>
                    <img
                      className="upload-photo__image"
                      src={state.context.profile.images[0].md}
                      alt="Profile"
                    />
                  </>
                )}
              </div>
            )}
            <div className="edit-profile__section">
              <h2 className="subheading edit-profile__subheading">
                {t('editProfile.form.tellUsTitle')}
              </h2>
              <div className="form__container">
                <Fieldset
                  label={t('editProfile.form.headline')}
                  labelFor="headline"
                >
                  <TextInput
                    id="headline"
                    value={profile.headline}
                    onChange={(event) =>
                      send({
                        type: 'setHeadline',
                        headline: event.target.value,
                      })
                    }
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.who')} labelFor="who">
                  <TextArea
                    id="who"
                    value={profile.who}
                    onChange={(event) =>
                      send({ type: 'setWho', who: event.target.value })
                    }
                  />
                </Fieldset>

                <Fieldset label={t('editProfile.form.what')} labelFor="what">
                  <TextArea
                    id="what"
                    value={profile.what}
                    onChange={(event) =>
                      send({ type: 'setWhat', what: event.target.value })
                    }
                  />
                </Fieldset>

                <Fieldset label={t('editProfile.form.why')} labelFor="why">
                  <TextArea
                    id="why"
                    value={profile.why}
                    onChange={(event) =>
                      send({ type: 'setWhy', why: event.target.value })
                    }
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.how')} labelFor="how">
                  <TextArea
                    id="how"
                    value={profile.how}
                    onChange={(event) =>
                      send({ type: 'setHow', how: event.target.value })
                    }
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.when')} labelFor="when">
                  <TextArea
                    id="when"
                    value={profile.when}
                    onChange={(event) =>
                      send({ type: 'setWhen', when: event.target.value })
                    }
                  />
                </Fieldset>
                <Fieldset label={t('editProfile.form.else')} labelFor="else">
                  <TextArea
                    id="else"
                    value={profile.else}
                    onChange={(event) =>
                      send({ type: 'setElse', else: event.target.value })
                    }
                  />
                </Fieldset>
              </div>
            </div>
            <div className="edit-profile__buttons">
              <Button
                buttonType="primary"
                className="edit-profile__button edit-profile__save"
                onClick={() => send({ type: 'saveProfile' })}
              >
                {t('editProfile.form.buttonTitle')}
              </Button>
              {!is2Fik && (
                <Button
                  buttonType="tertiary"
                  className="edit-profile__button edit-profile__delete"
                  onClick={() => send({ type: 'deleteProfile' })}
                >
                  {t('deleteProfile.button')}
                </Button>
              )}
            </div>
          </div>
        </main>
      );

    case state.matches('saving'):
      return (
        <>
          <ScrollToTop />
          <Loader theme="full-page" />
        </>
      );

    case state.matches('failure'):
      return (
        <Confirmation>
          <h1>{t('editProfile.form.saveErrorTitle')}</h1>
          <p>{t('editProfile.form.saveError')}</p>
          <Button
            buttonType="primary"
            onClick={() => send({ type: 'editProfile' })}
          >
            {t('editProfile.title')}
          </Button>
        </Confirmation>
      );

    default:
      return null;
  }
}
