import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import {
  Document,
  ConfigDocument,
  LastReadDocument,
  MessageDocument,
  ProfileDocument,
  RankDocument,
  TypingDocument,
  Image,
  Store,
  User,
  Sexuality,
  Gender,
  UploadState,
  Clause,
} from '../types';

import lg from '../images/default-avatar-lg.jpg';
import md from '../images/default-avatar-md.jpg';
import sm from '../images/default-avatar-sm.jpg';
import xs from '../images/default-avatar-xs.jpg';

export const DEFAULT_IMAGE = { id: 'default', lg, md, sm, xs };

type DocumentType =
  | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

function watchDocuments<TransformTo>(
  collection: string,
  clauses: Clause[],
  transform: (
    doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
  ) => TransformTo,
  load: (doc: TransformTo) => void,
  unload: (doc: TransformTo) => void,
  complete: () => void
) {
  let query: firebase.firestore.Query = firebase
    .firestore()
    .collection(collection);

  for (const clause of clauses) {
    if (clause.type === 'where') {
      query = query.where(
        clause.fieldName,
        clause.operator || '==',
        clause.fieldValue
      );
    }
    if (clause.type === 'order') {
      query = query.orderBy(clause.fieldName, clause.direction);
    }
    if (clause.type === 'limit') {
      query = query.limit(clause.value);
    }
  }
  return query.onSnapshot((col) => {
    col.docChanges().forEach((change) => {
      // Might be being too clever here.
      (change.type === 'removed' ? unload : load)(transform(change.doc));
    });

    complete();
  });
}

function watchDocument<TransformTo>(
  collection: string,
  id: string,
  transform: (
    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => TransformTo,
  callback: (doc: TransformTo) => void
) {
  return firebase
    .firestore()
    .collection(collection)
    .doc(id)
    .onSnapshot((doc) => {
      if (doc.exists) {
        callback(transform(doc));
      }
    });
}

function getDocument<TransformTo>(
  collection: string,
  id: string,
  transform: (
    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => TransformTo
): Promise<TransformTo> {
  return firebase
    .firestore()
    .collection(collection)
    .doc(id)
    .get()
    .then(transform);
}

export function getState(value: any) {
  switch (value) {
    case 'new':
      return 'new';
    case '2fik':
      return '2fik';
    case 'banned':
      return 'banned';
    default:
      return 'user';
  }
}

export function getUploadState(value: any): UploadState {
  const base: UploadState = { state: 'ready.upload' };
  if (
    value !== null &&
    value !== undefined &&
    {}.hasOwnProperty.call(value, 'state')
  ) {
    switch (value.state) {
      case 'ready.error':
        return { state: 'ready.error' };
      case 'processing.upload':
        return { state: 'processing.upload' };
      case 'processing.resize':
        return { state: 'processing.resize' };
      case 'processing.crop':
        return { state: 'processing.crop' };
      case 'ready.crop':
        return {}.hasOwnProperty.call(value, 'path')
          ? { state: 'ready.crop', path: getString(value.path) }
          : base;
    }
  }

  return base;
}

export function getImages(vs: any): Image[] {
  if (Array.isArray(vs)) {
    const images = vs
      .filter((v: any) => {
        return (
          v !== null &&
          v !== undefined &&
          {}.hasOwnProperty.call(v, 'id') &&
          {}.hasOwnProperty.call(v, 'lg') &&
          {}.hasOwnProperty.call(v, 'md') &&
          {}.hasOwnProperty.call(v, 'sm') &&
          {}.hasOwnProperty.call(v, 'xs')
        );
      })
      .map((v: any) => {
        return {
          id: getString(v.id),
          lg: getString(v.lg),
          md: getString(v.md),
          sm: getString(v.sm),
          xs: getString(v.xs),
        };
      });

    return images.length > 0 ? [...images].reverse() : [DEFAULT_IMAGE];
  }
  return [DEFAULT_IMAGE];
}

export function getHistory(hs: any): Date[] {
  return Array.isArray(hs)
    ? hs
        .filter((v): v is firebase.firestore.Timestamp => {
          return v instanceof firebase.firestore.Timestamp;
        })
        .map((v) => {
          return v.toDate();
        })
    : [];
}

export function getDate(t: any): Date {
  return t instanceof firebase.firestore.Timestamp ? t.toDate() : new Date(0);
}

export function getGender(g: string): Gender {
  return Object.values(Gender).find((v) => v === g) || Gender.none;
}

export function getSexuality(s: string): Sexuality {
  return Object.values(Sexuality).find((v) => v === s) || Sexuality.none;
}

export function getLanguage(l: any) {
  return l === 'en' ? 'en' : 'fr';
}

export function getString(s: any) {
  return s !== undefined && s !== null ? String(s) : '';
}

export function getInteger(i: any) {
  return Number.isNaN(Number.parseInt(i, 10)) ? 0 : Number.parseInt(i, 10);
}

function transformToProfileDocument(doc: DocumentType): ProfileDocument {
  const lastRead = doc.get('lastRead', {
    serverTimestamps: 'estimate',
  });

  const lastOnline = doc.get('lastOnline', {
    serverTimestamps: 'estimate',
  });

  const born = doc.get('born');

  const fs = doc.get('favorites');
  const favorites = Array.isArray(fs) ? fs.map((v) => getString(v)) : [];

  const bs = doc.get('blocked');
  const blocked = Array.isArray(bs) ? bs.map((v) => getString(v)) : [];

  return {
    id: doc.id,
    name: getString(doc.get('name')),
    headline: getString(doc.get('headline')),
    who: getString(doc.get('who')),
    why: getString(doc.get('why')),
    how: getString(doc.get('how')),
    what: getString(doc.get('what')),
    when: getString(doc.get('when')),
    else: getString(doc.get('else')),
    images: getImages(doc.get('images')),
    favorites,
    blocked,
    isDead: !!doc.get('isDead'),
    isOnline: !!doc.get('isOnline'),
    state: getState(doc.get('state')),
    upload: getUploadState(doc.get('upload')),
    gender: getGender(getString(doc.get('gender'))),
    sexuality: getSexuality(getString(doc.get('sexuality'))),
    language: getLanguage(doc.get('language')),
    history: getHistory(doc.get('history')),
    order: getInteger(doc.get('order')),
    unreadCount: getInteger(doc.get('unreadCount')),
    hotCount: getInteger(doc.get('hotCount')),
    notCount: getInteger(doc.get('notCount')),

    // This is pretty cool. Allows you to optionally add defined properties.
    ...(lastOnline instanceof firebase.firestore.Timestamp && {
      lastOnline: lastOnline.toDate(),
    }),
    ...(born instanceof firebase.firestore.Timestamp && {
      born: born.toDate(),
    }),
    ...(lastRead instanceof firebase.firestore.Timestamp && {
      lastRead: lastRead.toDate(),
    }),
  };
}

function transformToMessageDocument(doc: DocumentType): MessageDocument {
  return {
    id: doc.id,
    to: getString(doc.get('to')),
    from: getString(doc.get('from')),
    body: getString(doc.get('body')),
    time: getDate(doc.get('time', { serverTimestamps: 'estimate' })),
  };
}

function transformToRankDocument(doc: DocumentType): RankDocument {
  return {
    id: doc.id,
    to: getString(doc.get('to')),
    from: getString(doc.get('from')),
    hot: !!doc.get('hot'),
    time: getDate(doc.get('time', { serverTimestamps: 'estimate' })),
  };
}

function transformToLastReadDocument(doc: DocumentType): LastReadDocument {
  return {
    id: doc.id,
    to: getString(doc.get('to')),
    from: getString(doc.get('from')),
    time: getDate(doc.get('time', { serverTimestamps: 'estimate' })),
  };
}

function transformToTypingDocument(doc: DocumentType): TypingDocument {
  return {
    id: doc.id,
    to: getString(doc.get('to')),
    from: getString(doc.get('from')),
    typing: !!doc.get('typing'),
  };
}

function transformToConfigDocument(doc: DocumentType): ConfigDocument {
  return {
    id: doc.id,
    chat: !!doc.get('chat'),
  };
}

function getDocumentWithTime(
  document: Record<symbol, unknown>,
  timeField: string
) {
  return {
    ...document,
    [timeField]: firebase.firestore.FieldValue.serverTimestamp(),
  };
}

export const store: Store = {
  delay: 2000,

  getProfileDocument: (id: string) =>
    getDocument<ProfileDocument>('profiles', id, transformToProfileDocument),

  isSignInWithEmailLink: (link: string) => {
    return firebase.auth().isSignInWithEmailLink(link);
  },

  onAuthStateChanged: (callback: (user?: User) => void) => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((result) =>
          callback({
            id: user.uid,
            is2Fik: result.claims.is2Fik === true,
          })
        );
      } else {
        callback();
      }
    });
  },

  sendSignUpLinkToEmail: (email: string, name: string) => {
    return firebase
      .functions()
      .httpsCallable('signUp')({
        email,
        name,
        url: window.location.href,
      })
      .then(() => {
        // Ignore this
      });
  },

  sendSignInLinkToEmail: (email: string) => {
    return firebase
      .functions()
      .httpsCallable('signIn')({
        email,
        url: window.location.href,
      })
      .then(() => {
        // Ignore this
      });
  },

  signInWithEmailLink: (email: string, link: string) => {
    return firebase
      .auth()
      .signInWithEmailLink(email, link)
      .then(() => {
        // Ignore the result our call to check auth state will catch it.
      });
  },

  signOut: () => {
    return firebase.auth().signOut();
  },

  addFile: (path: string, file: File) => {
    return firebase
      .storage()
      .ref(path)
      .put(file)
      .then(() => {
        // Ignore the result of upload. Our watchers will catch it.
      });
  },

  crop: (
    profileId: string,
    left: number,
    top: number,
    width: number,
    height: number
  ) =>
    firebase
      .functions()
      .httpsCallable('processCrop')({
        profileId,
        left,
        top,
        width,
        height,
      })
      .then(() => {
        // Ignore the result of crop. Our watchers will catch it.
      }),

  addDocument: (
    collection: string,
    document: Record<symbol, unknown>,
    timeField = ''
  ) => {
    return firebase
      .firestore()
      .collection(collection)
      .add(
        timeField !== '' ? getDocumentWithTime(document, timeField) : document
      )
      .then((doc) => {
        return { id: doc.id };
        // Ignore the result of a save. Our watchers will catch it.
      });
  },

  saveDocument: (collection: string, document: Document, timeField = '') => {
    const { id, ...doc } = document;

    return firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .set(timeField !== '' ? getDocumentWithTime(doc, timeField) : doc);
  },

  updateDocument: (collection: string, document: Document, timeField = '') => {
    const { id, ...doc } = document;

    return firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .update(timeField !== '' ? getDocumentWithTime(doc, timeField) : doc);
  },

  deleteDocument: (collection: string, document: Document) => {
    const { id } = document;

    return firebase.firestore().collection(collection).doc(id).delete();
  },

  watchProfileDocument: (
    id: string,
    callback: (doc: ProfileDocument) => void
  ) => watchDocument('profiles', id, transformToProfileDocument, callback),

  watchTypingDocument: (id: string, callback: (doc: TypingDocument) => void) =>
    watchDocument('typing', id, transformToTypingDocument, callback),

  watchLastReadDocument: (
    id: string,
    callback: (doc: LastReadDocument) => void
  ) => watchDocument('reads', id, transformToLastReadDocument, callback),

  watchConfigDocument: (id: string, callback: (doc: ConfigDocument) => void) =>
    watchDocument('configs', id, transformToConfigDocument, callback),

  watchProfileDocuments: (
    clauses: Clause[],
    load: (doc: ProfileDocument) => void,
    unload: (doc: ProfileDocument) => void,
    complete: () => void
  ) =>
    watchDocuments(
      'profiles',
      clauses,
      transformToProfileDocument,
      load,
      unload,
      complete
    ),

  watchMessageDocuments: (
    clauses: Clause[],
    load: (doc: MessageDocument) => void,
    unload: (doc: MessageDocument) => void,
    complete: () => void
  ) =>
    watchDocuments(
      'messages',
      clauses,
      transformToMessageDocument,
      load,
      unload,
      complete
    ),

  watchRankDocuments: (
    clauses: Clause[],
    load: (doc: RankDocument) => void,
    unload: (doc: RankDocument) => void,
    complete: () => void
  ) =>
    watchDocuments(
      'ranks',
      clauses,
      transformToRankDocument,
      load,
      unload,
      complete
    ),

  watchLastReadDocuments: (
    clauses: Clause[],
    load: (doc: LastReadDocument) => void,
    unload: (doc: LastReadDocument) => void,
    complete: () => void
  ) =>
    watchDocuments(
      'reads',
      clauses,
      transformToLastReadDocument,
      load,
      unload,
      complete
    ),

  setPresence: (profile: ProfileDocument) => {
    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    firebase.database().goOnline();

    const userStatusDatabaseRef = firebase
      .database()
      .ref(`/status/${profile.id}`);

    const userStatusFirestoreRef = firebase
      .firestore()
      .doc(`/profiles/${profile.id}`);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    const isOfflineForDatabase = {
      isOnline: false,
      lastOnline: firebase.database.ServerValue.TIMESTAMP,
    };

    const isOfflineForFirestore = {
      isOnline: false,
      lastOnline: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const isOnlineForDatabase = {
      isOnline: true,
      lastOnline: firebase.database.ServerValue.TIMESTAMP,
    };

    const isOnlineForFirestore = {
      isOnline: true,
      lastOnline: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase
      .database()
      .ref('.info/connected')
      .on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) {
          userStatusFirestoreRef.update(isOfflineForFirestore);
          return;
        }

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(function () {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
            userStatusFirestoreRef.update({
              ...isOnlineForFirestore,
              history: firebase.firestore.FieldValue.arrayUnion(
                firebase.firestore.Timestamp.now()
              ),
            });
          });
      });

    return () => {
      firebase.database().ref('.info/connected').off('value');
      firebase.database().goOffline();
    };
  },

  addToFavorites: (profile: ProfileDocument, favortie: ProfileDocument) =>
    firebase
      .firestore()
      .collection('profiles')
      .doc(profile.id)
      .update({
        favorites: firebase.firestore.FieldValue.arrayUnion(favortie.id),
      }),

  removeFromFavorites: (profile: ProfileDocument, favortie: ProfileDocument) =>
    firebase
      .firestore()
      .collection('profiles')
      .doc(profile.id)
      .update({
        favorites: firebase.firestore.FieldValue.arrayRemove(favortie.id),
      }),

  addToBlocked: (profile: ProfileDocument, blocked: ProfileDocument) =>
    firebase
      .firestore()
      .collection('profiles')
      .doc(profile.id)
      .update({
        blocked: firebase.firestore.FieldValue.arrayUnion(blocked.id),
      }),

  removeFromBlocked: (profile: ProfileDocument, blocked: ProfileDocument) =>
    firebase
      .firestore()
      .collection('profiles')
      .doc(profile.id)
      .update({
        blocked: firebase.firestore.FieldValue.arrayRemove(blocked.id),
      }),

  removeFromPhotos: (profile: ProfileDocument, photo: Image) =>
    firebase
      .firestore()
      .collection('profiles')
      .doc(profile.id)
      .update({ images: firebase.firestore.FieldValue.arrayRemove(photo) }),

  delete: (token: string) => {
    return firebase
      .functions()
      .httpsCallable('finalDelete')({
        token,
      })
      .then(() => {
        // Ignore this
      });
  },
  optOut: (token: string) => {
    return firebase
      .functions()
      .httpsCallable('optOut')({
        token,
      })
      .then(() => {
        // Ignore this
      });
  },
};
