import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import { Actor as ChatActor } from '../../machines/ChatMachine';
import { Actor as InboxActor } from '../../machines/InboxMachine';
import { Actor as BrowseActor } from '../../machines/BrowseMachine';
import { Actor as ProfileActor } from '../../machines/ProfileMachine';
import { Actor as UpdatesActor } from '../../machines/UpdatesMachine';
import { Actor as EditProfileActor } from '../../machines/EditProfileMachine';
import { Actor as EditPhotosActor } from '../../machines/EditPhotosMachine';
import { Actor as DeleteProfileActor } from '../../machines/DeleteProfileMachine';
import { Actor as ProfileListActor } from '../../machines/ProfileListMachine';

import { Loader } from '../Loader/Loader';
import { AlertBanner } from '../AlertBanner/AlertBanner';
import { HeaderApp } from '../Header/HeaderApp';
import { FauxneHeader } from '../Header/FauxneHeader';
import { Chat } from '../Chat/Chat';
import { Inbox } from '../Inbox/Inbox';
import { Browse } from '../Profile/Browse';
import { Profile } from '../Profile/Profile';
import { Favorites } from '../Profile/Favorites';
import { Blocked } from '../Profile/Blocked';
import { Updates } from '../Updates/Updates';
import { EditProfile } from '../Profile/EditProfile';
import { EditPhotos } from '../Profile/EditPhotos';
import { DeleteProfile } from '../Profile/DeleteProfile';
import { HeaderOverlay } from '../Header/HeaderOverlay';
import { Selfie } from '../Profile/Selfie';
import { Reminder } from '../Reminder/Reminder';
import { SignInReminder } from '../SignIn/SignInReminder';
import { SignUpReminder } from '../SignUp/SignUpReminder';
import { SignUpConfirmation } from '../SignUp/SignUpConfirmation';
import { Footer } from '../Footer/Footer';

import { resources } from '../../translations';

import Button from '../Button/Button';

import './Romance.scss';

export function Romance({ actor }: { actor: RomanceActor }) {
  const [state, send] = useActor(actor);
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'en' ? 'fr' : 'en';

  const time = state.context.time;
  const user = state.context.user;
  const profile = state.context.profile;

  switch (true) {
    case state.matches('loading'):
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <Loader theme="full-page" />
          </div>
        </div>
      );

    case state.matches('ready'):
      switch (true) {
        case state.matches('ready.panel.hidden'):
          const hideHeader =
            state.matches('ready.body.reminder') ||
            state.matches('ready.body.signUpReminder') ||
            state.matches('ready.body.signUpConfirmation');

          const functions = state.matches('ready.body.createProfile')
            ? {
                homeClick: () => {
                  // Home click goes no when profile is new
                },
              }
            : {
                homeClick: () => send({ type: 'viewBrowse' }),
                accountClick: () => send({ type: 'viewAccount' }),
                chatClick: () => send({ type: 'viewInbox' }),
                updateClick: () => send({ type: 'viewUpdates' }),
              };

          return (
            <div className="app-frame">
              <div className="app-body">
                <FauxneHeader time={time} />
                {!hideHeader && (
                  <HeaderApp
                    {...functions}
                    chatState={
                      state.matches('ready.header.messages.unread')
                        ? 'notification'
                        : 'default'
                    }
                    updateState={
                      state.matches('ready.header.updates.unread')
                        ? 'notification'
                        : 'default'
                    }
                  >
                    {user.is2Fik && (
                      <AlertBanner
                        alertType={profile.isDead ? 'warning' : 'default'}
                      >
                        {t(profile.isDead ? 'alert.dead' : 'alert.persona', {
                          name: profile.name,
                        })}
                      </AlertBanner>
                    )}
                  </HeaderApp>
                )}
                {state.matches('ready.body.browse') && (
                  <Browse actor={state.children.browseMachine as BrowseActor} />
                )}
                {state.matches('ready.body.reminder') && (
                  <Reminder actor={actor} />
                )}
                {state.matches('ready.body.signUpConfirmation') && (
                  <SignUpConfirmation actor={actor} />
                )}
                {state.matches('ready.body.signUpReminder') && (
                  <SignUpReminder actor={actor} />
                )}
                {state.matches('ready.body.signInReminder') && (
                  <SignInReminder actor={actor} />
                )}
                {state.matches('ready.body.profile') && (
                  <Profile
                    actor={state.children.profileMachine as ProfileActor}
                  />
                )}
                {(state.matches('ready.body.editProfile') ||
                  state.matches('ready.body.createProfile')) && (
                  <EditProfile
                    actor={
                      state.children.editProfileMachine as EditProfileActor
                    }
                  />
                )}
                {state.matches('ready.body.editPhotos') && (
                  <EditPhotos
                    actor={state.children.editPhotosMachine as EditPhotosActor}
                  />
                )}
                {state.matches('ready.body.deleteProfile') && (
                  <DeleteProfile
                    actor={
                      state.children.deleteProfileMachine as DeleteProfileActor
                    }
                  />
                )}
                {state.matches('ready.body.favorites') && (
                  <Favorites
                    actor={state.children.favoritesMachine as ProfileListActor}
                  />
                )}
                {state.matches('ready.body.blocked') && (
                  <Blocked
                    actor={state.children.blockedMachine as ProfileListActor}
                  />
                )}
                {state.matches('ready.body.selfie') && <Selfie actor={actor} />}
                {state.matches('ready.body.signInReminder') && (
                  <Footer
                    time={time}
                    language={lang}
                    languageClick={() => i18n.changeLanguage(lang)}
                  />
                )}
              </div>
            </div>
          );
        default:
          return (
            <div className="app-frame">
              <div className="app-body">
                <FauxneHeader time={time} />
                {state.matches('ready.panel.account') && (
                  <>
                    <HeaderOverlay onClick={() => send({ type: 'closePanel' })}>
                      {t('header.accountHeaderTitle')}
                    </HeaderOverlay>
                    <main className="account-menu">
                      <Helmet>
                        <title>
                          {t('title')} - {t('header.accountHeaderTitle')}
                        </title>
                      </Helmet>
                      <div className="account-menu__header">
                        <h1 className="account-menu__title">
                          {state.context.profile.name}
                        </h1>
                      </div>
                      {!user.is2Fik && (
                        <h3 className="label account-menu__subtitle">
                          {t('panel.account.profileLabel')}
                        </h3>
                      )}
                      <div className="account-menu__section">
                        <Button
                          buttonType="account"
                          iconName="user"
                          onClick={() => send({ type: 'editProfile' })}
                        >
                          {t(
                            !user.is2Fik
                              ? 'panel.account.profile'
                              : 'panel.account.profile2Fik',
                            {
                              name: profile.name,
                            }
                          )}
                        </Button>
                        <Button
                          buttonType="account"
                          iconName="photo"
                          onClick={() => send({ type: 'editPhotos' })}
                        >
                          {t(
                            !user.is2Fik
                              ? 'panel.account.photos'
                              : 'panel.account.photos2Fik',
                            {
                              name: profile.name,
                            }
                          )}
                        </Button>
                      </div>
                      <div className="account-menu__section">
                        <Button
                          buttonType="account"
                          iconName="favourite"
                          onClick={() => send({ type: 'viewFavorites' })}
                        >
                          {t(
                            !user.is2Fik
                              ? 'panel.account.favourites'
                              : 'panel.account.favourites2Fik',
                            {
                              name: profile.name,
                            }
                          )}
                        </Button>
                        <Button
                          buttonType="account"
                          iconName="block"
                          onClick={() => send({ type: 'viewBlocked' })}
                        >
                          {t(
                            !user.is2Fik
                              ? 'panel.account.blocked'
                              : 'panel.account.blocked2Fik',
                            {
                              name: profile.name,
                            }
                          )}
                        </Button>
                      </div>
                      <div className="account-menu__section">
                        <Button
                          buttonType="account"
                          iconName="sign-out"
                          onClick={() => send({ type: 'signOut' })}
                        >
                          {t(
                            !user.is2Fik
                              ? 'panel.account.signOut'
                              : 'panel.account.signOut2Fik',
                            {
                              name: profile.name,
                            }
                          )}
                        </Button>
                      </div>
                      {!user.is2Fik && (
                        <>
                          <h3 className="label account-menu__subtitle">
                            {t('panel.account.language')}
                          </h3>
                          <div className="account-menu__section">
                            {Object.keys(resources).map((language) => {
                              return (
                                <Button
                                  className="account-menu__button"
                                  key={language}
                                  buttonType={
                                    i18n.language === language
                                      ? 'primary'
                                      : 'secondary'
                                  }
                                  onClick={() => i18n.changeLanguage(language)}
                                >
                                  {language}
                                </Button>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </main>
                  </>
                )}
                {state.matches('ready.panel.updates') && (
                  <Updates
                    actor={state.children.updatesMachine as UpdatesActor}
                  />
                )}
                {state.matches('ready.panel.inbox.view') && (
                  <Inbox actor={state.children.inboxMachine as InboxActor} />
                )}
                {(state.matches('ready.panel.chat') ||
                  state.matches('ready.panel.inbox.chat')) && (
                  <Chat actor={state.children.chatMachine as ChatActor} />
                )}
              </div>
            </div>
          );
      }

    default:
      return null;
  }
}
