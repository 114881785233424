import { createMachine, assign, sendParent, ActorRefFrom } from 'xstate';
import { Profile, ProfileWithAge } from '../types';
import { assertEventType } from './utils';
import {
  Context as RomanceContext,
  UpdateContext,
  ViewProfile,
  ViewSelfie,
} from './RomanceMachine';

export function mapParentContextToContext(context: RomanceContext): Context {
  return {
    profile: context.profile,
    profiles: context.profiles.filter((p) => !p.isBlocked),
  };
}

export interface Context {
  profile: ProfileWithAge;
  profiles: Profile[];
}

type Events = ViewProfile | ViewSelfie | UpdateContext;

export const machine = createMachine<Context, Events>(
  {
    id: 'browseMachine',
    initial: 'ready',
    states: {
      ready: {
        on: {
          updateContext: {
            actions: 'assignContext',
          },
          viewProfile: {
            actions: 'viewProfile',
          },
          viewSelfie: {
            actions: 'viewProfile',
          },
        },
      },
    },
  },
  {
    actions: {
      viewProfile: sendParent((context, event) => event),
      viewSelfie: sendParent((context, event) => event),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(event.context);
      }),
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;
