import React from 'react';
import classnames from 'classnames';
import './Loader.scss';
import { ReactComponent as LoaderHeart } from '../../images/loader/heart.svg';

interface LoaderProps {
  size?: 'xs' | 'sm' | 'lg';
  theme?: 'default' | 'white' | 'inline' | 'full-page';
  className?: string;
  isAnimated?: boolean;
}

export function Loader(props: LoaderProps) {
  const {
    size = 'lg',
    theme = 'default',
    className,
    isAnimated = true,
  } = props;

  return (
    <div
      className={classnames('loader', className, {
        'loader--sm': size === 'sm',
        'loader--xs': size === 'xs',
        'loader--white': theme === 'white',
        'loader--inline': theme === 'inline',
        'loader--full-page': theme === 'full-page',
        'loader--no-animation': !isAnimated,
      })}
    >
      <LoaderHeart className="loader__icon" />
    </div>
  );
}
