import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { App } from './components/App/App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import './firebase';

import { store } from './stores/firestore';
import { interpret } from 'xstate';
import { machine, createServices } from './machines/AppMachine';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://6397c9d18a7649f5b73242bef98d715e@o10643.ingest.sentry.io/5727657',
  });
}

function createService() {
  return interpret(
    machine.withConfig({ services: createServices(store) })
  ).start();
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App service={createService()} />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
