import React from 'react';
import classnames from 'classnames';
import './StatusTag.scss';
import { Icon } from '../Icon/Icon';
import { ProfileStatus } from '../../types';

interface StatusProps {
  children?: string;
  statusState?: ProfileStatus;
  className?: string;
}

export function StatusTag(props: StatusProps) {
  const { children, statusState = 'online', className } = props;

  return (
    <div
      className={classnames('status-tag', className, {
        'status-tag--online': statusState === 'online',
        'status-tag--inactive': statusState === 'inactive',
        'status-tag--offline': statusState === 'offline',
        'status-tag--user-online': statusState === 'user',
      })}
    >
      <Icon size="xs" name="circle" className="status-tag__icon" />
      <span className="status-tag__label">{children}</span>
    </div>
  );
}
