import { Translation } from './type';

export const en: Translation = {
  title: 'Romance à la 2Fik',
  date: {
    fromNow: '{{date, fromNow}}',
    lastLogin: '{{date, lastLogin}}',
    time: '{{date, time}}',
    chat: '{{date, chat}}',
    read: 'Read at {{date, time}}',
  },
  gender: {
    man: 'Man',
    woman: 'Woman',
    agender: 'Agender',
    androgynous: 'Androgynous',
    bigender: 'Bigender',
    cisgenderMan: 'Cisgender man',
    cisgenderWoman: 'Cisgender woman',
    demiman: 'Demiman',
    demiwoman: 'Demiwoman',
    genderFluid: 'Gender Fluid',
    genderNeutral: 'Gender Neutral',
    genderNonConforming: 'Gender Non-Conforming',
    genderQuestioning: 'Gender Questioning',
    genderqueer: 'Genderqueer',
    hijra: 'Hijra',
    intersex: 'Intersex',
    intersexMan: 'Intersex Man',
    intersexWoman: 'Intersex Woman',
    intersexPerson: 'Intersex Person',
    neutrois: 'Neutrois',
    nonBinary: 'Non-Binary',
    other: 'Other',
    pangender: 'Pangender',
    polyender: 'Polyender',
    thirdGender: 'Third Gender',
    trans: 'Trans',
    transMan: 'Trans Man',
    transPerson: 'Trans Person',
    transWoman: 'Trans Woman',
    transfeminine: 'Transfeminine',
    transgender: 'Transgender',
    transmasculine: 'Transmasculine',
    transsexual: 'Transsexual',
    transsexualMan: 'Transsexual Man',
    transexualPerson: 'Transexual Person',
    transsexualWoman: 'Transsexual Woman',
    twoSpirit: 'Two-Spirit',
    none: 'None',
  },
  sexuality: {
    allosexual: 'Allosexual',
    androsexual: 'Androsexual',
    asexual: 'Asexual',
    aromantic: 'Aromantic',
    bicurious: 'Bicurious',
    bisexual: 'Bisexual',
    demisexual: 'Demisexual',
    fluid: 'Fluid',
    gay: 'Gay',
    gynesexual: 'Gynesexual',
    heterosexual: 'Heterosexual',
    homosexual: 'Homosexual',
    lesbian: 'Lesbian',
    pansexual: 'Pansexual',
    sapiosexual: 'Sapiosexual',
    straight: 'Straight',
    none: 'None',
  },
  marketing: {
    description: 'Flirt with hot singles in your area.',
    button: 'Sign up now',
    label: 'Already have an account?',
    link: 'Sign In',
  },
  prelude: {
    title: 'Hello 2Fik',
    description: 'Select a persona to interact with your users.',
  },
  alert: {
    persona: 'Interacting as Persona: {{name}}',
    dead: 'Persona {{name}} is dead. Don’t update!',
  },
  optOut: {
    title: 'Opt Out Of Emails?',
    description:
      'If you didn’t create an account and wish to opt out and never receive another email from us, please confirm below.',
    confirmationButtonTitle: 'Yes, Opt Out',
    nevermindButtonTitle: 'Nevermind',
    homeButtonTitle: 'Back To Home',
    confirm: {
      title: 'You’ve Opted Out Of This Account',
      description: {
        paragraph1:
          'If you wish to create an account in the future, you’ll have to use another email.',
        paragraph2: 'If not, you’ll never hear from us again.',
        paragraph3: 'Bye!',
      },
    },
    error: {
      title: 'Opt Out Failed',
      description: {
        paragraph1:
          'We were unable to complete your opt out. Please try again by clicking the link in the email.',
      },
    },
  },
  signIn: {
    title: 'Sign In',
    body: 'Get a magic link sent to your email that’ll sign you in instantly!',
    form: {
      emailLabel: 'Your Email',
      buttonTitle: 'Send magic link',
      feedback: 'Email is invalid!',
    },
    success: {
      title: 'Email Sent',
      descriptionInstructionStrong: 'It’s on its way!',
      descriptionInstruction: 'You’ll get an email with a magic link soon.',
      descriptionCheck:
        'If you can’t see it in your inbox, check your Spam folder.',
      buttonTitle: 'Go Back',
    },
    notSent: {
      title: 'Email Not Sent...',
      description: 'The email did not send. Please try again.',
      buttonTitle: 'Go Back',
    },
    failure: {
      title: 'Sign in Failure',
      description: 'We were unable to sign you in. Please try again!',
      buttonTitle: 'Go Back',
    },
    signedIn: {
      title: 'You’re signed In',
      description:
        'Thanks to the magic link you’re now signed in and ready to flirt',
      buttonTitle: 'Lets Start Flirting',
    },
  },
  signUp: {
    title: 'Sign Up',
    subtitle: 'No password? No problem!',
    body:
      'Just confirm your email account and we’ll send you a magic link to set up your account!',
    form: {
      nameLabel: 'Your Pseudonym',
      emailLabel: 'Your Email',
      buttonTitle: 'Send magic link',
      feedback: 'Email is invalid!',
    },
    confirmEmail: {
      title: 'Confirm Your Email',
      description: 'A sign up confirmation has been sent to this email:',
      descriptionCheck:
        'If you want to create an account, confirm the sign up below.',
      form: {
        inputLabel: 'Your Email',
        buttonTitle: 'Confirm Sign Up',
      },
    },
    confirmation: {
      title: 'Confirmation Complete!',
      description: 'You’ve successfully created your account and signed in.',
      descriptionStrong: 'Let’s get started!',
      buttonTitle: 'Create Your Profile',
      optOutStrong: 'Not you?',
      optOutDescription: 'Want to stop getting these emails?',
      optOut: 'OPT OUT',
    },
    emailSent: {
      title: 'Email Sent!',
      descriptionStrong: 'It’s on its way!',
      descriptionInstruction: 'You’ll get an email with a magic link soon.',
      descriptionCheck: 'If you can’t see it in your inbox, check your Spam.',
      buttonTitle: 'Go Back',
    },
    failure: {
      title: 'Email Not Sent...',
      description: 'The email did not send. Please try again.',
      buttonTitle: 'Go Back',
    },
  },
  signOut: {
    title: 'You’re Signed Out',
    description: 'You have successfully signed out of your account.',
    buttonTitle: 'Sign Back In',
    failure: {
      title: 'Sign Out Failed',
      description: 'We were unable to sign you out. Please try again!',
    },
  },
  editProfile: {
    titleNew: 'Create your profile',
    title: 'Edit your profile',
    body: 'Please create a profile before browsing the site.',
    form: {
      basicInfoTitle: 'Basic information',
      required: 'required',
      nameLabel: 'Your Pseudonym',
      birthdateLabel: 'Your date of birth',
      birthdatePlaceholder: 'YYYY-MM-DD',
      genderLabel: 'Gender identity',
      sexualLabel: 'Sexual preference',
      selectLabel: 'Select an option',
      photoTitle: 'Your photo',
      photoIntro: 'A picture will help draw attention to your profile.',
      tellUsTitle: 'Tell us about yourself',
      headline: 'Description',
      who: 'Who are you?',
      what: 'What are you looking for?',
      why: 'Why are you here?',
      how: 'How do you like to flirt?',
      when: 'When can you chat?',
      else: 'Anything else?',
      buttonTitle: 'Save',
      requiredFields:
        'You must provide a name and a date of birth that is over 18 years old.',
      saveErrorTitle: 'Profile Did Not Save',
      saveError: 'Your profile information did not save. Please try again.',
    },
  },
  deleteProfile: {
    button: 'Delete profile?',
    confirm: {
      title: 'Delete Your Profile',
      intro: 'Are you absolutely sure you want to delete your profile?',
      buttonConfirm: 'Yes, delete it',
      buttonDeny: 'No, Keep it',
      error: 'There was an error in your deletion request. Please try again.',
    },
    confirmed: {
      title: 'Confirm Profile Deletion',
      intro:
        'We’ve sent you an email to confirm the deletion of your profile. Please click the link in the email to complete the deletion process.',
      buttonBack: 'Back to Home',
    },
    success: {
      title: 'Profile Deleted',
      intro:
        'You’ve successfully deleted your profile. Goodbye! We’ll miss you.',
      errorTitle: 'Profile Deletion Failure',
      error:
        'There was an error confirming your profile deletion. Please try the link again.',
    },
  },
  editLists: {
    favorites: {
      heading: 'Your Favourite List',
      intro: 'Your favourite users.',
      error: 'There was an error removing that favourite.',
    },
    blocked: {
      heading: 'Your Block List',
      intro: 'These are users you’ve blocked on the app.',
      error: 'There was an error removing that blocked profile.',
    },
    removeButton: 'Remove',
  },
  editPhotos: {
    edit: {
      heading: 'Edit Your Photos',
      intro: 'Add or delete the photos that appear on your profile.',
      addButton: 'Add a Photo',
      removeButton: 'Remove Photo',
      removeError: 'There was an error removing that photo. Please try again.',
    },
    upload: {
      heading: 'Upload a Photo',
      intro: 'Select a photo to upload and then crop it to the required size.',
      selectButton: 'Select a Photo',
      feedback: {
        failed: 'Failed to upload photo. Please try again',
        size:
          'Selected photo is to too large. Please select a photo that is smaller than 10 MB.',
      },
    },
    crop: {
      heading: 'Crop Your Photo',
      saveButton: 'Save Photo',
      cancelButton: 'Cancel',
      feedback: {
        uploading: 'Uploading...',
        resizing: 'Resizing...',
        cropping: 'Cropping...',
        cancelling: 'Cancelling...',
        failed: 'Cropping photo failed. Please try again.',
      },
    },
  },
  profile: {
    status: {
      online: 'online',
      inactive: 'recently online',
      offline: 'offline',
    },
    imageCountOf: 'of',
    rating: {
      hot: 'Hot',
      hotMessage: 'is hot!',
      not: 'Not',
      notMessage: 'is not hot...',
      saveRating: 'Saving your rating...',
      errorRating: 'Unable to save your rating.',
      deleteRating: 'Deleting your rating...',
    },
    meta: {
      currentUser: 'This is you!',
      ageSuffix: 'years old',
    },
    actions: {
      chat: 'Chat',
      fave: 'Fave',
      unfave: 'Unfave',
      saveFave: 'Saving fave...',
      faveError: 'Unable to save fave',
      block: 'Bye',
      unblock: 'Unblock',
      saveBlock: 'Saving block...',
      blockError: 'Unable to save block',
    },
    description: {
      whoLabel: 'Who?',
      whatLabel: 'What?',
      whyLabel: 'Why?',
      howLabel: 'How?',
      whenLabel: 'When?',
      elseLabel: 'P.S.',
    },
    loginDate: 'Last logged in',
    loginUnavailable: 'Never',
    ban: {
      warning: 'Bans are permanent and cannot be undone.',
      button: 'Ban User',
      saving: 'Saving Ban',
      complete: 'Ban Complete',
      error: 'Unable to ban user. Please try again.',
    },
    selfie: {
      editPhotos: 'Edit Photos',
      editProfile: 'Edit Profile',
    },
  },
  chat: {
    chatInput: 'Type a message',
    chatError: 'Message failed to send.',
    emptyChatTitle: 'No Messages',
    emptyChatDescription: 'Start flirting with people to see some updates.',
    emptyChatButton: 'Start flirting',
    blockedChatTitle: 'You’ve been',
    blockedChatStrong: 'Blocked',
    disabledChatTitle: 'Chat has been',
    disabledChatStrong: 'Disabled',
  },
  updates: {
    emptyUpdatesTitle: 'No Updates',
    emptyUpdatesDescription: 'Start flirting with people to see some updates.',
    emptyUpdatesButton: 'Start flirting',
    online: 'is now ONLINE',
    hot: 'rated you TOO HOT',
    not: 'rated you NOT HOT',
  },
  header: {
    signIn: 'Sign In',
    signUp: 'Sign Up',
    signOut: 'Sign Out',
    account: 'Account',
    chat: 'Chat',
    updates: 'Update',
    chatHeaderTitle: 'Your chat inbox',
    updatesHeaderTitle: 'Your updates',
    accountHeaderTitle: 'Your account',
  },
  footer: {
    what: 'What the hell is this?',
    link: 'https://2fikornot2fik.com/romanceala2fik/',
  },
  exhibition: {
    top5: 'Top 5',
  },
  panel: {
    account: {
      profileLabel: 'Your Profile',
      profile: 'Edit your profile',
      profile2Fik: 'Edit {{name, possessive}} profile',
      photos: 'Edit your photos',
      photos2Fik: 'Edit {{name, possessive}} photos',
      favourites: 'Your fave list',
      favourites2Fik: '{{name, possessive}} fave list',
      blocked: 'Your block list',
      blocked2Fik: '{{name, possessive}} block list',
      signOut: 'Sign out',
      signOut2Fik: 'Return to personas',
      language: 'Choose your language',
    },
  },
  reminder: {
    titleSignIn: 'Just A Reminder',
    bodySignIn1: 'This is not a real dating app!',
    bodySignIn2:
      'This is part of an 18+ performance art piece from the artist 2Fik.',
    bodySignIn3: 'Get it? Got it? Good!',
    bodySignInStrong: 'Learn more about the project',
    bodyLink: 'https://2fikornot2fik.com/romanceala2fik/',
    titleSignUp: 'All Done',
    bodySignUp:
      'Just a reminder that you are participating in a performance art piece.',
    buttonTitle: 'Let’s Start Flirting!',
  },
  tos: {
    title: 'Welcome to Romanceala2fik',
    topBanner:
      'These terms of service outline the rules and regulations for the use of Romanceala2fik’s Website, part of the artwork “Romance ain’t dead, 2Fik!”.',
    paragraph1:
      'By accessing this website we assume you accept these terms of service in full. Do not continue to use Romanceala2fik’s website if you do not accept all of the terms of service stated on this page.',
    paragraph2:
      'The following terminology applies to these Terms of Service, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms of service. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves.  All terms refer to the participation of the Client in the art work, in accordance with and subject to, prevailing law of Quebec. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.',
    cookies: {
      title: 'Cookies',
      paragraph1:
        'We employ the use of cookies. By using Romanceala2fik’s website you consent to the use of cookies in accordance with Romanceala2fik’s privacy policy.',
      paragraph2:
        'Most of the modern day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / 3rd party service providers may also use cookies.',
    },
    license: {
      title: 'License',
      paragraph1:
        'Unless otherwise stated, Romanceala2fik and/or its licensors own the intellectual property rights for all material on Romanceala2fik. All intellectual property rights are reserved. You may view and/or print pages from romanceala2fik.com for your own personal use subject to restrictions set in these terms of service.',
      paragraph2: 'You must not:',
      listItem1: 'Republish material from romanceala2fik.com',
      listItem2: 'Sell, rent or sub-license material from romanceala2fik.com',
      listItem3:
        'Reproduce, duplicate or copy material from romanceala2fik.com',
      listItem4:
        'Redistribute content from Romanceala2fik (unless content is specifically made for redistribution).',
    },
    user: {
      title: 'User Comments',
      userListItem1: 'This Agreement shall begin on the date hereof.',
      userListItem2:
        'Certain parts of this website offer the opportunity for users to post and exchange opinions, information, material and data (“Comments” and “Chats”) in areas of the website. Romanceala2fik does not screen, edit, publish or review Comments and Chats prior to their appearance on the website and Comments and Chats do not reflect the views or opinions of Romanceala2fik, its agents or affiliates. Comments reflect the view and opinion of the person who posts such a view or opinion. To the extent permitted by applicable laws Romanceala2fik shall not be responsible or liable for the Comments and Chatsor for any loss cost, liability, damages or expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments and Chats on this website.',
      userListItem3:
        'Romanceala2fik reserves the right to monitor all Comments and to remove any Comments which it considers in its absolute discretion to be inappropriate, offensive or otherwise in breach of these Terms of Service.',
      userListItem4: 'You warrant and represent that:',
      nestedListItem1:
        'You are entitled to post on our website and have provided all of the required information to do so;',
      nestedListItem2:
        'The Comments and Chats do not infringe any intellectual property right, including without limitation copyright, patent or trademark, or other proprietary right of any third party;',
      nestedListItem3:
        'The Comments and Chats do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material or material which is an invasion of privacy',
      nestedListItem4:
        'The Comments and Chats will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.',
      userListItem5:
        'You hereby grant to Romanceala2fik a non-exclusive royalty-free license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments and Chats in any and all forms, formats or media, including use during public representations of “Romance ain’t dead, 2Fik!”.',
    },

    hyperlink: {
      title: 'Hyperlinking to our Content',
      listItem1: {
        body:
          'The following organizations may link to our Web site without prior written approval:',
        nestedListItem1: 'Government agencies;',
        nestedListItem2: 'Our financila partners;',
        nestedListItem3: 'Presenters of 2Fik’s performances;',
        nestedListItem4: 'Search engines;',
        nestedListItem5: 'News organizations; and',
        nestedListItem6:
          'Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses',
      },
      listItem2:
        'These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.',
      listItem3: {
        body:
          'We may consider and approve in our sole discretion other link requests from the following types of organizations:',
        nestedListItem1: 'dot.com community sites;',
        nestedListItem2:
          'associations or other groups representing charities, including charity giving sites,',
        nestedListItem3: 'online directory distributors; and',
        nestedListItem4: 'internet portals;',
      },
      paragraph1:
        'We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of Romanceala2fik; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.',
      paragraph2:
        'If you are among the organizations listed in paragraph 3 above and are interested in linking to our website, you must notify us by sending an e-mail to info.2fik@gmail.com. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.',
      paragraph3: {
        body:
          'Approved organizations may hyperlink to our Web site as follows:',
        listItem1: 'By use of our corporate name; or',
        listItem2:
          'By use of the uniform resource locator (Web address) being linked to; or',
        listItem3:
          'By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party’s site.',
      },
      paragraph4:
        'No use of Romanceala2fik’s logo or other artwork will be allowed for linking absent a trademark license agreement.',
    },
    iframe: {
      title: 'Iframes',
      body:
        'Without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site.',
    },
    liability: {
      title: 'Content Liability',
      body:
        'We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.',
    },
    reservation: {
      title: 'Reservation of Rights',
      body:
        'We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms of service and its linking policy at any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms of service.',
    },
    removal: {
      title: 'Removal of links from our website',
      paragraph1:
        'If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.',
      paragraph2:
        'Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.',
    },
    disclaimer: {
      title: 'Disclaimer',
      paragraph1:
        'To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:',
      paragraph2:
        'The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, ',
      paragraph3:
        'To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.',
      listItem1:
        'limit or exclude our or your liability for death or personal injury resulting from negligence;',
      listItem2:
        'limit or exclude our or your liability for fraud or fraudulent misrepresentation;',
      listItem3:
        'limit any of our or your liabilities in any way that is not permitted under applicable law; or',
      listItem4:
        'exclude any of our or your liabilities that may not be excluded under applicable law.',
    },
    participation: {
      title: 'Participation in the artwork',
      body:
        'I acknowledge by agreeing to these Terms of Service, I am agreeing to participate in the dating app “Romance à la 2Fik”, part of the artwork “Romance ain’t dead, 2Fik!”. This participation may entail the use and/or projection of any information (words, photos or images) I have submitted to the website as part of my profile or as part of an interaction I have engaged in on the platform. This information can be used as part of the public performance(s) related to the artwork.',
    },
    credit: {
      title: 'Credit & Contact Information',
      body:
        'This Terms of service page was created at privacyterms.io terms & conditions generator. If you have any queries regarding any of our terms, please contact us.',
    },
    bottomBanner:
      'This performance is only suitable for thos 18+ in age. Please confirm your age below.',
    checkBox1: 'I confirm I am 18+ years',
    checkBox2:
      'I agree to these Terms, including the use of my images for performance purposes.',
    buttonTitle: 'Agree To Terms Of Service',
  },
};
