import { assign, createMachine, send, Sender, Interpreter } from 'xstate';
import isEmail from 'validator/lib/isEmail';
import { ProfileDocument, Store, User } from '../types';
import {
  EMAIL_KEY,
  assertEventType,
  getProfileWithAge,
  withDelay,
} from './utils';

import { watchHistory, pushHistory, hasHistory, getHistory } from './history';

import {
  Context as PreludeContext,
  machine as preludeMachine,
  createActions as createPreludeActions,
  createServices as createPreludeServices,
} from './PreludeMachine';

import {
  Context as RomanceContext,
  machine as romanceMachine,
  createActions as createRomanceActions,
  createServices as createRomanceServices,
  createActivities as createRomanceActivities,
} from './RomanceMachine';

import {
  machine as magicLinkMachine,
  createSignInServices,
  createSignUpServices,
  createSignInGuards,
  createSignUpGuards,
} from './MagicLinkMachine';

import {
  machine as optOutMachine,
  createServices as createOptOutServices,
} from './OptOutMachine';

import {
  machine as exhibitionMachine,
  createServices as createExhibitionServices,
  Context as ExhibitionContext,
} from './ExhibitionMachine';

const MACHINE_ID = 'appMachine';

export interface Context {
  time: Date;
  email: string;
  link: string;
  user?: User;
  isSignIn: boolean;
  optOutToken: string;
}

export interface LoadTime {
  type: 'loadTime';
  time: Date;
}

interface SignIn {
  type: 'signIn';
}

interface SetEmail {
  type: 'setEmail';
  email: string;
}

interface SignedIn {
  type: 'signedIn';
  user: User;
}

interface ViewStart {
  type: 'viewStart';
}

interface ViewConfirmEmail {
  type: 'viewConfirmEmail';
}

interface ViewSignIn {
  type: 'viewSignIn';
}

interface ViewShowcase {
  type: 'viewShowcase';
}

interface ViewSignUp {
  type: 'viewSignUp';
}

interface DonePrelude {
  type: 'done.invoke.preludeMachine';
  data?: ProfileDocument;
}

interface Delete {
  type: 'delete';
  token: string;
}
interface OptOut {
  type: 'optOut';
  token: string;
}

interface OptOutLater {
  type: 'optOutLater';
  token: string;
}

interface ViewHotExhibit {
  type: 'viewHotExhibit';
}

interface ViewChatExhibit {
  type: 'viewChatExhibit';
}

type Event =
  | DonePrelude
  | SignIn
  | SetEmail
  | SignedIn
  | ViewStart
  | ViewConfirmEmail
  | ViewSignIn
  | ViewShowcase
  | ViewSignUp
  | ViewHotExhibit
  | ViewChatExhibit
  | Delete
  | LoadTime
  | OptOut
  | OptOutLater;

const machine = createMachine<Context, Event>(
  {
    id: MACHINE_ID,
    initial: 'init',
    context: {
      time: new Date(),
      link: window.location.href,
      email: window.localStorage.getItem(EMAIL_KEY) || '',
      isSignIn: false,
      optOutToken: '',
    },
    invoke: [
      {
        id: 'watchHistory',
        src: 'watchHistory',
      },
      {
        id: 'watchTime',
        src: 'watchTime',
      },
    ],
    states: {
      init: {
        invoke: {
          id: 'checkAuthState',
          src: 'checkAuthState',
        },
        on: {
          signedIn: { target: 'prelude', actions: 'assignUser' },
          viewStart: 'start',
          viewShowcase: 'showcase',
          viewConfirmEmail: [
            { target: 'signingIn', cond: 'isValidEmail' },
            { target: 'confirmEmail' },
          ],
          delete: 'delete',
          optOut: 'optOut',
          optOutLater: {
            actions: 'assignOptOutToken',
          },
          viewHotExhibit: 'exhibition',
          viewChatExhibit: 'exhibition',
        },
      },
      start: {
        entry: 'pushStart',
        on: {
          viewStart: undefined,
        },
      },
      confirmEmail: {
        initial: 'idle',
        states: {
          idle: {},
          invalid: {},
        },
        on: {
          setEmail: { actions: 'assignEmail' },
          signIn: [
            { target: 'signingIn', cond: 'isValidEmail' },
            { target: '.invalid' },
          ],
        },
      },
      signingIn: {
        invoke: {
          id: 'signIn',
          src: 'signIn',
          onDone: {
            target: 'init',
            actions: [
              'assignIsSignIn',
              'cleanUpUrl',
              'removeEmailFromLocalStorage',
            ],
          },
          onError: 'signInFailure',
        },
      },
      prelude: {
        invoke: {
          id: 'preludeMachine',
          src: 'preludeMachine',
          data: mapContextToPreludeMachine,
          onDone: [
            { cond: 'hasProfile', target: 'romance' },
            { target: 'signingOut' },
          ],
        },
        on: {
          loadTime: { actions: ['assignToTime', 'sendToPreludeMachine'] },
        },
      },
      romance: {
        invoke: {
          id: 'romanceMachine',
          src: 'romanceMachine',
          data: mapContextToRomanceMachine,
          onDone: [
            { target: 'prelude', cond: 'is2Fik' },
            { target: 'signingOut' },
          ],
        },
        on: {
          loadTime: { actions: ['assignToTime', 'sendToRomanceMachine'] },
        },
      },
      signInFailure: {},
      signingOut: {
        invoke: {
          id: 'signOut',
          src: 'signOut',
          onDone: 'signOutSuccess',
          onError: 'signOutFailure',
        },
      },
      signOutSuccess: {},
      signOutFailure: {},
      signUp: {
        entry: 'pushSignUp',
        invoke: {
          id: 'signUpMachine',
          src: 'signUpMachine',
          onDone: 'start',
        },
      },
      signIn: {
        entry: 'pushSignIn',
        invoke: {
          id: 'signInMachine',
          src: 'signInMachine',
          onDone: 'start',
        },
      },
      delete: {
        initial: 'deleting',
        states: {
          deleting: {
            invoke: {
              id: 'delete',
              src: 'delete',
              onDone: { target: 'done', actions: 'cleanUpUrl' },
              onError: 'failure',
            },
          },
          done: {},
          failure: {},
        },
      },
      optOut: {
        invoke: {
          id: 'optOutMachine',
          src: 'optOutMachine',
          data: (context, event) => {
            assertEventType(event, 'optOut');
            return { token: event.token };
          },
          onDone: 'start',
        },
      },
      exhibition: {
        invoke: {
          id: 'exhibitionMachine',
          src: 'exhibitionMachine',
          data: mapContextToExhibitionMachine,
        },
      },
      showcase: {
        type: 'final',
      },
    },
    on: {
      viewStart: '.start',
      viewSignIn: '.signIn',
      viewSignUp: '.signUp',
      loadTime: { actions: 'assignToTime' },
    },
  },
  {
    actions: {
      assignEmail: assign({
        email: (context, event) => {
          assertEventType(event, 'setEmail');
          return event.email;
        },
      }),
      assignIsSignIn: assign({
        isSignIn: (context, event) => {
          return true;
        },
      }),
      assignUser: assign({
        user: (context, event) => {
          assertEventType(event, 'signedIn');
          return event.user;
        },
      }),
      assignOptOutToken: assign({
        optOutToken: (context, event) => {
          assertEventType(event, 'optOutLater');
          return event.token;
        },
      }),
      assignToTime: assign({
        time: (context, event) => {
          assertEventType(event, 'loadTime');
          return event.time;
        },
      }),
      sendToPreludeMachine: send((context: Context, event: Event) => event, {
        to: 'preludeMachine',
      }),
      sendToRomanceMachine: send((context: Context, event: Event) => event, {
        to: 'romanceMachine',
      }),
      cleanUpUrl: () => window.history.replaceState(null, '', '/'),
      removeEmailFromLocalStorage: () =>
        window.localStorage.removeItem(EMAIL_KEY),
      pushStart: (context, event) => {
        pushHistory(
          MACHINE_ID,
          event.type === 'viewStart' ? event : { type: 'viewStart' }
        );
      },
      pushSignIn: (context, event) => {
        assertEventType(event, 'viewSignIn');
        pushHistory(MACHINE_ID, event);
      },
      pushSignUp: (context, event) => {
        assertEventType(event, 'viewSignUp');
        pushHistory(MACHINE_ID, event);
      },
    },
    guards: {
      isValidEmail: (context) => isEmail(context.email),
      is2Fik: (context) => context.user !== undefined && context.user.is2Fik,
      hasProfile: (context, event) => {
        assertEventType(event, 'done.invoke.preludeMachine');
        return event.data !== undefined;
      },
    },
    services: {
      watchTime: (context: Context) => {
        return (callback: Sender<LoadTime>) => {
          const timer = setInterval(
            () => callback({ type: 'loadTime', time: new Date() }),
            2000
          );

          return () => {
            clearInterval(timer);
          };
        };
      },
    },
  }
);

export { machine };

function mapContextToRomanceMachine(
  context: Context,
  event: Event
): RomanceContext {
  assertEventType(event, 'done.invoke.preludeMachine');

  const { time, user, isSignIn } = context;

  if (user === undefined) {
    throw new Error('Unable to create app machine with undefined user.');
  }

  if (event.data === undefined) {
    throw new Error('Unable to create app machine with undefined profile.');
  }

  return {
    user,
    time,
    config: { id: 'romance', chat: true },
    profile: getProfileWithAge(time, event.data),
    ranks: [],
    presences: [],
    messages: [],
    profiles: [],
    lastReads: [],
    isSignIn,
  };
}

function mapContextToPreludeMachine(
  context: Context,
  event: Event
): PreludeContext {
  if (context.user === undefined) {
    throw new Error('Unable to create app machine with undefined user.');
  }

  const { time, user } = context;

  return {
    user,
    time,
    profiles: [],
  };
}

function isShowcase(pathname: string) {
  return pathname === '/showcase';
}

function isHotExhibit(pathname: string) {
  return pathname === '/exhibit/hot';
}

function isChatExhibit(pathname: string) {
  return pathname === '/exhibit/chat';
}

function mapContextToExhibitionMachine(
  context: Context,
  event: Event
): ExhibitionContext {
  return {
    exhibit: event.type === 'viewHotExhibit' ? 'hot' : 'chat',
    hots: [],
    nots: [],
    messages: [],
    moderators: [],
  };
}

export function createServices(store: Store) {
  return {
    romanceMachine: romanceMachine.withConfig({
      actions: createRomanceActions(store),
      activities: createRomanceActivities(store),
      services: createRomanceServices(store),
    }),
    signUpMachine: magicLinkMachine.withConfig({
      guards: createSignUpGuards(),
      services: createSignUpServices(store),
    }),
    signInMachine: magicLinkMachine.withConfig({
      guards: createSignInGuards(),
      services: createSignInServices(store),
    }),
    preludeMachine: preludeMachine.withConfig({
      actions: createPreludeActions(store),
      services: createPreludeServices(store),
    }),
    optOutMachine: optOutMachine.withConfig({
      services: createOptOutServices(store),
    }),
    exhibitionMachine: exhibitionMachine.withConfig({
      services: createExhibitionServices(store),
    }),
    watchHistory: (context: Context) => {
      return (callback: Sender<Event>) => watchHistory(MACHINE_ID, callback);
    },
    checkAuthState: (context: Context) => {
      return (callback: Sender<Event>) => {
        return store.onAuthStateChanged((user) => {
          const token = new URL(context.link).searchParams.get('deleteToken');
          const optOutToken = new URL(context.link).searchParams.get(
            'optOutToken'
          );
          const optOutLaterToken = new URL(context.link).searchParams.get(
            'optOutLaterToken'
          );

          if (optOutLaterToken !== null) {
            callback({
              type: 'optOutLater',
              token: optOutLaterToken,
            });
          }

          if (
            isShowcase(window.location.pathname) &&
            process.env.NODE_ENV === 'development'
          ) {
            callback({ type: 'viewShowcase' });
          } else if (token !== null) {
            callback({
              type: 'delete',
              token,
            });
          } else if (optOutToken !== null) {
            callback({
              type: 'optOut',
              token: optOutToken,
            });
          } else if (user) {
            if (isHotExhibit(window.location.pathname) && user.is2Fik) {
              callback({ type: 'viewHotExhibit' });
            } else if (isChatExhibit(window.location.pathname) && user.is2Fik) {
              callback({ type: 'viewChatExhibit' });
            } else {
              callback({
                type: 'signedIn',
                user,
              });
            }
          } else if (store.isSignInWithEmailLink(context.link)) {
            callback({ type: 'viewConfirmEmail' });
          } else if (hasHistory(MACHINE_ID)) {
            for (const event of getHistory(MACHINE_ID).events) {
              callback(event);
            }
          } else {
            callback({ type: 'viewStart' });
          }
        });
      };
    },
    signIn: (context: Context) => {
      return withDelay(
        store.signInWithEmailLink(context.email, context.link),
        store.delay
      );
    },
    signOut: (context: Context) => {
      return withDelay(store.signOut(), store.delay);
    },
    delete: (context: Context, event: Event) => {
      assertEventType(event, 'delete');
      return withDelay(store.delete(event.token), store.delay);
    },
  };
}

export type Service = Interpreter<Context, any, Event, any>;
