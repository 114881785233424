import React from 'react';
import classNames from 'classnames';
import './ChatConvo.scss';

interface ChatProps {
  className?: string;
  children?: React.ReactNode;
}

export function ChatConvo(props: ChatProps) {
  const { className, children } = props;

  return <div className={classNames(className, 'chat-convo')}>{children}</div>;
}
