import React from 'react';
import classnames from 'classnames';
import { Icon, IconName, IconSize, IconTheme } from '../Icon/Icon';
import './IconButton.scss';

export type IconButtonTheme = IconTheme;
export type IconButtonClick = (e: React.SyntheticEvent) => void;

interface ButtonProps {
  children?: React.ReactNode;
  buttonType?: 'nav' | 'profile' | 'labelless';
  buttonName?: string;
  iconName?: IconName;
  iconSize?: IconSize;
  iconTheme?: IconButtonTheme;
  className?: string;
  disabled?: boolean;
  onClick?: IconButtonClick;
  type?: 'button' | 'submit' | 'reset';
}

export function IconButton(props: ButtonProps) {
  const {
    children,
    buttonType = 'labelless',
    buttonName,
    iconName = 'close',
    iconSize = 'md',
    iconTheme = 'glow',
    className,
    disabled = false,
    onClick,
    type = 'button',
  } = props;

  return (
    <button
      name={buttonName}
      type={type}
      onClick={onClick}
      disabled={disabled}
      tabIndex={0}
      className={classnames('icon-button', className, {
        'icon-button--nav': buttonType === 'nav',
        'icon-button--profile': buttonType === 'profile',
        'icon-button--labelless': buttonType === 'labelless',
      })}
    >
      <Icon
        size={
          buttonType === 'nav'
            ? 'lg'
            : buttonType === 'profile'
            ? 'xl'
            : iconSize
        }
        name={iconName}
        theme={iconTheme}
        className="icon-button__icon"
      />
      <span className="icon-button__label">{children}</span>
    </button>
  );
}
