import React from 'react';
import './AlertBanner.scss';
import classNames from 'classnames';

interface AlertProps {
  className?: string;
  children: string;
  alertType?: 'default' | 'warning';
}

export function AlertBanner(props: AlertProps) {
  const { className, children, alertType = 'default' } = props;

  return (
    <div
      className={classNames(className, 'alert-banner', {
        'alert-banner--warning': alertType === 'warning',
      })}
    >
      {children}
    </div>
  );
}
