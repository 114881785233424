import React, { FunctionComponent, SVGProps } from 'react';
import classNames from 'classnames';
import './Logo.scss';

import { ReactComponent as LogoMark } from '../../images/brand/logo.svg';
import { ReactComponent as LogoFull } from '../../images/brand/logo-full.svg';

export type LogoType = 'mark' | 'typeform';

interface LogoData {
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
}

type LogoDataMap = { [key in LogoType]: LogoData };

const logoData: LogoDataMap = {
  mark: {
    component: LogoMark,
  },
  typeform: {
    component: LogoFull,
  },
};

interface LogoProps {
  className?: string;
  logoType?: LogoType;
  logoTheme?: 'default' | 'glow';
  logoSize?: 'sm' | 'md' | 'lg';
}

export function Logo({
  className = '',
  logoType = 'mark',
  logoTheme = 'glow',
  logoSize = 'md',
}: LogoProps) {
  const { component } = logoData[logoType];
  return React.createElement(component, {
    className: classNames(
      'logo',
      `logo-${logoType}`,
      `logo--${logoTheme}`,
      `logo--${logoSize}`,
      className
    ),
  });
}
