import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as OptOutActor } from '../../machines/OptOutMachine';
import Button from '../Button/Button';
import { Confirmation } from '../Confirmation/Confirmation';
import { Loader } from '../Loader/Loader';

export function OptOut({ actor }: { actor: OptOutActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  switch (true) {
    case state.matches('confirm'):
      return (
        <main className="confirmation-complete">
          <Confirmation pageType="wide-button">
            <h1 className="heading">{t('optOut.title')}</h1>
            <p>{t('optOut.description')}</p>
            <Button
              onClick={() => send({ type: 'saveOptOut' })}
              buttonType="primary"
            >
              {t('optOut.confirmationButtonTitle')}
            </Button>
            <Button
              onClick={() => send({ type: 'cancelOptOut' })}
              buttonType="secondary"
            >
              {t('optOut.nevermindButtonTitle')}
            </Button>
          </Confirmation>
        </main>
      );
    case state.matches('optingOut'):
      return <Loader theme="full-page" />;
    case state.matches('done'):
      return (
        <main className="confirmation-complete">
          <Confirmation pageType="default">
            <h1 className="heading">{t('optOut.confirm.title')}</h1>
            <p>{t('optOut.confirm.description.paragraph1')}</p>
            <p>{t('optOut.confirm.description.paragraph2')}</p>
            <p>{t('optOut.confirm.description.paragraph3')}</p>
            <Button
              onClick={() => send({ type: 'finishOptOut' })}
              buttonType="secondary"
            >
              {t('optOut.homeButtonTitle')}
            </Button>
          </Confirmation>
        </main>
      );
    case state.matches('error'):
      return (
        <main className="confirmation-error">
          <Confirmation pageType="default">
            <h1 className="heading">{t('optOut.error.title')}</h1>
            <p>{t('optOut.error.description.paragraph1')}</p>
            <Button
              onClick={() => send({ type: 'finishOptOut' })}
              buttonType="secondary"
            >
              {t('optOut.homeButtonTitle')}
            </Button>
          </Confirmation>
        </main>
      );
    default:
      return null;
  }
}
