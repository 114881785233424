import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor } from '../../machines/ProfileListMachine';
import { Feedback } from '../Feedback/Feedback';
import { EditListItem } from './EditListItem';
import './Favorites.scss';

export function Favorites({ actor }: { actor: Actor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  const favorites = state.context.profiles.filter((p) =>
    state.context.profile.favorites.includes(p.id)
  );

  return (
    <div className="page favourites">
      <Helmet>
        <title>
          {t('title')} - {t('editLists.favorites.heading')}
        </title>
      </Helmet>
      <h2 className="heading favourites__heading">
        {t('editLists.favorites.heading')}
      </h2>
      <p className="favourites__intro">{t('editLists.favorites.intro')}</p>
      {state.matches('remove.idle.invalid') && (
        <Feedback>{t('editLists.favorites.error')}</Feedback>
      )}
      <ul className="edit-list">
        {favorites.map((profile) => (
          <EditListItem
            key={profile.id}
            profileClick={() => {
              send({ type: 'viewProfile', profile });
            }}
            profileImage={profile.images[0].xs}
            profileName={profile.name}
            editClick={() => {
              send({ type: 'removeProfile', profile });
            }}
            editLabel={t('editLists.removeButton')}
            userStatus={profile.status}
          />
        ))}
      </ul>
    </div>
  );
}
