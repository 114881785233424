import { createMachine, assign, ActorRefFrom, Sender, send } from 'xstate';
import { assertEventType } from './utils';
import { MessageDocument, ProfileDocument, Store } from '../types';
import {
  LoadHotComplete,
  LoadNotComplete,
  LoadMessageComplete,
  LoadProfileComplete,
  machine as loadingMachine,
  createDelays as createLoadingDelays,
} from './LoadingMachine';

export interface Context {
  exhibit: 'chat' | 'hot';
  hots: ProfileDocument[];
  nots: ProfileDocument[];
  messages: MessageDocument[];
  moderators: ProfileDocument[];
}

interface LoadHot {
  type: 'loadHot';
  profile: ProfileDocument;
}

interface UnloadHot {
  type: 'unloadHot';
  profile: ProfileDocument;
}

interface LoadNot {
  type: 'loadNot';
  profile: ProfileDocument;
}

interface UnloadNot {
  type: 'unloadNot';
  profile: ProfileDocument;
}

interface LoadModerator {
  type: 'loadModerator';
  profile: ProfileDocument;
}

interface UnloadModerator {
  type: 'unloadModerator';
  profile: ProfileDocument;
}

interface LoadMessage {
  type: 'loadMessage';
  message: MessageDocument;
}

type Event =
  | LoadHot
  | UnloadHot
  | LoadHotComplete
  | LoadNot
  | UnloadNot
  | LoadModerator
  | UnloadModerator
  | LoadNotComplete
  | LoadMessage
  | LoadMessageComplete
  | LoadProfileComplete;

export const machine = createMachine<Context, Event>(
  {
    id: 'exhibitionMachine',
    initial: 'init',
    states: {
      init: {
        always: [
          { target: 'hot', cond: 'isHotExhibit' },
          { target: 'chat', cond: 'isChatExhibit' },
        ],
      },
      hot: {
        initial: 'loading',
        invoke: [
          {
            id: 'watchHot',
            src: 'watchHot',
          },
          {
            id: 'watchNot',
            src: 'watchNot',
          },
        ],
        states: {
          loading: {
            invoke: {
              id: 'loadingMachine',
              src: 'loadingHotMachine',
              onDone: 'ready',
            },
            on: {
              loadHotComplete: { actions: 'sendToLoadingMachine' },
              loadNotComplete: { actions: 'sendToLoadingMachine' },
            },
          },
          ready: {},
        },
      },
      chat: {
        initial: 'loading',
        invoke: [
          {
            id: 'watchMessages',
            src: 'watchMessages',
          },
          {
            id: 'watchModerators',
            src: 'watchModerators',
          },
        ],
        states: {
          loading: {
            invoke: {
              id: 'loadingMachine',
              src: 'loadingChatMachine',
              onDone: 'ready',
            },
            on: {
              loadMessageComplete: { actions: 'sendToLoadingMachine' },
              loadProfileComplete: { actions: 'sendToLoadingMachine' },
            },
          },
          ready: {},
        },
      },
    },
    on: {
      loadHot: { actions: 'assignHot' },
      unloadHot: { actions: 'removeHot' },
      loadNot: { actions: 'assignNot' },
      unloadNot: { actions: 'removeNot' },
      loadModerator: { actions: 'assignModerator' },
      unloadModerator: { actions: 'removeModerator' },
      loadMessage: { actions: 'assignMessage' },
    },
  },
  {
    guards: {
      isHotExhibit: (context) => context.exhibit === 'hot',
      isChatExhibit: (context) => context.exhibit === 'chat',
    },
    actions: {
      sendToLoadingMachine: send((context, event) => event, {
        to: 'loadingMachine',
      }),
      assignMessage: assign({
        messages: (context, event) => {
          assertEventType(event, 'loadMessage');
          const mods = context.moderators.map((m) => m.id);
          const include =
            !mods.includes(event.message.from) &&
            !mods.includes(event.message.to);

          return include
            ? [
                ...context.messages.filter((m) => m.id !== event.message.id),
                event.message,
              ]
            : context.messages;
        },
      }),
      assignHot: assign({
        hots: (context, event) => {
          assertEventType(event, 'loadHot');
          return assignToProfiles(context.hots, event.profile, (p) =>
            event.profile.hotCount === p.hotCount
              ? event.profile.name < p.name
              : event.profile.hotCount > p.hotCount
          );
        },
      }),
      removeHot: assign({
        hots: (context, event) => {
          assertEventType(event, 'unloadHot');
          return context.hots.filter((p) => p.id !== event.profile.id);
        },
      }),
      assignNot: assign({
        nots: (context, event) => {
          assertEventType(event, 'loadNot');
          return assignToProfiles(context.nots, event.profile, (p) =>
            event.profile.notCount === p.notCount
              ? event.profile.name < p.name
              : event.profile.notCount > p.notCount
          );
        },
      }),
      removeNot: assign({
        nots: (context, event) => {
          assertEventType(event, 'unloadNot');
          return context.nots.filter((p) => p.id !== event.profile.id);
        },
      }),
      assignModerator: assign({
        moderators: (context, event) => {
          assertEventType(event, 'loadModerator');
          return [
            ...context.moderators.filter((p) => p.id !== event.profile.id),
            event.profile,
          ];
        },
        messages: (context, event) => {
          assertEventType(event, 'loadModerator');
          return context.messages.filter(
            (m) => m.from !== event.profile.id && m.to !== event.profile.id
          );
        },
      }),
      removeModerator: assign({
        moderators: (context, event) => {
          assertEventType(event, 'unloadModerator');
          return context.moderators.filter((p) => p.id !== event.profile.id);
        },
      }),
    },
  }
);

function assignToProfiles(
  profiles: ProfileDocument[],
  profile: ProfileDocument,
  predicate: (p: ProfileDocument) => boolean
) {
  // First remove the profile if we already have a version of it.
  const partial = profiles.filter((i) => profile.id !== i.id);

  // Once removal is complete find correct location and insert it
  const insertAt = partial.findIndex(predicate);

  return insertAt >= 0
    ? [...partial.slice(0, insertAt), profile, ...partial.slice(insertAt)]
    : [...partial, profile];
}

export type Actor = ActorRefFrom<typeof machine>;

export function createServices(store: Store) {
  return {
    loadingHotMachine: loadingMachine.withConfig({
      delays: createLoadingDelays(store),
      guards: {
        isDoneLoading: (context) =>
          context.isHotComplete && context.isNotComplete,
      },
    }),
    loadingChatMachine: loadingMachine.withConfig({
      delays: createLoadingDelays(store),
      guards: {
        isDoneLoading: (context) => context.isMessageComplete,
      },
    }),
    watchHot: (context: Context) => {
      return (callback: Sender<LoadHot | UnloadHot | LoadHotComplete>) => {
        return store.watchProfileDocuments(
          [
            { type: 'where', fieldName: 'state', fieldValue: '2fik' },
            { type: 'order', fieldName: 'hotCount', direction: 'desc' },
            { type: 'order', fieldName: 'name' },
            { type: 'limit', value: 5 },
          ],
          (profile) => {
            callback({ type: 'loadHot', profile });
          },
          (profile) => {
            callback({ type: 'unloadHot', profile });
          },
          () => {
            callback({ type: 'loadHotComplete' });
          }
        );
      };
    },
    watchNot: (context: Context) => {
      return (callback: Sender<LoadNot | UnloadNot | LoadNotComplete>) => {
        return store.watchProfileDocuments(
          [
            { type: 'where', fieldName: 'state', fieldValue: '2fik' },
            { type: 'order', fieldName: 'notCount', direction: 'desc' },
            { type: 'order', fieldName: 'name' },
            { type: 'limit', value: 5 },
          ],
          (profile) => {
            callback({ type: 'loadNot', profile });
          },
          (profile) => {
            callback({ type: 'unloadNot', profile });
          },
          () => {
            callback({ type: 'loadNotComplete' });
          }
        );
      };
    },
    watchModerators: (context: Context) => {
      return (
        callback: Sender<LoadModerator | UnloadModerator | LoadProfileComplete>
      ) => {
        return store.watchProfileDocuments(
          [{ type: 'where', fieldName: 'order', fieldValue: -1 }],
          (profile) => {
            callback({ type: 'loadModerator', profile });
          },
          (profile) => {
            callback({ type: 'unloadModerator', profile });
          },
          () => {
            callback({ type: 'loadProfileComplete' });
          }
        );
      };
    },
    watchMessages: (context: Context) => {
      return (callback: Sender<LoadMessage | LoadMessageComplete>) => {
        return store.watchMessageDocuments(
          [
            { type: 'order', fieldName: 'time', direction: 'desc' },
            // Put a limit on this query since the performance will have
            // lots and lots of messages.
            { type: 'limit', value: 50 },
          ],
          (message) => {
            callback({ type: 'loadMessage', message });
          },
          (message) => {
            // Messages can never be unloaded.
          },
          () => {
            callback({ type: 'loadMessageComplete' });
          }
        );
      };
    },
  };
}
