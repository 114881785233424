import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as EditPhotosActor } from '../../machines/EditPhotosMachine';
import { Actor as UploadPhotoActor } from '../../machines/UploadPhotoMachine';
import { UploadPhoto } from '../UploadPhoto/UploadPhoto';
import { IconButton } from '../IconButton/IconButton';
import Button from '../Button/Button';
import { Feedback } from '../Feedback/Feedback';
import './EditPhotos.scss';

export function EditPhotos({ actor }: { actor: EditPhotosActor }) {
  const [state, send] = useActor(actor);
  const profile = state.context.profile;
  const { t } = useTranslation();

  switch (true) {
    case state.matches('edit'):
      return (
        <div className="page edit-photos">
          <Helmet>
            <title>
              {t('title')} - {t('editPhotos.edit.heading')}
            </title>
          </Helmet>
          <h2 className="heading edit-photos__heading">
            {t('editPhotos.edit.heading')}
          </h2>
          <p className="intro edit-photos__intro">
            {t('editPhotos.edit.intro')}
          </p>
          <div className="edit-photos__button">
            <Button
              buttonType="primary"
              className="edit-photos__add-button"
              onClick={() => send({ type: 'uploadPhoto' })}
            >
              {t('editPhotos.edit.addButton')}
            </Button>
          </div>
          {state.matches('remove.idle.invalid') && (
            <Feedback>{t('editPhotos.edit.removeError')}</Feedback>
          )}
          <ul className="edit-photos__list">
            {profile.images
              .filter((photo) => photo.id !== 'default')
              .map((photo) => (
                <li className="edit-photos__list-item" key={photo.id}>
                  <img
                    src={photo.md}
                    alt="Profile"
                    className="edit-photos__photo"
                  />
                  <IconButton
                    buttonType="labelless"
                    iconName="close"
                    iconSize="sm"
                    className="edit-photos__photo-delete"
                    onClick={() => {
                      send({ type: 'removePhoto', photo });
                    }}
                  >
                    {t('editPhotos.edit.removeButton')}
                  </IconButton>
                </li>
              ))}
          </ul>
        </div>
      );

    case state.matches('upload'):
      return (
        <div className="page">
          <UploadPhoto
            actor={state.children.uploadPhotoMachine as UploadPhotoActor}
          />
        </div>
      );

    default:
      return null;
  }
}
