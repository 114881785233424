import React from 'react';
import './HeaderOverlay.scss';
import classNames from 'classnames';
import { IconButton } from '../IconButton/IconButton';
import {
  ProfileIcon,
  UserProfileStatus,
  UserProfileName,
} from '../Profile/ProfileIcon';

interface HeaderOverlayProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  userStatus?: UserProfileStatus;
  profileIcon?: boolean;
  profileIconAvatar?: string;
  profileIconName?: UserProfileName;
  profileClick?: (e: React.SyntheticEvent) => void;
}

export function HeaderOverlay(props: HeaderOverlayProps) {
  const {
    children,
    className,
    onClick,
    userStatus,
    profileIcon,
    profileIconAvatar,
    profileIconName,
    profileClick,
  } = props;

  return (
    <section
      className={classNames(className, 'header-overlay ', {
        'header-overlay--with-icon': profileIcon,
      })}
    >
      {profileIcon && (
        <ProfileIcon
          onClick={profileClick}
          userStatus={userStatus}
          image={profileIconAvatar}
          name={profileIconName}
        />
      )}
      <div className="header-overlay__title-wrapper">
        <h1 className="header-overlay__title">{children}</h1>
      </div>
      <IconButton
        buttonType="labelless"
        iconName="close"
        className="header-overlay__icon-close"
        onClick={onClick}
        type="button"
      />
    </section>
  );
}
