import React from 'react';
import './NotificationItem.scss';
import classnames from 'classnames';
import { Icon } from '../Icon/Icon';
import avatarImg from '../../images/default-avatar-lg.jpg';

interface NotificationProps {
  key?: string;
  notifType?: 'inbox' | 'update';
  notifSeen?: boolean;
  className?: string;
  profileName: string;
  profileImage: string;
  profileFave?: boolean;
  profileBlocked?: boolean;
  notifMessage?: string;
  notifAction?: string;
  notifDate?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

export function NotificationItem(props: NotificationProps) {
  const {
    key,
    notifType = 'inbox',
    notifSeen = false,
    className,
    profileName,
    profileImage = avatarImg,
    profileFave = false,
    profileBlocked = false,
    notifMessage,
    notifAction,
    notifDate = '',
    onClick,
  } = props;

  return (
    <button
      className={classnames('notification', className, {
        'notificaton--inbox': notifType === 'inbox',
        'notificaton--update': notifType === 'update',
        'notificaton--blocked': profileBlocked,
      })}
      onClick={onClick}
      key={key}
    >
      <div className="notification__user-icon">
        <img src={profileImage} alt={`${profileName} avatar`} />
      </div>
      <div className="notification__content">
        <div className="notification__user">
          <span className="notification__user-name">{profileName}</span>
          {profileBlocked === true && (
            <Icon
              size="xs"
              name="block"
              theme="glow"
              className="notification__user-blocked"
            />
          )}
          {profileFave === true && (
            <Icon
              size="xs"
              name="favourite"
              theme="glow"
              className="notification__user-favourite"
            />
          )}
          {notifAction && (
            <span className="notification__user-action">{notifAction}</span>
          )}
        </div>
        <div className="notification__detail">
          {notifMessage && (
            <div className="notification__detail-message">
              <p>{notifMessage}</p>
            </div>
          )}
          <span className="notification__detail-meta">
            {!notifSeen && (
              <Icon
                size="xs"
                name="circle"
                theme="glow"
                className="notification__detail-indicator"
              />
            )}
            <span className="notification__detail-meta-date">{notifDate}</span>
          </span>
        </div>
      </div>
    </button>
  );
}
