import i18n from 'i18next';
import { ActorRefFrom, Sender, assign, createMachine, send } from 'xstate';
import { assertEventType, getProfileWithAge } from './utils';
import { watchHistory, pushHistory, hasHistory, getHistory } from './history';

import {
  ConfigDocument,
  LastReadDocument,
  MessageDocument,
  PartialMessage,
  Profile,
  ProfileDocument,
  PartialRank,
  RankDocument,
  Store,
  ProfileStatus,
  User,
  ProfileWithAge,
  Presence,
} from '../types';

import { LoadTime } from './AppMachine';

import {
  machine as loadingMachine,
  createDelays as createLoadingDelays,
  LoadConfigComplete,
  LoadProfileComplete,
  LoadMessageComplete,
  LoadRankComplete,
  LoadLastReadComplete,
} from './LoadingMachine';

import {
  machine as inboxMachine,
  mapParentContextToContext as mapParentContextToInboxContext,
} from './InboxMachine';

import {
  machine as browseMachine,
  mapParentContextToContext as mapParentContextToBrowseContext,
} from './BrowseMachine';

import {
  machine as chatMachine,
  createServices as createChatServices,
  mapParentContextToContext as mapParentContextToChatContext,
} from './ChatMachine';

import {
  machine as profileMachine,
  createServices as createProfileServices,
  mapParentContextToContext as mapParentContextToProfileContext,
} from './ProfileMachine';

import {
  machine as updatesMachine,
  createServices as createUpdatesServices,
  mapParentContextToContext as mapParentContextToUpdatesContext,
} from './UpdatesMachine';

import {
  machine as editProfileMachine,
  createServices as createEditProfileServices,
  mapParentContextToContext as mapParentContextToEditProfileContext,
} from './EditProfileMachine';

import {
  machine as editPhotosMachine,
  createServices as createEditPhotosServices,
  mapParentContextToContext as mapParentContextToEditPhotosContext,
} from './EditPhotosMachine';

import {
  machine as deleteProfileMachine,
  createServices as createDeleteProfileServices,
  mapParentContextToContext as mapParentContextToDeleteProfileContext,
} from './DeleteProfileMachine';

import {
  machine as profileListMachine,
  mapParentContextToBlockedContext,
  mapParentContextToFavoritesContext,
  createBlockedServices,
  createFavoritesServices,
  createFavoriteActions,
  createBlockedActions,
} from './ProfileListMachine';

// Threshold in milliseconds = 10 minutes
const INACTIVE_THRESHOLD = 10 * 60 * 1000;
const MACHINE_ID = 'romanceMachine';

export interface Context {
  user: User;
  time: Date;
  config: ConfigDocument;
  profile: ProfileWithAge;
  profiles: Profile[];
  ranks: PartialRank[];
  presences: Presence[];
  messages: PartialMessage[];
  lastReads: LastReadDocument[];
  isSignIn: boolean;
}

export interface UpdateContext {
  type: 'updateContext';
  context: Context;
}

export interface ViewChat {
  type: 'viewChat';
  profile: Profile;
}

export interface ViewProfile {
  type: 'viewProfile';
  profile: Profile;
}

export interface ViewSelfie {
  type: 'viewSelfie';
}

export interface ClosePanel {
  type: 'closePanel';
}

export interface DeleteProfile {
  type: 'deleteProfile';
}

interface LoadRank {
  type: 'loadRank';
  rank: RankDocument;
}

interface LoadLastRead {
  type: 'loadLastRead';
  lastRead: LastReadDocument;
}

interface LoadMessage {
  type: 'loadMessage';
  message: MessageDocument;
}

interface LoadProfile {
  type: 'loadProfile';
  profile: ProfileDocument;
}

interface LoadConfig {
  type: 'loadConfig';
  config: ConfigDocument;
}

interface UnloadProfile {
  type: 'unloadProfile';
  profile: ProfileDocument;
}

interface UnloadRank {
  type: 'unloadRank';
  rank: RankDocument;
}

interface SignOut {
  type: 'signOut';
}

interface ViewAccount {
  type: 'viewAccount';
}

interface ViewUpdates {
  type: 'viewUpdates';
}

interface ViewInbox {
  type: 'viewInbox';
}

interface ViewFavorites {
  type: 'viewFavorites';
}

interface ViewBlocked {
  type: 'viewBlocked';
}

interface ViewBrowse {
  type: 'viewBrowse';
}

interface ViewHistory {
  type: 'viewHistory';
}

interface ViewReminder {
  type: 'viewReminder';
}

interface CreateProfile {
  type: 'createProfile';
}

interface EditProfile {
  type: 'editProfile';
}

interface EditPhotos {
  type: 'editPhotos';
}

interface DoneDeleteProfile {
  type: 'done.invoke.deleteProfileMachine';
  data: boolean;
}

type Event =
  | ViewAccount
  | ViewUpdates
  | ViewInbox
  | ViewChat
  | ViewFavorites
  | ViewBlocked
  | ViewProfile
  | ViewBrowse
  | ViewSelfie
  | ViewHistory
  | ViewReminder
  | CreateProfile
  | EditProfile
  | EditPhotos
  | DeleteProfile
  | ClosePanel
  | SignOut
  | LoadProfile
  | LoadMessage
  | LoadRank
  | LoadLastRead
  | LoadTime
  | LoadConfig
  | LoadProfileComplete
  | LoadMessageComplete
  | LoadRankComplete
  | LoadLastReadComplete
  | LoadConfigComplete
  | UnloadProfile
  | UnloadRank
  | UpdateContext
  | DoneDeleteProfile;

export const machine = createMachine<Context, Event>(
  {
    id: MACHINE_ID,
    activities: ['onlinePresence'],
    invoke: [
      {
        id: 'watchProfile',
        src: 'watchProfile',
      },
      {
        id: 'watchConfig',
        src: 'watchConfig',
      },
      {
        id: 'watchProfiles',
        src: 'watchProfiles',
      },
      {
        id: 'watchToMessages',
        src: 'watchToMessages',
      },
      {
        id: 'watchFromMessages',
        src: 'watchFromMessages',
      },
      {
        id: 'watchToRanks',
        src: 'watchToRanks',
      },
      {
        id: 'watchFromRanks',
        src: 'watchFromRanks',
      },
      {
        id: 'watchFromLastReads',
        src: 'watchFromLastReads',
      },
      {
        id: 'watchHistory',
        src: 'watchHistory',
      },
    ],
    initial: 'loading',
    states: {
      loading: {
        initial: 'data',
        states: {
          data: {
            invoke: {
              id: 'loadingMachine',
              src: 'loadingMachine',
              onDone: 'language',
            },
            on: {
              loadConfigComplete: { actions: 'sendToLoadingMachine' },
              loadProfileComplete: { actions: 'sendToLoadingMachine' },
              loadMessageComplete: { actions: 'sendToLoadingMachine' },
              loadLastReadComplete: { actions: 'sendToLoadingMachine' },
              loadRankComplete: { actions: 'sendToLoadingMachine' },
            },
          },
          language: {
            invoke: {
              id: 'load2FikLang',
              src: 'load2FikLang',
              onDone: 'done',
              onError: 'done',
            },
          },
          done: {
            type: 'final',
          },
        },
        onDone: 'ready',
      },
      ready: {
        type: 'parallel',
        states: {
          header: {
            type: 'parallel',
            states: {
              messages: {
                initial: 'read',
                states: {
                  read: {},
                  unread: {},
                },
                on: {
                  updateContext: [
                    { target: '.unread', cond: 'hasUnreadMessages' },
                    { target: '.read' },
                  ],
                },
              },
              updates: {
                initial: 'read',
                states: {
                  read: {},
                  unread: {},
                },
                on: {
                  updateContext: [
                    { target: '.unread', cond: 'hasUnreadUpdates' },
                    { target: '.read' },
                  ],
                },
              },
            },
          },
          body: {
            initial: 'init',
            states: {
              // Init is a transition state to show the user the sign in reminder before they log in
              init: {
                always: [
                  { target: 'history', cond: 'is2Fik' },
                  { target: 'signUpConfirmation', cond: 'isNewProfile' },
                  { target: 'signInReminder', cond: 'isSignInLink' },
                  { target: 'reminder' },
                ],
              },
              history: {
                invoke: {
                  id: 'checkHistory',
                  src: 'checkHistory',
                },
              },
              browse: {
                entry: ['setLocationToBrowse', 'pushBrowse'],
                invoke: {
                  id: 'browseMachine',
                  src: 'browseMachine',
                  data: mapParentContextToBrowseContext,
                },
                on: {
                  updateContext: { actions: 'sendToBrowseMachine' },
                },
              },
              profile: {
                entry: ['setLocationToProfile', 'pushProfile'],
                invoke: {
                  id: 'profileMachine',
                  src: 'profileMachine',
                  data: (context: Context, event: Event) => {
                    assertEventType(event, 'viewProfile');

                    return mapParentContextToProfileContext(
                      context,
                      event.profile
                    );
                  },
                },
                on: {
                  // Re-eneter the profile state when viewProfile is called a second
                  // time. This can happen when a user is using the forward and back
                  // menu of the browser to switch their states. Without this we would
                  // never transition to displaying the new profile.
                  viewProfile: 'profile',
                  updateContext: { actions: 'sendToProfileMachine' },
                },
              },
              createProfile: {
                invoke: {
                  id: 'editProfileMachine',
                  src: 'editProfileMachine',
                  data: mapParentContextToEditProfileContext,
                  onDone: 'signUpReminder',
                },
                on: {
                  updateContext: { actions: 'sendToEditProfileMachine' },
                },
              },
              editProfile: {
                entry: ['setLocationToEditProfile', 'pushEditProfile'],
                invoke: {
                  id: 'editProfileMachine',
                  src: 'editProfileMachine',
                  data: mapParentContextToEditProfileContext,
                  onDone: 'browse',
                },
                on: {
                  updateContext: { actions: 'sendToEditProfileMachine' },
                },
              },
              editPhotos: {
                entry: ['setLocationToEditPhotos', 'pushEditPhotos'],
                invoke: {
                  id: 'editPhotosMachine',
                  src: 'editPhotosMachine',
                  data: mapParentContextToEditPhotosContext,
                  onDone: 'browse',
                },
                on: {
                  updateContext: { actions: 'sendToEditPhotosMachine' },
                },
              },
              deleteProfile: {
                entry: 'pushDeleteProfile',
                invoke: {
                  id: 'deleteProfileMachine',
                  src: 'deleteProfileMachine',
                  data: mapParentContextToDeleteProfileContext,
                  onDone: [
                    { target: '#done', cond: 'isDeleteComplete' },
                    { target: 'browse' },
                  ],
                },
                on: {
                  updateContext: { actions: 'sendToDeleteProfileMachine' },
                },
              },
              favorites: {
                entry: ['setLocationToFavorites', 'pushFavorites'],
                invoke: {
                  id: 'favoritesMachine',
                  src: 'favoritesMachine',
                  data: mapParentContextToFavoritesContext,
                },
                on: {
                  updateContext: { actions: 'sendToFavoritesMachine' },
                },
              },
              blocked: {
                entry: ['setLocationToBlocked', 'pushBlocked'],
                invoke: {
                  id: 'blockedMachine',
                  src: 'blockedMachine',
                  data: mapParentContextToBlockedContext,
                },
                on: {
                  updateContext: { actions: 'sendToBlockedMachine' },
                },
              },
              reminder: {
                on: {
                  viewHistory: 'history',
                },
              },
              selfie: {
                entry: ['setLocationToSelfie', 'pushSelfie'],
              },
              signInReminder: {
                on: {
                  viewReminder: 'reminder',
                },
              },
              signUpConfirmation: {},
              signUpReminder: {},
            },
            on: {
              createProfile: '.createProfile',
              deleteProfile: '.deleteProfile',
              editProfile: '.editProfile',
              editPhotos: '.editPhotos',
              viewFavorites: '.favorites',
              viewBlocked: '.blocked',
              viewBrowse: '.browse',
              viewSelfie: '.selfie',
              viewProfile: '.profile',
            },
          },
          panel: {
            initial: 'hidden',
            states: {
              hidden: {
                initial: 'implicit',
                entry: 'setLocationToHidden',
                states: {
                  // Ensure we only push history when the panel is closed explicity.
                  implicit: {},
                  explicit: {
                    entry: 'pushClosePanel',
                  },
                },
              },
              account: {
                entry: ['setLocationToAccount', 'pushAccount'],
              },
              updates: {
                entry: ['setLocationToUpdates', 'pushUpdates'],
                invoke: {
                  id: 'updatesMachine',
                  src: 'updatesMachine',
                  data: mapParentContextToUpdatesContext,
                },
                on: {
                  updateContext: { actions: 'sendToUpdatesMachine' },
                },
              },
              inbox: {
                initial: 'view',
                states: {
                  view: {
                    entry: ['setLocationToInbox', 'pushInbox'],
                    invoke: {
                      id: 'inboxMachine',
                      src: 'inboxMachine',
                      data: mapParentContextToInboxContext,
                    },
                    on: {
                      viewChat: 'chat',
                      updateContext: { actions: 'sendToInboxMachine' },
                    },
                  },
                  chat: {
                    entry: ['setLocationToChat', 'pushChat'],
                    invoke: {
                      id: 'chatMachine',
                      src: 'chatMachine',
                      data: (context: Context, event: Event) => {
                        assertEventType(event, 'viewChat');

                        return mapParentContextToChatContext(
                          context,
                          event.profile,
                          ''
                        );
                      },
                    },
                    on: {
                      viewInbox: 'view',
                      closePanel: 'view',
                      updateContext: { actions: 'sendToChatMachine' },
                    },
                  },
                },
              },
              chat: {
                entry: ['setLocationToChat', 'pushChat'],
                invoke: {
                  id: 'chatMachine',
                  src: 'chatMachine',
                  data: (context: Context, event: Event) => {
                    assertEventType(event, 'viewChat');

                    return mapParentContextToChatContext(
                      context,
                      event.profile,
                      ''
                    );
                  },
                },
                on: {
                  updateContext: { actions: 'sendToChatMachine' },
                },
              },
            },
            on: {
              viewAccount: '.account',
              viewUpdates: '.updates',
              viewInbox: '.inbox',
              viewChat: '.chat',
              closePanel: '.hidden.explicit',
              // These can happen any time now with back and forward buttons
              deleteProfile: '.hidden',
              editProfile: '.hidden',
              editPhotos: '.hidden',
              viewFavorites: '.hidden',
              viewBlocked: '.hidden',
              viewBrowse: '.hidden',
              viewSelfie: '.hidden',
              viewProfile: '.hidden',
            },
          },
        },
      },
      done: {
        id: 'done',
        type: 'final',
      },
    },
    on: {
      unloadProfile: {
        actions: [
          assign<Context, UnloadProfile>({
            profiles: removeFromProfiles,
          }),
          assign<Context, UnloadProfile>({
            ranks: rebuildRanks,
            messages: rebuildMessages,
            presences: rebuildPresences,
          }),
          'sendUpdateContext',
        ],
      },
      unloadRank: {
        actions: [
          assign<Context, UnloadRank>({
            ranks: removeFromRanks,
          }),
          assign<Context, UnloadRank>({
            profiles: rebuildProfiles,
          }),
          'sendUpdateContext',
        ],
      },
      loadProfile: [
        {
          target: 'done',
          cond: 'isBannedProfile',
        },
        {
          actions: [
            assign<Context, LoadProfile>({
              profile: assignToProfile,
              profiles: assignToProfiles,
            }),
            assign<Context, LoadProfile>({
              profiles: rebuildProfiles,
            }),
            assign<Context, LoadProfile>({
              ranks: rebuildRanks,
              messages: rebuildMessages,
              presences: rebuildPresences,
            }),
            'sendUpdateContext',
          ],
        },
      ],
      loadRank: {
        actions: [
          assign<Context, LoadRank>({
            ranks: assignToRanks,
          }),
          assign<Context, LoadRank>({
            profiles: rebuildProfiles,
          }),
          'sendUpdateContext',
        ],
      },
      loadMessage: {
        actions: [
          assign<Context, LoadMessage>({
            messages: assignToMessages,
          }),
          'sendUpdateContext',
        ],
      },
      loadLastRead: {
        actions: [
          assign<Context, LoadLastRead>({
            lastReads: assignToLastReads,
          }),
          assign<Context, LoadLastRead>({
            messages: rebuildMessages,
          }),
          'sendUpdateContext',
        ],
      },
      loadTime: {
        actions: [
          assign<Context, LoadTime>({
            time: (context, event) => event.time,
            profile: (context, event) =>
              getProfileWithAge(event.time, context.profile),
          }),
          assign<Context, LoadTime>({
            profiles: rebuildProfiles,
          }),
          assign<Context, LoadTime>({
            messages: rebuildMessages,
          }),
          'sendUpdateContext',
        ],
      },
      loadConfig: {
        actions: [
          assign<Context, LoadConfig>({
            config: (context, event) => event.config,
          }),
          'sendUpdateContext',
        ],
      },
      signOut: 'done',
    },
  },
  {
    guards: {
      hasUnreadUpdates: (context: Context) => {
        return (
          context.ranks.some(
            (r) =>
              r.to === context.profile.id &&
              r.profile !== undefined &&
              !r.profile.isBlocked &&
              !r.isRead
          ) || context.presences.some((p) => !p.isRead)
        );
      },
      hasUnreadMessages: (context: Context) => {
        return context.messages.some(
          (m) => m.profile !== undefined && !m.profile.isBlocked && !m.isRead
        );
      },
      isNewProfile: (context: Context) => {
        return context.profile.state === 'new';
      },
      isDeleteComplete: (context: Context, event: Event) => {
        assertEventType(event, 'done.invoke.deleteProfileMachine');
        return event.data;
      },
      isBannedProfile: (context: Context, event: Event) => {
        assertEventType(event, 'loadProfile');
        return (
          context.profile.id === event.profile.id &&
          event.profile.state === 'banned'
        );
      },
      isSignInLink: (context: Context) => context.isSignIn,
      is2Fik: (context: Context) => context.user.is2Fik,
    },
    actions: {
      pushBrowse: (context, event) => {
        if (!context.user.is2Fik) {
          pushHistory(
            MACHINE_ID,
            event.type === 'viewBrowse' ? event : { type: 'viewBrowse' }
          );
        }
      },
      pushProfile: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewProfile');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushSelfie: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewSelfie');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushBlocked: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewBlocked');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushFavorites: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewFavorites');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushEditProfile: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'editProfile');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushEditPhotos: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'editPhotos');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushDeleteProfile: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'deleteProfile');
          pushHistory(MACHINE_ID, event);
        }
      },
      pushAccount: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewAccount');
          pushHistory(MACHINE_ID, event, 'push');
        }
      },
      pushUpdates: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewUpdates');
          pushHistory(MACHINE_ID, event, 'push');
        }
      },
      pushInbox: (context, event) => {
        if (!context.user.is2Fik) {
          if (event.type === 'closePanel') {
            pushHistory(MACHINE_ID, event, 'pop');
          } else {
            assertEventType(event, 'viewInbox');
            pushHistory(MACHINE_ID, event, 'push');
          }
        }
      },
      pushChat: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'viewChat');
          pushHistory(MACHINE_ID, event, 'push');
        }
      },
      pushClosePanel: (context, event) => {
        if (!context.user.is2Fik) {
          assertEventType(event, 'closePanel');
          pushHistory(MACHINE_ID, event, 'pop');
        }
      },
      sendToLoadingMachine: sendToMachine('loadingMachine'),
      sendToBrowseMachine: sendToMachine('browseMachine'),
      sendToProfileMachine: sendToMachine('profileMachine'),
      sendToUpdatesMachine: sendToMachine('updatesMachine'),
      sendToInboxMachine: sendToMachine('inboxMachine'),
      sendToChatMachine: sendToMachine('chatMachine'),
      sendToEditPhotosMachine: sendToMachine('editPhotosMachine'),
      sendToEditProfileMachine: sendToMachine('editProfileMachine'),
      sendToFavoritesMachine: sendToMachine('favoritesMachine'),
      sendToBlockedMachine: sendToMachine('blockedMachine'),
      sendToDeleteProfileMachine: sendToMachine('deleteProfileMachine'),
      sendUpdateContext: send((context: Context) => {
        return {
          type: 'updateContext',
          context,
        };
      }),
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;

export function createActivities(store: Store) {
  return {
    onlinePresence: (context: Context) => {
      return !context.profile.isDead
        ? store.setPresence(context.profile)
        : () => {
            // Do nothing when the profile is dead
          };
    },
  };
}

export function createServices(store: Store) {
  return {
    browseMachine,
    inboxMachine,
    chatMachine: chatMachine.withConfig({
      services: createChatServices(store),
    }),
    profileMachine: profileMachine.withConfig({
      services: createProfileServices(store),
    }),
    updatesMachine: updatesMachine.withConfig({
      services: createUpdatesServices(store),
    }),
    editProfileMachine: editProfileMachine.withConfig({
      services: createEditProfileServices(store),
    }),
    editPhotosMachine: editPhotosMachine.withConfig({
      services: createEditPhotosServices(store),
    }),
    favoritesMachine: profileListMachine.withConfig({
      actions: createFavoriteActions(),
      services: createFavoritesServices(store),
    }),
    blockedMachine: profileListMachine.withConfig({
      actions: createBlockedActions(),
      services: createBlockedServices(store),
    }),
    deleteProfileMachine: deleteProfileMachine.withConfig({
      services: createDeleteProfileServices(store),
    }),
    loadingMachine: loadingMachine.withConfig({
      delays: createLoadingDelays(store),
    }),

    watchProfile: (context: Context) => {
      return (callback: Sender<LoadProfile>) => {
        return store.watchProfileDocument(context.profile.id, (profile) => {
          callback({
            type: 'loadProfile',
            profile,
          });
        });
      };
    },

    watchConfig: (context: Context) => {
      return (callback: Sender<LoadConfig | LoadConfigComplete>) => {
        return store.watchConfigDocument('romance', (config) => {
          callback({ type: 'loadConfig', config });
          callback({ type: 'loadConfigComplete' });
        });
      };
    },

    watchProfiles: (context: Context) => {
      return (
        callback: Sender<LoadProfile | UnloadProfile | LoadProfileComplete>
      ) => {
        return store.watchProfileDocuments(
          [
            {
              type: 'where',
              fieldName: 'state',
              fieldValue: context.user.is2Fik ? 'user' : '2fik',
            },
          ],
          (profile) => {
            callback({
              type: 'loadProfile',
              profile,
            });
          },
          (profile) => {
            callback({
              type: 'unloadProfile',
              profile,
            });
          },
          () => {
            callback({ type: 'loadProfileComplete' });
          }
        );
      };
    },

    watchToMessages: (context: Context) => {
      return (callback: Sender<LoadMessage | LoadMessageComplete>) => {
        return store.watchMessageDocuments(
          [{ type: 'where', fieldName: 'to', fieldValue: context.profile.id }],
          (message) => {
            callback({
              type: 'loadMessage',
              message,
            });
          },
          (message) => {
            // Messages can never be unloaded.
          },
          () => {
            callback({ type: 'loadMessageComplete' });
          }
        );
      };
    },

    watchFromMessages: (context: Context) => {
      return (callback: Sender<LoadMessage | LoadMessageComplete>) => {
        return store.watchMessageDocuments(
          [
            {
              type: 'where',
              fieldName: 'from',
              fieldValue: context.profile.id,
            },
          ],
          (message) => {
            callback({
              type: 'loadMessage',
              message,
            });
          },
          (message) => {
            // Messages can never be unloaded.
          },
          () => {
            callback({ type: 'loadMessageComplete' });
          }
        );
      };
    },

    watchToRanks: (context: Context) => {
      return (callback: Sender<LoadRank | UnloadRank | LoadRankComplete>) => {
        return store.watchRankDocuments(
          [{ type: 'where', fieldName: 'to', fieldValue: context.profile.id }],
          (rank) => {
            callback({
              type: 'loadRank',
              rank,
            });
          },
          (rank) => {
            callback({
              type: 'unloadRank',
              rank,
            });
          },
          () => {
            callback({ type: 'loadRankComplete' });
          }
        );
      };
    },

    watchFromRanks: (context: Context) => {
      return (callback: Sender<LoadRank | UnloadRank | LoadRankComplete>) => {
        return store.watchRankDocuments(
          [
            {
              type: 'where',
              fieldName: 'from',
              fieldValue: context.profile.id,
            },
          ],
          (rank) => {
            callback({
              type: 'loadRank',
              rank,
            });
          },
          (rank) => {
            callback({
              type: 'unloadRank',
              rank,
            });
          },
          () => {
            callback({ type: 'loadRankComplete' });
          }
        );
      };
    },

    watchFromLastReads: (context: Context) => {
      return (callback: Sender<LoadLastRead | LoadLastReadComplete>) => {
        return store.watchLastReadDocuments(
          [
            {
              type: 'where',
              fieldName: 'from',
              fieldValue: context.profile.id,
            },
          ],
          (lastRead) => {
            callback({
              type: 'loadLastRead',
              lastRead,
            });
          },
          (lastRead) => {
            // Ranks can never be unloaded.
          },
          () => {
            callback({ type: 'loadLastReadComplete' });
          }
        );
      };
    },

    watchHistory: (context: Context) => {
      return (callback: Sender<Event>) => watchHistory(MACHINE_ID, callback);
    },

    load2FikLang: (context: Context) => {
      return context.user.is2Fik
        ? i18n.changeLanguage(context.profile.language)
        : Promise.resolve();
    },

    checkHistory: (context: Context) => {
      return (callback: Sender<Event>) => {
        if (hasHistory(MACHINE_ID)) {
          for (const event of getHistory(MACHINE_ID).events) {
            callback(event);
          }
        } else {
          callback({ type: 'viewBrowse' });
        }
      };
    },
  };
}

export function createActions(store: Store) {
  return {
    setLocationToBrowse: (context: Context) => {
      updateBodyLocation(store, context, { state: 'browse' });
    },
    setLocationToSelfie: (context: Context) => {
      updateBodyLocation(store, context, { state: 'selfie' });
    },
    setLocationToEditPhotos: (context: Context) => {
      updateBodyLocation(store, context, { state: 'editPhotos' });
    },
    setLocationToEditProfile: (context: Context) => {
      updateBodyLocation(store, context, { state: 'editProfile' });
    },
    setLocationToFavorites: (context: Context) => {
      updateBodyLocation(store, context, { state: 'favorites' });
    },
    setLocationToBlocked: (context: Context) => {
      updateBodyLocation(store, context, { state: 'favorites' });
    },
    setLocationToProfile: (context: Context, event: Event) => {
      assertEventType(event, 'viewProfile');
      updateBodyLocation(store, context, {
        state: 'profile',
        profile: event.profile.id,
      });
    },
    setLocationToHidden: (context: Context) => {
      updatePanelLocation(store, context, { state: 'hidden' });
    },
    setLocationToAccount: (context: Context) => {
      updatePanelLocation(store, context, { state: 'account' });
    },
    setLocationToUpdates: (context: Context) => {
      updatePanelLocation(store, context, { state: 'updates' });
    },
    setLocationToInbox: (context: Context) => {
      updatePanelLocation(store, context, { state: 'inbox' });
    },
    setLocationToChat: (context: Context, event: Event) => {
      assertEventType(event, 'viewChat');
      updatePanelLocation(store, context, {
        state: 'chat',
        profile: event.profile.id,
      });
    },
  };
}

interface Location {
  id: string;
  profile: string;
  body?: BodyLocation;
  panel?: PanelLocation;
}

type BodyLocation =
  | {
      state:
        | 'browse'
        | 'selfie'
        | 'editProfile'
        | 'editPhotos'
        | 'favorites'
        | 'blocked';
    }
  | { state: 'profile'; profile: string };

type PanelLocation =
  | { state: 'hidden' | 'account' | 'inbox' | 'updates' }
  | { state: 'chat'; profile: string };

function updateBodyLocation(
  store: Store,
  context: Context,
  body: BodyLocation
) {
  if (context.user.is2Fik) {
    updateLocation(store, {
      id: context.user.id,
      profile: context.profile.id,
      body,
    });
  }
}

function updatePanelLocation(
  store: Store,
  context: Context,
  panel: PanelLocation
) {
  if (context.user.is2Fik) {
    updateLocation(store, {
      id: context.user.id,
      profile: context.profile.id,
      panel,
    });
  }
}

function updateLocation(store: Store, location: Location) {
  store.updateDocument('location', location);
}

// TODO: Can we generalize this so that it could be used with other machines?
// Right now the context and event types get in the way.
function sendToMachine(id: string) {
  return send((context: Context, event: Event) => event, { to: id });
}

function isRead(context: Context, message: MessageDocument) {
  const lastRead = context.lastReads.find((l) => l.to === message.from);
  return (
    context.profile.id === message.from ||
    (lastRead !== undefined && lastRead.time.getTime() > message.time.getTime())
  );
}

function getStatus(time: Date, profile: ProfileDocument): ProfileStatus {
  const since = profile.lastOnline
    ? time.getTime() - profile.lastOnline.getTime()
    : Number.POSITIVE_INFINITY;

  const isBelow = since < INACTIVE_THRESHOLD;
  const isBelowDouble = since < INACTIVE_THRESHOLD * 2;

  // Show 2Fik's profiles as online for 10 minutes after their last appearance
  // or always online if they are the moderator
  const isOnline =
    profile.isOnline ||
    (profile.state === '2fik' && (profile.order === -1 || isBelow));

  // Double the amount of time that 2Fik's profiles appear inactive since for the
  // first half of this time they will be appear as online. See above code.
  const isInactive = profile.state === '2fik' ? isBelowDouble : isBelow;

  return isOnline ? 'online' : isInactive ? 'inactive' : 'offline';
}

function getProfileFromInteraction(
  context: Context,
  message: MessageDocument | RankDocument
): Profile | undefined {
  return message.to === context.profile.id
    ? context.profiles.find((p) => p.id === message.from)
    : context.profiles.find((p) => p.id === message.to);
}

function assignToProfile(context: Context, event: LoadProfile) {
  return context.profile.id === event.profile.id
    ? getProfileWithAge(context.time, event.profile)
    : context.profile;
}

function assignToRanks(context: Context, event: LoadRank) {
  const message = {
    ...event.rank,
    profile: getProfileFromInteraction(context, event.rank),
    isRead:
      context.profile.lastRead !== undefined &&
      context.profile.lastRead.getTime() > event.rank.time.getTime(),
  };

  return assignToInteraction<PartialRank>(context.ranks, message);
}

function removeFromRanks(context: Context, event: UnloadRank) {
  return context.ranks.filter((r) => r.id !== event.rank.id);
}

function assignToLastReads(context: Context, event: LoadLastRead) {
  const insertAt = context.lastReads.findIndex(
    (l) => l.id === event.lastRead.id
  );

  return insertAt >= 0
    ? [
        ...context.lastReads.slice(0, insertAt),
        event.lastRead,
        ...context.lastReads.slice(insertAt + 1),
      ]
    : [...context.lastReads, event.lastRead];
}

function rebuildRanks(
  context: Context,
  event: LoadProfile | UnloadProfile
): PartialRank[] {
  return context.ranks.map((r) => {
    return {
      ...r,
      profile: getProfileFromInteraction(context, r),
      isRead:
        context.profile.lastRead !== undefined &&
        context.profile.lastRead.getTime() > r.time.getTime(),
    };
  });
}

function rebuildPresences(
  context: Context,
  event: LoadProfile | UnloadProfile
): Presence[] {
  return context.profiles
    .filter((p) => p.isFavorite)
    .flatMap((profile) =>
      profile.history.slice(-3).map((time) => {
        return {
          profile,
          time,
          isRead:
            context.profile.lastRead !== undefined &&
            context.profile.lastRead.getTime() > time.getTime(),
        };
      })
    );
}

function assignToProfiles(context: Context, event: LoadProfile): Profile[] {
  if (event.profile.id !== context.profile.id) {
    const profile: Profile = {
      ...getProfileWithAge(context.time, event.profile),
      isFavorite: context.profile.favorites.includes(event.profile.id),
      isBlocked: context.profile.blocked.includes(event.profile.id),
      isBlockingYou: event.profile.blocked.includes(context.profile.id),
      isHot: context.ranks.find(
        (r) => r.from === context.profile.id && r.to === event.profile.id
      )?.hot,
      status: getStatus(context.time, event.profile),
    };

    const insertAt = context.profiles.findIndex((p) => p.id === profile.id);
    const randomAt = Math.floor(Math.random() * (context.profiles.length - 1));

    return insertAt >= 0
      ? [
          ...context.profiles.slice(0, insertAt),
          profile,
          ...context.profiles.slice(insertAt + 1),
        ]
      : [
          ...context.profiles.slice(0, randomAt),
          profile,
          ...context.profiles.slice(randomAt),
        ];
  }

  return context.profiles;
}

function removeFromProfiles(context: Context, event: UnloadProfile): Profile[] {
  return context.profiles.filter((p) => p.id !== event.profile.id);
}

function rebuildProfiles(
  context: Context,
  event: LoadProfile | LoadRank | LoadTime | UnloadRank
): Profile[] {
  if (event.type === 'loadProfile') {
    return event.profile.id === context.profile.id
      ? context.profiles.map((p) => {
          return {
            ...getProfileWithAge(context.time, p),
            isFavorite: context.profile.favorites.includes(p.id),
            isBlocked: context.profile.blocked.includes(p.id),
            isBlockingYou: p.blocked.includes(context.profile.id),
            status: getStatus(context.time, p),
          };
        })
      : context.profiles;
  }

  if (event.type === 'loadRank' || event.type === 'unloadRank') {
    return context.profiles.map((p) => {
      return {
        ...p,
        isHot: context.ranks.find(
          (r) => r.from === context.profile.id && r.to === p.id
        )?.hot,
      };
    });
  }

  if (event.type === 'loadTime') {
    return context.profiles.map((p) => {
      return {
        ...p,
        status: getStatus(context.time, p),
        age: getProfileWithAge(context.time, p).age,
      };
    });
  }

  return context.profiles;
}

function assignToMessages(
  context: Context,
  event: LoadMessage
): PartialMessage[] {
  const message = {
    ...event.message,
    isRead: isRead(context, event.message),
    profile: getProfileFromInteraction(context, event.message),
  };

  return assignToInteraction<PartialMessage>(context.messages, message);
}

function rebuildMessages(
  context: Context,
  event: LoadProfile | LoadLastRead | LoadTime | UnloadProfile
): PartialMessage[] {
  if (
    event.type === 'loadProfile' ||
    event.type === 'loadTime' ||
    event.type === 'unloadProfile'
  ) {
    return context.messages.map((m) => {
      return {
        ...m,
        profile: getProfileFromInteraction(context, m),
      };
    });
  }

  if (event.type === 'loadLastRead') {
    return context.messages.map((m) => {
      return {
        ...m,
        isRead: isRead(context, m),
      };
    });
  }

  return context.messages;
}

function assignToInteraction<Item extends PartialRank | PartialMessage>(
  items: Item[],
  item: Item
) {
  // First remove the message if we already have a version of it.
  const newItems = items.filter((i) => item.id !== i.id);

  // Once removal is complete find the new localtion for the message and insert it
  const insertAt = newItems.findIndex(
    (m) => item.time.getTime() < m.time.getTime()
  );

  return insertAt >= 0
    ? [...newItems.slice(0, insertAt), item, ...newItems.slice(insertAt)]
    : [...newItems, item];
}
