import React from 'react';
import './ChatReceipt.scss';
import classNames from 'classnames';

interface ChatReceiptProps {
  className?: string;
  children?: React.ReactNode;
}

export function ChatReceipt(props: ChatReceiptProps) {
  const { className, children } = props;

  return (
    <span className={classNames(className, 'chat-receipt')}>{children}</span>
  );
}
