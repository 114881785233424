import moment from 'moment';
import { EventObject } from 'xstate';
import { ProfileDocument, ProfileWithAge } from '../types';

export const EMAIL_KEY = 'emailForSignIn';

export function assertEventType<
  TE extends EventObject,
  TType extends TE['type']
>(event: TE, eventType: TType): asserts event is TE & { type: TType } {
  if (event.type !== eventType) {
    throw new Error(
      `Invalid event: expected "${eventType}", got "${event.type}"`
    );
  }
}

export function getProfileWithAge(
  time: Date,
  profile: ProfileDocument
): ProfileWithAge {
  return {
    ...profile,
    age:
      profile.born !== undefined
        ? moment(time).diff(profile.born, 'years')
        : NaN,
  };
}

export function withDelay<T>(promise: Promise<T>, delay: number) {
  let done = false;
  let error = false;
  let thrown: any = null;
  let result: T;

  promise
    .then((r) => {
      done = true;
      result = r;
    })
    .catch((e) => {
      error = true;
      thrown = e;
    });

  return new Promise<T>((resolve, reject) => {
    const interval = setInterval(() => {
      if (done) {
        clearInterval(interval);
        resolve(result);
      }

      if (error) {
        clearInterval(interval);
        reject(thrown);
      }
    }, delay);
  });
}
