import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as MagicLinkActor } from '../../machines/MagicLinkMachine';
import Button from '../Button/Button';
import { Feedback } from '../Feedback/Feedback';
import { Form } from '../Form/Form';
import { Fieldset } from '../Form/Fieldset';
import { TextInput } from '../Form/TextInput';
import { Confirmation } from '../Confirmation/Confirmation';
import { Loader } from '../Loader/Loader';

import './SignIn.scss';

export function SignIn({ actor }: { actor: MagicLinkActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  switch (true) {
    case state.matches('idle'):
      return (
        <main className="sign-in">
          <Helmet>
            <title>
              {t('title')} - {t('signIn.title')}
            </title>
          </Helmet>
          <h1 className="heading signin__heading">{t('signIn.title')}</h1>
          <div className="sign-in__description">
            <p>{t('signIn.body')}</p>
          </div>

          <div className="sign-in__form">
            <Form
              className="form__container"
              onSubmit={() => send({ type: 'sendSignInLink' })}
            >
              {state.matches('idle.invalid') && (
                <Feedback className="sign-in__feedback">
                  {t('signIn.form.feedback')}
                </Feedback>
              )}
              <Fieldset label={t('signIn.form.emailLabel')}>
                <TextInput
                  name="email"
                  value={state.context.email}
                  type="email"
                  onChange={(event) =>
                    send({ type: 'setEmail', email: event.target.value })
                  }
                />
              </Fieldset>
              <Button
                className="sign-in__button form__button"
                buttonType="primary"
                type="submit"
              >
                {t('signIn.form.buttonTitle')}
              </Button>
            </Form>
          </div>
        </main>
      );

    case state.matches('sending'):
      return <Loader theme="full-page" />;

    case state.matches('success'):
      return (
        <Confirmation>
          <h1>{t('signIn.success.title')}</h1>
          <p>
            <strong>{t('signIn.success.descriptionInstructionStrong')}</strong>{' '}
            {t('signIn.success.descriptionInstruction')}
          </p>
          <p>{t('signIn.success.descriptionCheck')}</p>
          <Button onClick={() => send({ type: 'done' })}>
            {t('signIn.success.buttonTitle')}
          </Button>
        </Confirmation>
      );

    case state.matches('failure'):
      return (
        <Confirmation>
          <h1>{t('signIn.notSent.title')}</h1>
          <p>{t('signIn.notSent.description')}</p>
          <Button onClick={() => send({ type: 'done' })}>
            {t('signIn.notSent.buttonTitle')}
          </Button>
        </Confirmation>
      );

    default:
      return null;
  }
}
