import { useLayoutEffect } from 'react';

/*
 * Using method outlined here: https://reactrouter.com/web/guides/scroll-restoration
 */
export function ScrollToTop() {
  useLayoutEffect(() => {
    const element = document.getElementsByClassName('app-body').item(0);

    // For desktop we need to scroll the container to the top.
    if (element !== null) {
      element.scrollTop = 0;
    }

    // For mobile we need to scroll the window to the top.
    window.scrollTo(0, 0);
  }, []);

  return null;
}
