import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import { Actor as UploadPhotoActor } from '../../machines/UploadPhotoMachine';
import { Feedback } from '../Feedback/Feedback';
import Button from '../Button/Button';
import 'react-image-crop/lib/ReactCrop.scss';
import './UploadPhoto.scss';

export function UploadPhoto({ actor }: { actor: UploadPhotoActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();
  const isNew = state.context.isNew;
  const isCropping =
    state.matches('crop') ||
    state.matches('cropping') ||
    state.matches('cancelling');

  function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length > 0) {
      send({
        type: 'upload',
        file: event.target.files[0],
      });
    }
  }

  return (
    <div className="upload-photo">
      <h2 className="heading upload-photo__heading">
        {t(
          isCropping ? 'editPhotos.crop.heading' : 'editPhotos.upload.heading'
        )}
      </h2>
      {!isCropping && (
        <p className="intro upload-photo__intro">
          {t(isNew ? 'editProfile.form.photoIntro' : 'editPhotos.upload.intro')}
        </p>
      )}
      {state.matches('ready.error.crop') && (
        <Feedback className="upload-photo__feedback">
          {t('editPhotos.crop.feedback.failed')}
        </Feedback>
      )}
      {state.matches('ready.error.upload') && (
        <Feedback className="upload-photo__feedback">
          {t('editPhotos.upload.feedback.failed')}
        </Feedback>
      )}
      {state.matches('ready.error.size') && (
        <Feedback className="upload-photo__feedback">
          {t('editPhotos.upload.feedback.size')}
        </Feedback>
      )}
      {state.matches('uploading') ? (
        <Button disabled={true} className="upload-photo__input-button">
          {t('editPhotos.crop.feedback.uploading')}
        </Button>
      ) : state.matches('resizing') ? (
        <Button disabled={true} className="upload-photo__input-button">
          {t('editPhotos.crop.feedback.resizing')}
        </Button>
      ) : state.matches('cropping') ? (
        <Button disabled={true} className="upload-photo__input-button">
          {t('editPhotos.crop.feedback.cropping')}
        </Button>
      ) : state.matches('cancelling') ? (
        <Button disabled={true} className="upload-photo__input-button">
          {t('editPhotos.crop.feedback.cancelling')}
        </Button>
      ) : state.matches('crop') ? (
        <>
          <ReactCrop
            src={
              state.context.profile.upload.state === 'ready.crop'
                ? state.context.profile.upload.path
                : ''
            }
            crop={state.context.crop}
            keepSelection={true}
            onChange={(ignore, crop) => send({ type: 'updateCrop', crop })}
            onImageLoaded={(image) => {
              const aspect = image.width / image.height;

              send({
                type: 'updateCrop',
                crop: {
                  aspect: 1,
                  unit: '%',
                  x: aspect > 1 ? 25 + 25 / aspect : 25,
                  y: aspect > 1 ? 25 : 25 + 25 * aspect,
                  width: aspect > 1 ? 50 / aspect : 50,
                  height: aspect > 1 ? 50 : 50 * aspect,
                },
              });

              return false;
            }}
          />
          <div className="upload-photo__buttons">
            <Button
              className="upload-photo__input-button"
              buttonType="primary"
              onClick={() => send({ type: 'crop' })}
            >
              {t('editPhotos.crop.saveButton')}
            </Button>
            <Button
              className="upload-photo__input-button"
              buttonType="secondary"
              onClick={() => send({ type: 'cancel' })}
            >
              {t('editPhotos.crop.cancelButton')}
            </Button>
          </div>
        </>
      ) : (
        <Button buttonType="primary" className="upload-photo__input-button">
          <label htmlFor="photo">{t('editPhotos.upload.selectButton')}</label>
          <input
            id="photo"
            type="file"
            accept="image/*"
            onChange={handleUpload}
            disabled={!state.matches('ready')}
            className="upload-photo__input"
          />
        </Button>
      )}
    </div>
  );
}
