import { EventObject, Sender } from 'xstate';

interface HistoryEvent extends EventObject {
  isHistory?: true;
}

export function watchHistory<Event extends EventObject>(
  id: string,
  send: Sender<Event>
) {
  const handlePopState = (e: PopStateEvent) => {
    if (e.state !== null && e.state.id === id) {
      for (const event of e.state.events) {
        send(event);
      }
    }
  };

  window.addEventListener('popstate', handlePopState);

  return () => window.removeEventListener('popstate', handlePopState);
}

export function hasHistory(id: string) {
  return window.history.state !== null && window.history.state.id === id;
}

export function getHistory(id: string) {
  return window.history.state;
}

export function pushHistory(
  id: string,
  event: HistoryEvent,
  action: 'push' | 'pop' | 'reset' = 'reset'
) {
  if (!event.isHistory) {
    const es =
      window.history.state !== null &&
      Array.isArray(window.history.state.events)
        ? (window.history.state.events as HistoryEvent[])
        : [];

    const events =
      action === 'push'
        ? [...es, { ...event, isHistory: true }]
        : action === 'pop'
        ? es.slice(0, -1)
        : [{ ...event, isHistory: true }];

    const state = { id, events };
    if (window.history.state === null) {
      window.history.replaceState(state, '');
    } else {
      window.history.pushState(state, '');
    }
  }
}
