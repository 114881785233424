import React from 'react';
import './ChatBubble.scss';
import classNames from 'classnames';

interface ChatBubbleProps {
  className?: string;
  children?: React.ReactNode;
  type?: 'reciever' | 'sender';
}

export function ChatBubble(props: ChatBubbleProps) {
  const { className, children, type = 'sender' } = props;

  return (
    <div
      className={classNames(className, 'chat-bubble', {
        'chat-bubble--reciever': type === 'reciever',
        'chat-bubble--sender': type === 'sender',
      })}
    >
      <span className="chat-bubble__message">{children}</span>
    </div>
  );
}
