export enum Gender {
  man = 'man',
  woman = 'woman',
  agender = 'agender',
  androgynous = 'androgynous',
  bigender = 'bigender',
  cisgenderMan = 'cisgenderMan',
  cisgenderWoman = 'cisgenderWoman',
  demiman = 'demiman',
  demiwoman = 'demiwoman',
  genderFluid = 'genderFluid',
  genderNeutral = 'genderNeutral',
  genderNonConforming = 'genderNonConforming',
  genderQuestioning = 'genderQuestioning',
  genderqueer = 'genderqueer',
  hijra = 'hijra',
  intersex = 'intersex',
  intersexMan = 'intersexMan',
  intersexWoman = 'intersexWoman',
  intersexPerson = 'intersexPerson',
  neutrois = 'neutrois',
  nonBinary = 'nonBinary',
  other = 'other',
  pangender = 'pangender',
  polyender = 'polyender',
  thirdGender = 'thirdGender',
  trans = 'trans',
  transMan = 'transMan',
  transPerson = 'transPerson',
  transWoman = 'transWoman',
  transfeminine = 'transfeminine',
  transgender = 'transgender',
  transmasculine = 'transmasculine',
  transsexual = 'transsexual',
  transsexualMan = 'transsexualMan',
  transexualPerson = 'transexualPerson',
  transsexualWoman = 'transsexualWoman',
  twoSpirit = 'twoSpirit',
  // Used when no option has been selected.
  none = 'none',
}

export enum Sexuality {
  allosexual = 'allosexual',
  androsexual = 'androsexual',
  asexual = 'asexual',
  aromantic = 'aromantic',
  bicurious = 'bicurious',
  bisexual = 'bisexual',
  demisexual = 'demisexual',
  fluid = 'fluid',
  gay = 'gay',
  gynesexual = 'gynesexual',
  heterosexual = 'heterosexual',
  homosexual = 'homosexual',
  lesbian = 'lesbian',
  pansexual = 'pansexual',
  sapiosexual = 'sapiosexual',
  straight = 'straight',
  // Used when no option has been selected.
  none = 'none',
}

export type ProfileStatus = 'online' | 'inactive' | 'offline' | 'user';
export type UploadState =
  | {
      state:
        | 'ready.upload'
        | 'ready.error'
        | 'processing.upload'
        | 'processing.resize'
        | 'processing.crop';
    }
  | {
      state: 'ready.crop';
      path: string;
    };

export interface Document {
  readonly id: string;
}

export interface Image {
  id: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
}

export interface ProfileDocument extends Document {
  name: string;
  headline: string;
  who: string;
  why: string;
  how: string;
  what: string;
  when: string;
  else: string;

  images: Image[];
  blocked: string[];
  favorites: string[];
  history: Date[];

  gender: Gender;
  sexuality: Sexuality;
  language: 'en' | 'fr';

  upload: UploadState;

  born?: Date;
  lastRead?: Date;
  lastOnline?: Date;

  state: 'new' | 'user' | '2fik' | 'banned';

  isDead: boolean;
  isOnline: boolean;

  order: number;

  unreadCount: number;
  hotCount: number;
  notCount: number;
}

interface Interaction extends Document {
  to: string;
  from: string;
}

export interface MessageDocument extends Interaction {
  body: string;
  time: Date;
}

export interface TypingDocument extends Interaction {
  typing: boolean;
}

export interface RankDocument extends Interaction {
  hot: boolean;
  time: Date;
}

export interface LastReadDocument extends Interaction {
  time: Date;
}

export interface ConfigDocument extends Document {
  chat: boolean;
}

export interface PartialMessage extends MessageDocument {
  isRead: boolean;
  profile?: Profile;
}

export interface Message extends PartialMessage {
  profile: Profile;
}

export interface PartialRank extends RankDocument {
  isRead: boolean;
  profile?: Profile;
}

export interface Rank extends PartialRank {
  profile: Profile;
}

export interface ProfileWithAge extends ProfileDocument {
  age: number;
}

export interface Profile extends ProfileWithAge {
  isFavorite: boolean;
  isBlocked: boolean;
  isBlockingYou: boolean;
  isHot?: boolean;
  status: ProfileStatus;
}

export interface User {
  id: string;
  is2Fik: boolean;
}

export interface Presence {
  profile: Profile;
  time: Date;
  isRead: boolean;
}

export interface Update {
  profile: Profile;
  time: Date;
  isRead: boolean;
  hot?: boolean;
}
export type Clause =
  | {
      type: 'where';
      fieldName: string;
      fieldValue: string | number;
      operator?: '==' | '!=';
    }
  | { type: 'order'; fieldName: string; direction?: 'desc' | 'asc' }
  | { type: 'limit'; value: number };

export interface Store {
  delay: number;

  sendSignUpLinkToEmail: (email: string, name: string) => Promise<void>;

  sendSignInLinkToEmail: (email: string) => Promise<void>;

  addFile: (path: string, file: File) => Promise<void>;

  crop: (
    profileId: string,
    left: number,
    top: number,
    width: number,
    height: number
  ) => Promise<void>;

  addDocument: (
    collection: string,
    document: Record<symbol, unknown>,
    timeField?: string
  ) => Promise<Document>;

  saveDocument: (
    collection: string,
    document: Document,
    timeField?: string
  ) => Promise<void>;

  updateDocument: (
    collection: string,
    document: Document,
    timeField?: string
  ) => Promise<void>;

  deleteDocument: (collection: string, document: Document) => Promise<void>;

  getProfileDocument: (id: string) => Promise<ProfileDocument>;

  isSignInWithEmailLink: (link: string) => boolean;

  onAuthStateChanged: (callback: (user?: User) => void) => () => void;

  signInWithEmailLink: (email: string, link: string) => Promise<void>;

  signOut: () => Promise<void>;

  setPresence: (profile: ProfileDocument) => () => void;

  watchProfileDocument: (
    id: string,
    callback: (doc: ProfileDocument) => void
  ) => () => void;

  watchTypingDocument: (
    id: string,
    callback: (doc: TypingDocument) => void
  ) => () => void;

  watchLastReadDocument: (
    id: string,
    callback: (doc: LastReadDocument) => void
  ) => () => void;

  watchConfigDocument: (
    id: string,
    callback: (doc: ConfigDocument) => void
  ) => () => void;

  watchProfileDocuments: (
    clauses: Clause[],
    load: (doc: ProfileDocument) => void,
    unload: (doc: ProfileDocument) => void,
    complete: () => void
  ) => () => void;

  watchMessageDocuments: (
    clauses: Clause[],
    load: (doc: MessageDocument) => void,
    unload: (doc: MessageDocument) => void,
    complete: () => void
  ) => () => void;

  watchRankDocuments: (
    clauses: Clause[],

    load: (doc: RankDocument) => void,
    unload: (doc: RankDocument) => void,
    complete: () => void
  ) => () => void;

  watchLastReadDocuments: (
    clauses: Clause[],

    load: (doc: LastReadDocument) => void,
    unload: (doc: LastReadDocument) => void,
    complete: () => void
  ) => () => void;

  addToBlocked: (
    profile: ProfileDocument,
    blocked: ProfileDocument
  ) => Promise<void>;

  addToFavorites: (
    profile: ProfileDocument,
    favortie: ProfileDocument
  ) => Promise<void>;

  removeFromBlocked: (
    profile: ProfileDocument,
    blocked: ProfileDocument
  ) => Promise<void>;

  removeFromFavorites: (
    profile: ProfileDocument,
    favortie: ProfileDocument
  ) => Promise<void>;

  removeFromPhotos: (profile: ProfileDocument, photo: Image) => Promise<void>;

  delete: (token: string) => Promise<void>;
  optOut: (token: string) => Promise<void>;
}
