import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as InboxActor } from '../../machines/InboxMachine';
import { HeaderOverlay } from '../Header/HeaderOverlay';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { Confirmation } from '../Confirmation/Confirmation';
import { Message } from '../../types';
import Button from '../Button/Button';
import './Inbox.scss';

export function Inbox({ actor }: { actor: InboxActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  const favorites = state.context.favorites;
  const messages = state.context.messages;

  function displayItem(message: Message) {
    return (
      <div key={message.profile.id}>
        <NotificationItem
          profileName={message.profile.name}
          profileImage={message.profile.images[0].xs}
          profileFave={message.profile.isFavorite}
          profileBlocked={message.profile.isBlockingYou}
          notifMessage={message.body}
          onClick={() => send({ type: 'viewChat', profile: message.profile })}
          notifDate={t('date.fromNow', { date: message.time })}
          notifType="inbox"
          notifSeen={message.isRead}
        />
      </div>
    );
  }

  return (
    <main className="inbox">
      <Helmet>
        <title>
          {t('title')} - {t('header.chatHeaderTitle')}
        </title>
      </Helmet>
      <HeaderOverlay onClick={() => send({ type: 'closePanel' })}>
        {t('header.chatHeaderTitle')}
      </HeaderOverlay>
      {favorites.length === 0 && messages.length === 0 && (
        <Confirmation className="confirmation-inbox" pageType="full">
          <h1 className="heading confirmation__heading">
            {t('chat.emptyChatTitle')}
          </h1>
          <p>{t('chat.emptyChatDescription')}</p>
          <Button
            className="sign-out__button form__button"
            buttonType="primary"
            onClick={() => send({ type: 'closePanel' })}
          >
            {t('chat.emptyChatButton')}
          </Button>
        </Confirmation>
      )}
      {favorites.length > 0 && favorites.map((m) => displayItem(m))}
      {messages.length > 0 && messages.map((m) => displayItem(m))}
    </main>
  );
}
