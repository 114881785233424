import React from 'react';
import './ExhibitionHeading.scss';
import classNames from 'classnames';
import { Icon, IconName } from '../Icon/Icon';

interface DisplayHeadingProps {
  children?: React.ReactNode;
  className?: string;
  iconName: IconName;
}

export function ExhibitionHeading(props: DisplayHeadingProps) {
  const { children, className, iconName } = props;

  return (
    <header className={classNames(className, 'exhibition-heading')}>
      <Icon size="xl" name={iconName} className="exhibition-heading__icon" />
      <h1 className="exhibition-heading__title">{children}</h1>
    </header>
  );
}
