import { useTranslation } from 'react-i18next';
import { Gender } from '../../types';

export function GenderTag({ gender }: { gender: Gender }) {
  const { t } = useTranslation();

  switch (gender) {
    case Gender.man:
      return <>{t('gender.man')}</>;
    case Gender.woman:
      return <>{t('gender.woman')}</>;
    case Gender.agender:
      return <>{t('gender.agender')}</>;
    case Gender.androgynous:
      return <>{t('gender.androgynous')}</>;
    case Gender.bigender:
      return <>{t('gender.bigender')}</>;
    case Gender.cisgenderMan:
      return <>{t('gender.cisgenderMan')}</>;
    case Gender.cisgenderWoman:
      return <>{t('gender.cisgenderWoman')}</>;
    case Gender.demiman:
      return <>{t('gender.demiman')}</>;
    case Gender.demiwoman:
      return <>{t('gender.demiwoman')}</>;
    case Gender.genderFluid:
      return <>{t('gender.genderFluid')}</>;
    case Gender.genderNeutral:
      return <>{t('gender.genderNeutral')}</>;
    case Gender.genderNonConforming:
      return <>{t('gender.genderNonConforming')}</>;
    case Gender.genderQuestioning:
      return <>{t('gender.genderQuestioning')}</>;
    case Gender.genderqueer:
      return <>{t('gender.genderqueer')}</>;
    case Gender.hijra:
      return <>{t('gender.hijra')}</>;
    case Gender.intersex:
      return <>{t('gender.intersex')}</>;
    case Gender.intersexMan:
      return <>{t('gender.intersexMan')}</>;
    case Gender.intersexWoman:
      return <>{t('gender.intersexWoman')}</>;
    case Gender.intersexPerson:
      return <>{t('gender.intersexPerson')}</>;
    case Gender.neutrois:
      return <>{t('gender.neutrois')}</>;
    case Gender.nonBinary:
      return <>{t('gender.nonBinary')}</>;
    case Gender.other:
      return <>{t('gender.other')}</>;
    case Gender.pangender:
      return <>{t('gender.pangender')}</>;
    case Gender.polyender:
      return <>{t('gender.polyender')}</>;
    case Gender.thirdGender:
      return <>{t('gender.thirdGender')}</>;
    case Gender.trans:
      return <>{t('gender.trans')}</>;
    case Gender.transMan:
      return <>{t('gender.transMan')}</>;
    case Gender.transPerson:
      return <>{t('gender.transPerson')}</>;
    case Gender.transWoman:
      return <>{t('gender.transWoman')}</>;
    case Gender.transfeminine:
      return <>{t('gender.transfeminine')}</>;
    case Gender.transgender:
      return <>{t('gender.transgender')}</>;
    case Gender.transmasculine:
      return <>{t('gender.transmasculine')}</>;
    case Gender.transsexual:
      return <>{t('gender.transsexual')}</>;
    case Gender.transsexualMan:
      return <>{t('gender.transsexualMan')}</>;
    case Gender.transexualPerson:
      return <>{t('gender.transexualPerson')}</>;
    case Gender.transsexualWoman:
      return <>{t('gender.transsexualWoman')}</>;
    case Gender.twoSpirit:
      return <>{t('gender.twoSpirit')}</>;
    case Gender.none:
      return <>{t('gender.none')}</>;
  }
}
