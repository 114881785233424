import React, { Component } from 'react';
import Slider from 'react-slick';
import './Gallery.scss';
import { IconButton } from '../IconButton/IconButton';

interface NavProps {
  onClick?: (e: React.SyntheticEvent) => void;
}

interface GalleryProps {
  betweenCount?: string;
}

function GalleryNext(props: NavProps) {
  const { onClick } = props;
  return (
    <IconButton
      className="gallery__nav gallery--next"
      buttonType="labelless"
      iconName="arrow-right"
      onClick={onClick}
    >
      Next Image
    </IconButton>
  );
}

function GalleryPrev(props: NavProps) {
  const { onClick } = props;
  return (
    <IconButton
      className="gallery__nav gallery--prev"
      buttonType="labelless"
      iconName="arrow-left"
      onClick={onClick}
    >
      Previous Image
    </IconButton>
  );
}

export class Gallery extends Component<GalleryProps> {
  public state = { currentSlide: 0, totalSlides: 0 };

  public handleAfterChange = (index: number) => {
    this.setState({
      currentSlide: index,
    });
  };

  public render() {
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <GalleryNext />,
      prevArrow: <GalleryPrev />,
      afterChange: this.handleAfterChange,
    };
    return (
      <div className="gallery">
        <div className="gallery__count">
          <span className="gallery__count-number">
            {this.state.currentSlide + 1}
          </span>{' '}
          {this.props.betweenCount}{' '}
          <span className="gallery__count-number">
            {React.Children.count(this.props.children)}
          </span>
        </div>
        <Slider {...sliderSettings} className="gallery__slides">
          {this.props.children}
        </Slider>
      </div>
    );
  }
}
