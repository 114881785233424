import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as BrowseActor } from '../../machines/BrowseMachine';
import { Listing } from '../Profile/Listing';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';
import './Browse.scss';

export function Browse({ actor }: { actor: BrowseActor }) {
  const [state, send] = useActor(actor);

  const profile = state.context.profile;
  const profiles = state.context.profiles;
  const { t } = useTranslation();

  return (
    <main className="browse">
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <ScrollToTop />
      <ul className="browse__items">
        <Listing
          name={t('profile.meta.currentUser')}
          status={profile.isDead ? 'offline' : 'user'}
          image={profile.images[0].md}
          onClick={() => send({ type: 'viewSelfie' })}
        />
        {profiles.map((p) => (
          <li className="browse__item" key={p.id}>
            <Listing
              name={p.name}
              image={p.images[0].md}
              status={p.status}
              onClick={() => send({ type: 'viewProfile', profile: p })}
            />
          </li>
        ))}
      </ul>
    </main>
  );
}
