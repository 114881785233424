import { ActorRefFrom, assign, createMachine, sendParent } from 'xstate';
import { ProfileWithAge, Rank, Store, Update } from '../types';
import { assertEventType } from './utils';
import {
  Context as ParentContext,
  UpdateContext,
  ViewProfile,
  ClosePanel,
} from './RomanceMachine';

export function mapParentContextToContext(context: ParentContext): Context {
  const ranks: Update[] = context.ranks
    .filter((r): r is Rank => {
      return r.profile !== undefined;
    })
    .filter((r) => r.to === context.profile.id);

  return {
    profile: context.profile,
    updates: ranks
      .concat(context.presences)
      .sort((a, b) => b.time.getTime() - a.time.getTime()),
  };
}

export interface Context {
  profile: ProfileWithAge;
  updates: Update[];
}

type Event = UpdateContext | ViewProfile | ClosePanel;

const machine = createMachine<Context, Event>(
  {
    id: 'updatesMachine',
    initial: 'waiting',
    states: {
      waiting: {
        // Upon opening Updates wait 2 seconds before marking as read.
        after: {
          2000: [
            { cond: 'hasUnreadUpdates', target: 'saving' },
            { target: 'success' },
          ],
        },
      },
      saving: {
        invoke: {
          id: 'saveLastRead',
          src: 'saveLastRead',
          onDone: 'success',
          onError: 'failure',
        },
      },
      success: {},
      failure: {},
    },
    on: {
      updateContext: {
        target: '.waiting',
        actions: 'assignContext',
      },
      closePanel: { actions: 'sendToParent' },
      viewProfile: { actions: 'sendToParent' },
    },
  },
  {
    guards: {
      hasUnreadUpdates: (context, event) =>
        context.updates.some((u) => !u.isRead),
    },
    actions: {
      sendToParent: sendParent((context, event) => event),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(event.context);
      }),
    },
  }
);

function createServices(store: Store) {
  return {
    saveLastRead: (context: Context) =>
      store.updateDocument('profiles', { id: context.profile.id }, 'lastRead'),
  };
}

export { machine, createServices };

export type Actor = ActorRefFrom<typeof machine>;
