import { useService } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { Romance } from '../Romance/Romance';
import { Prelude } from '../Prelude/Prelude';
import { SignIn } from '../SignIn/SignIn';
import { Showcase } from '../Showcase/Showcase';
import { SignUp } from '../SignUp/SignUp';
import { HeaderMarketing } from '../Header/HeaderMarketing';
import { Form } from '../Form/Form';
import { Fieldset } from '../Form/Fieldset';
import { TextInput } from '../Form/TextInput';
import { Feedback } from '../Feedback/Feedback';
import { Loader } from '../Loader/Loader';
import { Confirmation } from '../Confirmation/Confirmation';
import { OptOut } from '../OptOut/OptOut';
import { TextLink } from '../TextLink/TextLink';
import { Marketing } from '../Marketing/Marketing';
import { Footer } from '../Footer/Footer';
import { FauxneHeader } from '../Header/FauxneHeader';
import { Exhibition } from '../Exhibition/Exhibition';

import { Service as AppService } from '../../machines/AppMachine';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import { Actor as PreludeActor } from '../../machines/PreludeMachine';
import { Actor as MagicLinkActor } from '../../machines/MagicLinkMachine';
import { Actor as OptOutActor } from '../../machines/OptOutMachine';
import { Actor as ExhibitionActor } from '../../machines/ExhibitionMachine';

import './App.scss';

function getOptOutURL(token: string) {
  const url = new URL('/', window.location.origin);
  url.searchParams.append('optOutToken', token);
  return url.toString();
}

export function App({ service }: { service: AppService }) {
  const [state, send] = useService(service);
  const { t, i18n } = useTranslation();
  const time = state.context.time;
  const lang = i18n.language === 'en' ? 'fr' : 'en';

  if (state.matches('showcase')) {
    return <Showcase />;
  }

  if (state.matches('exhibition')) {
    return (
      <Exhibition actor={state.children.exhibitionMachine as ExhibitionActor} />
    );
  }

  switch (true) {
    case state.matches('init'):
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <Loader isAnimated={false} theme="full-page" />
          </div>
        </div>
      );

    case state.matches('signingIn'):
    case state.matches('signingOut'):
    case state.matches('delete.deleting'):
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <Loader theme="full-page" />
          </div>
        </div>
      );

    case state.matches('prelude'):
      return <Prelude actor={state.children.preludeMachine as PreludeActor} />;

    case state.matches('romance'):
      return <Romance actor={state.children.romanceMachine as RomanceActor} />;

    default:
      return (
        <div className="app-frame">
          <div className="app-body">
            <FauxneHeader time={time} />
            <HeaderMarketing
              homeClick={() => send({ type: 'viewStart' })}
              signInClick={() => send({ type: 'viewSignIn' })}
              signUpClick={() => send({ type: 'viewSignUp' })}
            />
            {state.matches('start') && (
              <Marketing
                signInClick={() => send({ type: 'viewSignIn' })}
                signUpClick={() => send({ type: 'viewSignUp' })}
              />
            )}
            {state.matches('signInFailure') && (
              <Confirmation
                pageType="default"
                className="sign-in sign-in--failure"
              >
                <h1>{t('signIn.failure.title')}</h1>
                <p>{t('signIn.failure.description')}</p>
                <Button
                  buttonType="secondary"
                  onClick={() => send({ type: 'viewStart' })}
                >
                  {t('signIn.failure.buttonTitle')}
                </Button>
              </Confirmation>
            )}
            {state.matches('signOutSuccess') && (
              <Confirmation
                className="sign-out sign-out--success"
                pageType="default"
              >
                <h1 className="heading sign-out">{t('signOut.title')}</h1>
                <p>{t('signOut.description')}</p>
                <Button
                  className="sign-out__button form__button"
                  buttonType="primary"
                  onClick={() => send({ type: 'viewSignIn' })}
                >
                  {t('signOut.buttonTitle')}
                </Button>
              </Confirmation>
            )}
            {state.matches('signOutFailure') && (
              <Confirmation
                pageType="default"
                className="sign-out sign-out--failure"
              >
                <h1>{t('signOut.failure.title')}</h1>
                <p>{t('signOut.failure.description')}</p>
                <Button
                  buttonType="secondary"
                  onClick={() => send({ type: 'viewStart' })}
                >
                  {t('signOut.buttonTitle')}
                </Button>
              </Confirmation>
            )}
            {state.matches('confirmEmail') && (
              <Confirmation
                pageType="default"
                className="sign-up--confirm-email"
              >
                <h1>{t('signUp.confirmEmail.title')}</h1>
                <p>{t('signUp.confirmEmail.description')}</p>
                <Form
                  className="form__container"
                  onSubmit={() => send({ type: 'signIn' })}
                >
                  {state.matches('confirmEmail.invalid') && (
                    <Feedback className="sign-in__feedback">
                      {t('signUp.form.feedback')}
                    </Feedback>
                  )}
                  <Fieldset
                    label={t('signUp.confirmEmail.form.inputLabel')}
                    labelFor="email"
                  >
                    <TextInput
                      id="email"
                      value={state.context.email}
                      type="email"
                      onChange={(event) =>
                        send({ type: 'setEmail', email: event.target.value })
                      }
                    />
                  </Fieldset>
                  <p>{t('signUp.confirmEmail.descriptionCheck')}</p>
                  <Button
                    className="sign-in__button form__button"
                    buttonType="primary"
                    onClick={() => send({ type: 'signIn' })}
                  >
                    {t('signUp.confirmEmail.form.buttonTitle')}
                  </Button>
                </Form>
                {state.context.optOutToken && (
                  <div className="confirmation__opt-out">
                    <p>
                      <strong> {t('signUp.confirmation.optOutStrong')} </strong>
                      {t('signUp.confirmation.optOutDescription')}
                    </p>
                    <TextLink href={getOptOutURL(state.context.optOutToken)}>
                      {t('signUp.confirmation.optOut')}
                    </TextLink>
                  </div>
                )}
              </Confirmation>
            )}
            {state.matches('signIn') && (
              <SignIn actor={state.children.signInMachine as MagicLinkActor} />
            )}
            {state.matches('signUp') && (
              <SignUp actor={state.children.signUpMachine as MagicLinkActor} />
            )}
            {state.matches('delete.done') && (
              <Confirmation className="delete-profile delete-profile--confirmation">
                <h1>{t('deleteProfile.success.title')}</h1>
                <p>{t('deleteProfile.success.intro')}</p>
                <Button
                  buttonType="secondary"
                  onClick={() => send({ type: 'viewStart' })}
                >
                  {t('deleteProfile.confirmed.buttonBack')}
                </Button>
              </Confirmation>
            )}
            {state.matches('delete.failure') && (
              <Confirmation className="delete-profile delete-profile--failure">
                <h1>{t('deleteProfile.success.errorTitle')}</h1>
                <p>{t('deleteProfile.success.error')}</p>
                <Button
                  buttonType="secondary"
                  onClick={() => send({ type: 'viewStart' })}
                >
                  {t('deleteProfile.confirmed.buttonBack')}
                </Button>
              </Confirmation>
            )}
            {state.matches('optOut') && (
              <OptOut actor={state.children.optOutMachine as OptOutActor} />
            )}
            <Footer
              time={time}
              language={lang}
              languageClick={() => i18n.changeLanguage(lang)}
            />
          </div>
        </div>
      );
  }
}
