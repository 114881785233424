import React from 'react';
import './Persona.scss';
import classNames from 'classnames';
import { Gender, Sexuality } from '../../types';
import { Tag } from '../Tag/Tag';
import { GenderTag } from '../GenderTag/GenderTag';
import { SexualityTag } from '../SexualityTag/SexualityTag';

interface PersonaProps {
  name: string;
  image: string;
  age?: number;
  gender?: Gender;
  sexuality?: Sexuality;
  className?: string;
  tagIcon?: any;
  tagContent?: any;
  frequency?: number;
  onClick?: (e: React.SyntheticEvent) => void;
}

export function Persona(props: PersonaProps) {
  const {
    name,
    image,
    age,
    gender,
    sexuality,
    className,
    tagIcon,
    tagContent,
    frequency,
    onClick,
  } = props;

  return (
    <button onClick={onClick} className={classNames(className, 'persona')}>
      <img className="persona__image" src={image} alt={name} />
      <div className="persona__details">
        <div className="persona__label">
          {frequency !== undefined && frequency >= 0 && (
            <span className={'persona__frequency frequency--' + frequency}>
              {frequency}x
            </span>
          )}
          <h2 className="persona__title">{name}</h2>
        </div>
        <h3 className="persona__description">
          {age && <span>{age}</span>}
          {gender && (
            <span>
              <GenderTag gender={gender} />
            </span>
          )}
          {sexuality && (
            <span>
              <SexualityTag sexuality={sexuality} />
            </span>
          )}
        </h3>
        {tagIcon ? (
          <Tag iconSize="sm" iconName={tagIcon}>
            {tagContent}
          </Tag>
        ) : (
          tagContent && <Tag iconSize="sm">{tagContent}</Tag>
        )}
      </div>
    </button>
  );
}
