import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import MarketingImage from '../../images/marketing-app.png';
import { Logo } from '../Logo/Logo';
import Button from '../Button/Button';
import { ButtonClick } from '../Button/Button';

import './Marketing.scss';

interface MarketingProps {
  className?: string;
  signUpClick?: ButtonClick;
  signInClick?: ButtonClick;
}

export function Marketing(props: MarketingProps) {
  const { className, signUpClick, signInClick } = props;
  const { t } = useTranslation();

  return (
    <main className={classnames('marketing-screen', className)}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="marketing-screen__logo">
        <Logo logoSize="lg" logoType="typeform" />
      </div>
      <p className="marketing-screen__description">
        {t('marketing.description')}
      </p>
      <Button
        className="marketing-screen__button"
        onClick={signUpClick}
        buttonType="primary"
      >
        {t('marketing.button')}
      </Button>
      <p className="intro marekting-screen__link-description">
        {t('marketing.label')}
      </p>
      <Button
        buttonType="stripped"
        onClick={signInClick}
        className="marketing-screen__link"
      >
        {t('marketing.link')}
      </Button>
      <img
        className="marketing-screen__image"
        src={MarketingImage}
        alt="Meow"
      />
    </main>
  );
}
