import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import Button from '../Button/Button';
import { Confirmation } from '../Confirmation/Confirmation';

export function SignInReminder({ actor }: { actor: RomanceActor }) {
  const [, send] = useActor(actor);
  const { t } = useTranslation();

  return (
    <main className="signedIn">
      <Confirmation pageType="default">
        <h1 className="heading singnedIn__title">
          {t('signIn.signedIn.title')}
        </h1>
        <p className="signedIn__description">
          {t('signIn.signedIn.description')}
        </p>
        <Button
          className="sign-in__button form__button"
          buttonType="primary"
          onClick={() => send({ type: 'viewReminder' })}
        >
          {t('signIn.signedIn.buttonTitle')}
        </Button>
      </Confirmation>
    </main>
  );
}
