import React from 'react';
import classnames from 'classnames';

import './OptionInput.scss';

export type InputType = 'checkbox' | 'radio';
export type InputName = string;
export type InputValue = string | number;
export type InputChecked = boolean;
export type InputDisabled = boolean;

interface OptionProps {
  type?: InputType;
  name: InputName;
  value: InputValue;
  checked: InputChecked;
  disabled?: InputDisabled;
  children?: React.ReactNode;
  onClick?: (value: string | number) => void;
}

export function OptionInput({
  type = 'checkbox',
  children,
  checked,
  name,
  value,
  onClick,
  disabled = false,
}: OptionProps) {
  const id = `radio-${name}-${value}`.replace(/\s+/g, '-');
  return (
    <div className="form__item">
      <input
        type={type}
        className={classnames('form__option', {
          'form--checkbox': type === 'checkbox',
          'form--radio': type === 'radio',
        })}
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e: React.SyntheticEvent) => {
          if (!disabled && typeof onClick === 'function') {
            onClick(value);
          }
        }}
      />
      <label
        htmlFor={id}
        className={classnames('form__item-label', {
          'form--checked': checked,
          'form--disabled': disabled,
        })}
      >
        {children}
      </label>
    </div>
  );
}
