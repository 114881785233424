import React from 'react';
import classNames from 'classnames';
import { Icon, IconName } from '../Icon/Icon';
import './Feedback.scss';

interface FeedbackProps {
  className?: string;
  iconName?: IconName;
  children: React.ReactNode;
  msgType?: 'default' | 'strong';
}

export function Feedback(props: FeedbackProps) {
  const { className, iconName, children, msgType = 'default' } = props;

  return (
    <div
      className={classNames(className, 'feedback', {
        'feedback--strong': msgType === 'strong',
      })}
    >
      {iconName && (
        <Icon
          name={iconName}
          size="md"
          theme="glow"
          className="feedback__icon"
        />
      )}
      {children}
    </div>
  );
}
