import { createMachine, assign, sendParent, ActorRefFrom } from 'xstate';
import { Message } from '../types';
import { assertEventType } from './utils';

import {
  Context as RomanceContext,
  UpdateContext,
  ViewChat,
  ClosePanel,
} from './RomanceMachine';

export function mapParentContextToContext(context: RomanceContext): Context {
  const messages = context.messages
    .filter((m): m is Message => {
      return m.profile !== undefined;
    })
    .reduce<Message[]>((ms, message) => {
      const profileId =
        message.to === context.profile.id ? message.from : message.to;

      const removeAt = ms.findIndex(
        (m) => m.to === profileId || m.from === profileId
      );

      return removeAt >= 0
        ? [...ms.slice(0, removeAt), ...ms.slice(removeAt + 1), message]
        : [...ms, message];
    }, [])
    .reverse();

  return {
    messages: messages.filter(
      (m) => !m.profile.isBlocked && !m.profile.isFavorite
    ),
    favorites: messages.filter(
      (m) => !m.profile.isBlocked && m.profile.isFavorite
    ),
  };
}

export interface Context {
  favorites: Message[];
  messages: Message[];
}

type Events = UpdateContext | ViewChat | ClosePanel;

export const machine = createMachine<Context, Events>(
  {
    id: 'inboxMachine',
    initial: 'ready',
    states: {
      ready: {
        on: {
          closePanel: { actions: 'sendToParent' },
          viewChat: { actions: 'sendToParent' },
          updateContext: {
            actions: 'assignContext',
          },
        },
      },
    },
  },
  {
    actions: {
      sendToParent: sendParent((context, event) => event),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(event.context);
      }),
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;
