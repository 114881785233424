import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Actor as RomanceActor } from '../../machines/RomanceMachine';
import Button from '../Button/Button';
import { Confirmation } from '../Confirmation/Confirmation';
import { TextLink } from '../TextLink/TextLink';

import './Reminder.scss';

export function Reminder({ actor }: { actor: RomanceActor }) {
  const [, send] = useActor(actor);
  const { t } = useTranslation();

  return (
    <Confirmation pageType="full">
      <h1 className="heading">{t('reminder.titleSignIn')}</h1>
      <p>
        <strong>{t('reminder.bodySignIn1')}</strong>
      </p>
      <p>{t('reminder.bodySignIn2')}</p>
      <TextLink
        className="reminder-body__strong"
        href={t('reminder.bodyLink')}
        target="_blank"
      >
        {t('reminder.bodySignInStrong')}
      </TextLink>
      <p>{t('reminder.bodySignIn3')}</p>
      <Button
        buttonType="primary"
        onClick={() => send({ type: 'viewHistory' })}
      >
        {t('reminder.buttonTitle')}
      </Button>
    </Confirmation>
  );
}
