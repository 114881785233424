import { createMachine, assign, ActorRefFrom, sendParent } from 'xstate';
import { Profile, ProfileWithAge, Store } from '../types';
import { assertEventType, withDelay } from './utils';

import {
  Context as RomanceContext,
  UpdateContext,
  ViewChat,
} from './RomanceMachine';

export function mapParentContextToContext(
  context: RomanceContext,
  profile: Profile
): Context {
  return {
    hasChat: context.config.chat,
    is2Fik: context.user.is2Fik,
    from: context.profile,
    profile,
  };
}

export interface Context {
  hasChat: boolean;
  is2Fik: boolean;
  from: ProfileWithAge;
  profile: Profile;
}

interface AddBlock {
  type: 'addBlock';
}

interface RemoveBlock {
  type: 'removeBlock';
}

interface AddFavorite {
  type: 'addFavorite';
}

interface RemoveFavorite {
  type: 'removeFavorite';
}

interface SaveHot {
  type: 'saveHot';
}

interface SaveNot {
  type: 'saveNot';
}

interface DeleteRank {
  type: 'deleteRank';
}

interface SaveBan {
  type: 'saveBan';
}

type Event =
  | ViewChat
  | UpdateContext
  | SaveHot
  | SaveNot
  | DeleteRank
  | AddBlock
  | RemoveBlock
  | AddFavorite
  | RemoveFavorite
  | SaveBan;

export const machine = createMachine<Context, Event>(
  {
    id: 'profileMachine',
    type: 'parallel',
    states: {
      rank: {
        initial: 'idle',
        states: {
          idle: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              saveHot: 'saving',
              saveNot: 'saving',
              deleteRank: 'deleting',
            },
          },
          saving: {
            invoke: {
              id: 'saveRank',
              src: 'saveRank',
              onDone: 'idle.valid',
              onError: 'idle.invalid',
            },
          },
          deleting: {
            invoke: {
              id: 'deleteRank',
              src: 'deleteRank',
              onDone: 'idle.valid',
              onError: 'idle.invalid',
            },
          },
        },
      },
      favorite: {
        initial: 'idle',
        states: {
          idle: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              addFavorite: 'saving',
              removeFavorite: 'saving',
            },
          },
          saving: {
            invoke: {
              id: 'saveFavorite',
              src: 'saveFavorite',
              onDone: 'idle.valid',
              onError: 'idle.invalid',
            },
          },
        },
      },
      block: {
        initial: 'idle',
        states: {
          idle: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              addBlock: 'saving',
              removeBlock: 'saving',
            },
          },
          saving: {
            invoke: {
              id: 'saveBlock',
              src: 'saveBlock',
              onDone: 'idle.valid',
              onError: 'idle.invalid',
            },
          },
        },
      },
      ban: {
        initial: 'idle',
        states: {
          idle: {
            initial: 'valid',
            states: {
              valid: {},
              invalid: {},
            },
            on: {
              saveBan: 'saving',
            },
          },
          saving: {
            invoke: {
              id: 'saveBan',
              src: 'saveBan',
              onDone: 'done',
              onError: 'idle.invalid',
            },
          },
          done: {},
        },
      },
    },
    on: {
      viewChat: {
        cond: 'isAlive',
        actions: 'viewChat',
      },
      updateContext: {
        actions: 'assignContext',
      },
    },
  },
  {
    actions: {
      viewChat: sendParent((context, event) => event),
      assignContext: assign((context, event) => {
        assertEventType(event, 'updateContext');
        return mapParentContextToContext(
          event.context,
          event.context.profiles.find((p) => p.id === context.profile.id) ||
            context.profile
        );
      }),
    },
    guards: {
      isAlive: (context, event) => {
        assertEventType(event, 'viewChat');
        return !event.profile.isDead;
      },
    },
  }
);

export function createServices(store: Store) {
  return {
    saveRank: (context: Context, event: Event) => {
      let hot = false;

      if (event.type === 'saveHot') {
        hot = true;
      } else {
        assertEventType(event, 'saveNot');
      }

      const doc = {
        id: `${context.from.id}_${context.profile.id}`,
        to: context.profile.id,
        from: context.from.id,
        hot,
      };

      return withDelay(store.saveDocument('ranks', doc, 'time'), store.delay);
    },
    deleteRank: (context: Context, event: Event) => {
      assertEventType(event, 'deleteRank');

      return withDelay(
        store.deleteDocument('ranks', {
          id: `${context.from.id}_${context.profile.id}`,
        }),
        store.delay
      );
    },
    saveFavorite: (context: Context, event: Event) => {
      if (event.type === 'addFavorite') {
        return withDelay(
          store.addToFavorites(context.from, context.profile),
          store.delay
        );
      }

      assertEventType(event, 'removeFavorite');
      return withDelay(
        store.removeFromFavorites(context.from, context.profile),
        store.delay
      );
    },
    saveBlock: (context: Context, event: Event) => {
      if (event.type === 'addBlock') {
        return withDelay(
          store.addToBlocked(context.from, context.profile),
          store.delay
        );
      }

      assertEventType(event, 'removeBlock');
      return withDelay(
        store.removeFromBlocked(context.from, context.profile),
        store.delay
      );
    },
    saveBan: (context: Context, event: Event) => {
      assertEventType(event, 'saveBan');

      return store.saveDocument('bans', {
        id: context.profile.id,
      });
    },
  };
}

export type Actor = ActorRefFrom<typeof machine>;
