import React from 'react';
import './Tag.scss';
import classNames from 'classnames';
import { Icon, IconName, IconSize, IconTheme } from '../Icon/Icon';

interface TagProps {
  className?: string;
  children: React.ReactNode;
  iconName?: IconName;
  iconSize?: IconSize;
  iconTheme?: IconTheme;
}

export function Tag(props: TagProps) {
  const { className, children, iconName, iconSize, iconTheme } = props;

  return (
    <div
      className={classNames(className, 'tag', {
        'tag-with-icon': iconName,
      })}
    >
      {iconName && (
        <Icon
          className="tag__icon"
          size={iconSize}
          name={iconName}
          theme={iconTheme}
        />
      )}
      {children}
    </div>
  );
}
