import React from 'react';
import './ChatLoader.scss';
import { ChatBubble } from '../Chat/ChatBubble';
import { ReactComponent as LoaderDots } from '../../images/loader/dots.svg';

export function ChatLoader() {
  return (
    <ChatBubble type="reciever" className="chat-bubble--loader">
      <LoaderDots className="chat-loader" />
    </ChatBubble>
  );
}
