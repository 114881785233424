import { createMachine, assign, sendParent, ActorRefFrom } from 'xstate';
import { assertEventType } from './utils';
import { Profile, ProfileWithAge, Store } from '../types';

import {
  Context as ParentContext,
  UpdateContext,
  ViewProfile,
} from './RomanceMachine';

export function mapParentContextToBlockedContext(
  context: ParentContext
): Context {
  return {
    profile: context.profile,
    profiles: context.profiles.filter((p) => p.isBlocked),
  };
}

export function mapParentContextToFavoritesContext(
  context: ParentContext
): Context {
  return {
    profile: context.profile,
    profiles: context.profiles.filter((p) => p.isFavorite),
  };
}

interface Context {
  profile: ProfileWithAge;
  profiles: Profile[];
}

interface RemoveProfile {
  type: 'removeProfile';
  profile: Profile;
}

type Event = UpdateContext | RemoveProfile | ViewProfile;

export const machine = createMachine<Context, Event>(
  {
    id: 'profileListMachine',
    initial: 'idle',
    states: {
      idle: {
        initial: 'valid',
        states: {
          valid: {},
          invalid: {},
        },
        on: {
          removeProfile: 'removing',
        },
      },
      removing: {
        invoke: {
          id: 'removeProfile',
          src: 'removeProfile',
          onDone: 'idle',
          onError: 'idle.invalid',
        },
      },
    },
    on: {
      updateContext: {
        actions: 'assignContext',
      },
      viewProfile: {
        actions: 'sendToParent',
      },
    },
  },
  {
    actions: {
      sendToParent: sendParent((context, event) => event),
    },
  }
);

export type Actor = ActorRefFrom<typeof machine>;

export function createBlockedActions() {
  return {
    assignContext: assign((context: Context, event: Event) => {
      assertEventType(event, 'updateContext');
      return mapParentContextToBlockedContext(event.context);
    }),
  };
}

export function createFavoriteActions() {
  return {
    assignContext: assign((context: Context, event: Event) => {
      assertEventType(event, 'updateContext');
      return mapParentContextToFavoritesContext(event.context);
    }),
  };
}

export function createBlockedServices(store: Store) {
  return {
    removeProfile: (context: Context, event: Event) => {
      assertEventType(event, 'removeProfile');
      return store.removeFromBlocked(context.profile, event.profile);
    },
  };
}

export function createFavoritesServices(store: Store) {
  return {
    removeProfile: (context: Context, event: Event) => {
      assertEventType(event, 'removeProfile');
      return store.removeFromFavorites(context.profile, event.profile);
    },
  };
}
