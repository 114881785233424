import React from 'react';
import './ChatMeta.scss';
import classNames from 'classnames';

interface ChatMetaProps {
  className?: string;
  children?: React.ReactNode;
}

export function ChatMeta(props: ChatMetaProps) {
  const { className, children } = props;

  return <h3 className={classNames(className, 'chat-meta')}>{children}</h3>;
}
