import React from 'react';
import classNames from 'classnames';
import { Icon, IconName } from '../Icon/Icon';

import './ChatAlert.scss';

interface ChatAlertProps {
  className?: string;
  iconName?: IconName;
  alertTitle?: string;
  alertDescription?: string;
}

export function ChatAlert(props: ChatAlertProps) {
  const { className, iconName, alertTitle, alertDescription } = props;

  return (
    <div className={classNames(className, 'chat-alert')}>
      <Icon
        size="md"
        name={iconName}
        theme="glow"
        className="chat-alert__icon"
      />
      <p className="chat-alert__title">
        {alertDescription}
        <span className="chat-alert__title-strong">&nbsp;{alertTitle}</span>
      </p>
    </div>
  );
}
