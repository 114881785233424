import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/fr';
import { initReactI18next } from 'react-i18next';
import { resources } from './translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fr',
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (
          value instanceof Date &&
          format === 'fromNow' &&
          lng !== undefined
        ) {
          return moment(value).locale(lng).fromNow();
        }

        if (
          value instanceof Date &&
          format === 'lastLogin' &&
          lng !== undefined
        ) {
          return moment(value).locale(lng).format('L');
        }

        if (value instanceof Date && format === 'chat' && lng !== undefined) {
          return lng === 'fr'
            ? moment(value).locale(lng).format('dddd Do MMMM')
            : moment(value).locale(lng).format('dddd, MMMM Do');
        }

        if (value instanceof Date && format === 'time' && lng !== undefined) {
          return moment(value).locale(lng).format('LT');
        }

        if (format === 'possessive') {
          const name = String(value);

          return name.charAt(name.length - 1) === 's'
            ? `${name}’`
            : `${name}’s`;
        }

        return value;
      },
    },
  });
