import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import './HeaderApp.scss';

import { Logo } from '../Logo/Logo';
import Button from '../Button/Button';
import {
  IconButton,
  IconButtonTheme,
  IconButtonClick,
} from '../IconButton/IconButton';

interface HeaderAppProps {
  children?: React.ReactNode;
  className?: string;
  homeClick?: IconButtonClick;
  accountClick?: IconButtonClick;
  chatState?: IconButtonTheme;
  chatClick?: IconButtonClick;
  updateState?: IconButtonTheme;
  updateClick?: IconButtonClick;
}

export function HeaderApp({
  children,
  className,
  homeClick,
  accountClick,
  chatState = 'notification',
  chatClick,
  updateState = 'notification',
  updateClick,
}: HeaderAppProps) {
  const { t } = useTranslation();

  return (
    <header
      className={classnames('header-app__container header--sticky', className)}
    >
      {children}
      <div className="header-app">
        <Button
          buttonType="stripped"
          className="header-app__logo"
          onClick={homeClick}
        >
          <Logo />
        </Button>
        {(accountClick || chatClick || updateClick) && (
          <nav className="header-app__nav">
            {accountClick && (
              <IconButton
                buttonType="nav"
                iconName="user"
                className="header-app__nav-item"
                onClick={accountClick}
              >
                {t('header.account')}
              </IconButton>
            )}
            {chatClick && (
              <IconButton
                buttonType="nav"
                iconName="chat"
                iconTheme={chatState}
                className="header-app__nav-item"
                onClick={chatClick}
              >
                {t('header.chat')}
              </IconButton>
            )}
            {updateClick && (
              <IconButton
                buttonType="nav"
                iconName="notifications"
                iconTheme={updateState}
                className="header-app__nav-item"
                onClick={updateClick}
              >
                {t('header.updates')}
              </IconButton>
            )}
          </nav>
        )}
      </div>
    </header>
  );
}
