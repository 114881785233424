import React from 'react';
import './TextLink.scss';
import classNames from 'classnames';

interface TextLinkProps {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  target?: string;
}

export function TextLink(props: TextLinkProps) {
  const { children, className, href, target } = props;

  return (
    <a
      href={href}
      target={target}
      className={classNames(className, 'text-link')}
    >
      {children}
    </a>
  );
}
