import { useTranslation } from 'react-i18next';
import { ReactComponent as FauxneNotch } from '../../images/fauxne/notch.svg';
import { ReactComponent as FauxneService } from '../../images/fauxne/service.svg';

import './FauxneHeader.scss';

interface FauxneHeaderProps {
  time: Date;
}

export function FauxneHeader({ time }: FauxneHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="fauxne__header">
      <div className="fauxne__time">{t('date.time', { date: time })}</div>
      <FauxneNotch className="fauxne__notch" />
      <div className="fauxne__network">
        Cupid <FauxneService className="fauxne__service" />
      </div>
    </div>
  );
}
