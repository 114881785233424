import classNames from 'classnames';
import './ChatInput.scss';
import { Form } from '../Form/Form';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';

interface ChatInputProps {
  children?: React.ReactNode;
  disabled?: boolean;
  onSubmit?: (e: React.SyntheticEvent) => void;
  className?: string;
}

export function ChatInput(props: ChatInputProps) {
  const { children, disabled, onSubmit, className } = props;

  return (
    <Form
      className={classNames(className, {
        [`chat-input__blocked`]: !!disabled,
      })}
      onSubmit={onSubmit}
    >
      <fieldset className="chat-input">
        <Icon name="chat" size="lg" className="chat-input__icon" />
        {children}
        <IconButton
          type="submit"
          buttonType="labelless"
          iconName="arrow-right"
          className="chat-input__button"
          disabled={disabled}
        />
      </fieldset>
    </Form>
  );
}
