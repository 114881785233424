import React from 'react';
import classNames from 'classnames';
import './Form.scss';

interface FieldsetProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  labelFor?: string;
  hint?: string;
}

export function Fieldset({
  children,
  className,
  labelFor,
  label,
  hint,
}: FieldsetProps) {
  return (
    <fieldset
      className={classNames(className, 'form__fieldset', {
        'form--hint': hint,
      })}
    >
      <div className="form__label-wrapper">
        <label className="form__label" htmlFor={labelFor}>
          {label}
        </label>
        {hint && <span className="form__hint">{hint}</span>}
      </div>
      {children}
    </fieldset>
  );
}
