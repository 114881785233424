import classNames from 'classnames';

import './TextInput.scss';
import './TextArea.scss';

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
}

export function TextArea({
  className,
  id,
  name,
  value,
  onChange,
}: TextAreaProps) {
  return (
    <textarea
      id={id}
      className={classNames(className, 'form__text-input')}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
}
