import { useTranslation } from 'react-i18next';
import { TextLink } from '../TextLink/TextLink';
import Button from '../Button/Button';
import { ButtonClick } from '../Button/Button';

import './Footer.scss';

interface FooterProps {
  time: Date;
  languageClick?: ButtonClick;
  language?: string;
}

export function Footer({ time, languageClick, language }: FooterProps) {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <span className="footer__copyright">
        &copy; {time.getFullYear()} 2Fik
      </span>
      <div className="footer__language-link">
        <Button
          onClick={languageClick}
          buttonType="stripped"
          className="text-link"
        >
          {language}
        </Button>
      </div>
      <TextLink href={t('footer.link')}>{t('footer.what')}</TextLink>
    </footer>
  );
}
