import { Translation } from './type';

export const fr: Translation = {
  title: 'Romance à la 2Fik',
  date: {
    fromNow: '{{date, fromNow}}',
    lastLogin: '{{date, lastLogin}}',
    time: '{{date, time}}',
    chat: '{{date, chat}}',
    read: 'Lu à {{date, time}}',
  },
  gender: {
    man: 'Homme',
    woman: 'Femme',
    agender: 'Agenre',
    androgynous: 'Androgyne',
    bigender: 'Bigenre',
    cisgenderMan: 'Homme Cisgenre',
    cisgenderWoman: 'Femme Cisgenre',
    demiman: 'Demihomme',
    demiwoman: 'Demifemme',
    genderFluid: 'Fluide',
    genderNeutral: 'Neutre',
    genderNonConforming: 'Non Conforme',
    genderQuestioning: 'Genre en questionnement',
    genderqueer: 'Queer',
    hijra: 'Hijra',
    intersex: 'Intersexe',
    intersexMan: 'Homme intersexe',
    intersexWoman: 'Femme intersexe',
    intersexPerson: 'Personne Intersexe',
    neutrois: 'Neutrois',
    nonBinary: 'Non-Binaire',
    other: 'Autre',
    pangender: 'Pangenre',
    polyender: 'Polygenre',
    thirdGender: 'Troisième Genre',
    trans: 'Trans',
    transMan: 'Homme Trans',
    transPerson: 'Personne Trans',
    transWoman: 'Femme Trans',
    transfeminine: 'Transféminin-e',
    transgender: 'Transgenre',
    transmasculine: 'Transmasculin-e',
    transsexual: 'Transsexuel-le',
    transsexualMan: 'Homme Transsexuel',
    transexualPerson: 'Personne Transsexuelle',
    transsexualWoman: 'Femme Transsexuelle',
    twoSpirit: 'Bispirituel-le',
    none: 'Rien',
  },
  sexuality: {
    allosexual: 'Allosexuel-le',
    androsexual: 'Androsexuel-le',
    asexual: 'Asexuel-le',
    aromantic: 'Aromantique',
    bicurious: 'Bicurieux-se',
    bisexual: 'Bisexuel-le',
    demisexual: 'Demisexuel-le',
    fluid: 'Fluide',
    gay: 'Gay',
    gynesexual: 'Gynesexuel-le',
    heterosexual: 'Hétérosexuel-le',
    homosexual: 'Homosexuel-le',
    lesbian: 'Lesbienne',
    pansexual: 'Pansexuel-le',
    sapiosexual: 'Sapiosexuel-le',
    straight: 'Hétéro',
    none: 'Rien',
  },
  marketing: {
    description: 'Rencontre des célibataires près de chez toi.',
    button: 'Abonne-toi maintenant',
    label: 'Déjà abonné?',
    link: 'Connexion',
  },
  prelude: {
    title: 'Bonjour 2Fik',
    description: 'Choisis un personnage pour intéragir avec les utilisateurs.',
  },
  alert: {
    persona: 'Vous interagissez en tant que {{name}}',
    dead: 'Profil {{name}} décédé. Ne pas modifier!',
  },
  optOut: {
    title: 'Se désabonner?',
    description:
      'Si tu n’as pas créé de compte et que tu souhaites te désengager et ne plus recevoir d’e-mails de notre part, svp confirmer ci-dessous.',
    confirmationButtonTitle: 'Oui, je pars.',
    nevermindButtonTitle: 'Je change d’idée',
    homeButtonTitle: 'Retour',

    confirm: {
      title: 'Tu t’es désinscrit de ce compte',
      description: {
        paragraph1:
          'Si tu souhaites créer un compte à l’avenir, tu devras utiliser une autre adresse électronique.',
        paragraph2: 'Si non, tu n’entendras plus jamais parler de nous!',
        paragraph3: 'Bye!',
      },
    },
    error: {
      title: 'Échec du désengagement',
      description: {
        paragraph1:
          'Nous sommes incapables de compléter ton désengagement. Svp essayer à nouveau via le lien dans le courriel.',
      },
    },
  },
  signIn: {
    title: 'CONNEXION',
    body: 'Tu veux un lien magique pour te connecter instantanément?',
    form: {
      emailLabel: 'Courriel',
      buttonTitle: 'Envoyer lien magique',
      feedback: 'Le courriel est invalide',
    },
    success: {
      title: 'Courriel envoyé!',
      descriptionInstructionStrong: 'En route!',
      descriptionInstruction:
        'Tu recevras un courriel avec un lien magique bientôt.',
      descriptionCheck:
        'Si tu ne le trouves pas dans ta boîte de réception, vérifie dans tes pourriels.',
      buttonTitle: 'Retour',
    },
    notSent: {
      title: 'Courriel non envoyé...',
      description: 'Le courriel n’a pas été envoyé. Essaie encore stp.',
      buttonTitle: 'Retour',
    },
    failure: {
      title: 'Échec de connexion',
      description: 'Nous n’avons pas pu te connecter. Essaie à nouveau!',
      buttonTitle: 'Retour',
    },
    signedIn: {
      title: 'Tu es connecté.e!',
      description:
        'Grâce au lien magique, tu es connecté.e et prêt.e à draguer!',
      buttonTitle: 'On commence?',
    },
  },
  signUp: {
    title: 'Je m’inscris',
    subtitle: 'Pas de mot de passe, pas de problème!',
    body:
      'Confirme ton adresse courriel et on t’enverra un lien magique pour t’inscrire!',
    form: {
      nameLabel: 'Pseudonyme',
      emailLabel: 'Courriel',
      buttonTitle: 'Envoyer lien magique',
      feedback: 'Le courriel est invalide',
    },
    confirmEmail: {
      title: 'Confirme ton courriel',
      description: 'Un courriel de confirmation a été envoyé à cette adresse:',
      descriptionCheck: 'Si tu veux te créer un compte, confirme ici.',
      form: {
        inputLabel: 'Courriel',
        buttonTitle: 'Confirme ton abonnement',
      },
    },
    confirmation: {
      title: 'Confirmation complétée!',
      description: 'Tu as réussi! Ton compte est prêt.',
      descriptionStrong: 'On commence?',
      buttonTitle: 'Crée ton profil',
      optOutStrong: 'Ce n’est pas toi?',
      optOutDescription: 'Tu ne veux plus recevoir ces courriels? ',
      optOut: 'SE DÉSINSCRIRE',
    },
    emailSent: {
      title: 'Courriel envoyé!',
      descriptionStrong: 'En route!',
      descriptionInstruction:
        'Tu recevras un courriel avec un lien magique bientôt.',
      descriptionCheck:
        'Si tu ne le trouves pas dans ta boîte de réception, vérifie dans tes pourriels.',
      buttonTitle: 'Retour',
    },
    failure: {
      title: 'Courriel non envoyé...',
      description: 'Le courriel n’a pas été envoyé. Essaie encore stp.',
      buttonTitle: 'Retour',
    },
  },
  signOut: {
    title: 'Tu es déconnecté.e',
    description: 'Déconnexion effectuée avec succès.',
    buttonTitle: 'Connexion',
    failure: {
      title: 'Échec de déconnexion',
      description: 'Nous n’avons pas pu te déconnecter. Essaie à nouveau!',
    },
  },
  editProfile: {
    titleNew: 'Crée ton profil',
    title: 'Modifie ton profil',
    body: 'Merci de créer ton profil avant de visiter le site.',
    form: {
      basicInfoTitle: 'Infos',
      required: 'requis',
      nameLabel: 'Pseudonyme',
      birthdateLabel: 'Date de naissance',
      birthdatePlaceholder: 'AAAA-MM-JJ',
      genderLabel: 'Genre',
      sexualLabel: 'Orientation sexuelle',
      selectLabel: 'Fais un choix',
      photoTitle: 'Ta photo',
      photoIntro: 'Une photo aidera à attirer l’attention.',
      tellUsTitle: 'Parle-nous de toi',
      headline: 'Description',
      who: 'Qui es-tu ?',
      what: 'Pourquoi es-tu ici?',
      why: 'Que cherches-tu?',
      how: 'Comment aimes-tu flirter?',
      when: 'Quand peux-tu discuter ou rencontrer?',
      else: 'Quelque chose d’autre?',
      buttonTitle: 'Sauvegarder',
      requiredFields:
        'Tu dois donner un nom et une date de naissance de plus de 18 ans.',
      saveErrorTitle: 'Le profil n’a pas été enregistré',
      saveError:
        'Les informations de ton profil n’ont pas été enregistrées. Svp réessayer.',
    },
  },
  deleteProfile: {
    button: 'Effacer le profil?',
    confirm: {
      title: 'Effacer le profil',
      intro: 'Es-tu certain que c’est ce que tu veux?',
      buttonConfirm: 'Oui, je pars',
      buttonDeny: 'Non, je reste',
      error:
        'Il y a eu une erreur dans ta demande d’effacement. Merci de bien vouloir réessayer',
    },
    confirmed: {
      title: 'Confirme la suppression de ton profil',
      intro:
        'On t’a envoyé un courriel pour confirmer la suppresion de ton profil. Merci de suivre le lien pour compléter le processus.',
      buttonBack: 'Retour',
    },
    success: {
      title: 'Profil supprimé',
      intro:
        'Tu as réussi à supprimer ton profil. Au revoir ! Tu nous manqueras.',
      errorTitle: 'Échec de la suppression du profil',
      error:
        'Il y a eu une erreur dans ta demande d’effacement. Merci de bien vouloir réessayer.',
    },
  },
  editLists: {
    favorites: {
      heading: 'Ta liste de préféré-e-s',
      intro: 'Tes profils préférés.',
      error: 'Erreur: le profil préféré n’a pu être enlevé de ta liste.',
    },
    blocked: {
      heading: 'Les bloqué-e-s',
      intro: 'Les profils que tu as bloqués.',
      error: 'Erreur: le profil bloqué n’a pu être enlevé de ta liste.',
    },
    removeButton: 'Supprimer',
  },
  editPhotos: {
    edit: {
      heading: 'Modifie tes photos',
      intro: 'Ajoute ou enlève les photos qui sont sur ton profil.',
      addButton: 'Ajoute une photo',
      removeButton: 'Efface Photo',
      removeError:
        'Une erreur s’est produite lors de la suppression de cette photo. Svp réessayer.',
    },
    upload: {
      heading: 'Télécharge une photo',
      intro:
        'Choisis une photo à télécharger et recadre-la dans le bon format.',
      selectButton: 'Choisis une photo',
      feedback: {
        failed:
          'Le téléchargement de la photo a échoué. Merci de bien vouloir essayer encore une fois.',
        size:
          'La photo choisie est trop volumineuse. Merci de bien vouloir choisir une photo plus petite que 10MB.',
      },
    },
    crop: {
      heading: 'Recarde ta photo',
      saveButton: 'Enregistre ta photo',
      cancelButton: 'Annuler',
      feedback: {
        uploading: 'Téléchargement...',
        resizing: 'Redimension...',
        cropping: 'Recadrement...',
        cancelling: 'Annulation...',
        failed:
          'Le recadrage de la photo a échoué. Merci de bien vouloir essayer encore une fois.',
      },
    },
  },
  profile: {
    status: {
      online: 'en ligne',
      inactive: 'vu récemment',
      offline: 'hors ligne',
    },
    imageCountOf: 'de',
    rating: {
      hot: 'Canon',
      hotMessage: 'est canon!',
      not: 'Torchon',
      notMessage: 'est torchon!',
      saveRating: 'Sauvegarde de ton classement...',
      errorRating: 'Impossible de sauvegarder ton classement.',
      deleteRating: 'Efface ton classement',
    },
    meta: {
      currentUser: 'C’est toi!',
      ageSuffix: 'ans',
    },
    actions: {
      chat: 'Chat',
      fave: 'Pref',
      unfave: 'Non-Pref',
      saveFave: 'Sauvegarde Pref...',
      faveError: 'Impossible de sauvegarder ton pref.',
      block: 'Bye',
      unblock: 'Dé-bloquer',
      saveBlock: 'Sauvegarde profil bloqué...',
      blockError: 'Impossible de sauvegarder le profil bloqué.',
    },
    description: {
      whoLabel: 'Qui?',
      whatLabel: 'Quoi?',
      whyLabel: 'Pourquoi?',
      howLabel: 'Comment?',
      whenLabel: 'Quand?',
      elseLabel: 'P.S.',
    },
    loginDate: 'Vu pour la dernière fois le',
    loginUnavailable: 'Jamais',
    ban: {
      warning: 'Les bannissements sont permanents et ne peuvent être annulés.',
      button: 'Bannir l’utilisateur',
      saving: 'Sauvergarder le bannissement',
      complete: 'bannissement complété',
      error: 'Impossible de bloquer l’utilisateur. Svp essaie à nouveau.',
    },
    selfie: {
      editPhotos: 'Modifie tes photos',
      editProfile: 'Modifie ton profil',
    },
  },
  chat: {
    chatInput: 'Tape un message',
    chatError: 'Le message n’a pas pu être envoyé.',
    emptyChatTitle: 'Aucun message',
    emptyChatDescription:
      'Commence à fleureter avec du monde pour avoir des mises à jour.',
    emptyChatButton: 'Commence à fleureter',
    blockedChatTitle: 'Tu t’es fais',
    blockedChatStrong: 'bloqué.e',
    disabledChatTitle: 'Le chat a été',
    disabledChatStrong: 'Désactivé',
  },
  updates: {
    emptyUpdatesTitle: 'Pas de mises à jour',
    emptyUpdatesDescription:
      'Commence à fleureter avec du monde pour avoir des mises à jour.',
    emptyUpdatesButton: 'Commence à fleureter',
    online: 'est EN LIGNE',
    hot: 't’a déclaré CANON',
    not: 't’a déclaré TORCHON',
  },
  header: {
    signIn: 'Connexion',
    signUp: 'Je m’inscris',
    signOut: 'Déconnexion',
    account: 'Mon compte',
    chat: 'Chat',
    updates: 'Nouveautés',
    chatHeaderTitle: 'Ta boîte de messagerie',
    updatesHeaderTitle: 'Tes mises à jour',
    accountHeaderTitle: 'Ton compte',
  },
  footer: {
    what: 'Qu’est-ce que c’est?',
    link: 'https://2fikornot2fik.com/fr/romance/',
  },
  exhibition: {
    top5: 'Top 5',
  },
  panel: {
    account: {
      profileLabel: 'Ton profil',
      profile: 'Modifie ton profil',
      profile2Fik: 'Modifier le profil de {{name}}',
      photos: 'Modifie tes photos',
      photos2Fik: 'Modifier les photos de {{name}}',
      favourites: 'Ta liste de préfs',
      favourites2Fik: 'Liste de favoris {{name}}',
      blocked: 'Les bloqué-e-s',
      blocked2Fik: 'Liste de blocages {{name}}',
      signOut: 'Déconnexion',
      signOut2Fik: 'Retour aux profils',
      language: 'Choisis ta langue',
    },
  },
  reminder: {
    titleSignIn: 'Un petit rappel',
    titleSignUp: 'Terminé!',
    bodySignIn1: 'Ceci n’est pas une véritable application de rencontre!',
    bodySignIn2:
      'Ceci fait partie d’une oeuvre performative de l’artiste 2Fik dédiée aux 18 ans et plus.',
    bodySignIn3: 'Tu comprends ? Parfait !',
    bodySignInStrong: 'Apprends-en plus sur le projet',
    bodyLink: 'https://2fikornot2fik.com/fr/romance/',
    bodySignUp: 'Un petit rappel: tu participes à une performance artistique.',
    buttonTitle: 'On commence?',
  },
  tos: {
    title: 'Bienvenue à Romanceala2fik',
    topBanner:
      'Les présentes conditions d’utilisation décrivent les règles et règlements relatifs à l’utilisation du site Internet Romanceala2fik, qui fait parti de l’œuvre d’art La Romance est pas morte, 2Fik!.',
    paragraph1:
      'En accédant à ce site web, vous acceptez ces conditions de service dans leur intégralité. Ne continuez pas à utiliser le site web de Romanceala2fik si vous n’acceptez pas toutes les conditions de service énoncées sur cette page.',
    paragraph2:
      'La terminologie suivante s’applique aux présentes conditions de service, à la déclaration de confidentialité et à l’avis de non-responsabilité, ainsi qu’à tout autre accord : “Client”, “Vous” et “Votre” font référence à vous, la personne qui accède à ce site web et qui accepte les conditions de service de la compagnie Toufik avec un deux. “La compagnie”, “Nous”, “Notre”, “Nos” et “Notre”, font référence à notre compagnie. “Partie”, “Parties”, ou “Nous”, désigne à la fois le Client et nous-mêmes, ou soit le Client, soit nous-mêmes. Tous les termes font référence à la participation du Client dans l’œuvre d’art, conformément et sous réserve des règles et lois en vigueur au Québec. Toute utilisation de la terminologie ci-dessus au singulier, au pluriel, en majuscules et/ou il(s)/ elle(s) ou ielle(s) sont considérés comme interchangeables et donc comme se référant à la même chose.',
    cookies: {
      title: 'Témoins de navigation (cookies)',
      paragraph1:
        'Nous utilisons des témoins de navigation (cookies). En utilisant le site Internet de Romanceala2fik, vous consentez à l’utilisation de cookies conformément à notre politique de confidentialité.',
      paragraph2:
        'La plupart des sites web interactifs modernes utilisent des cookies qui permettent de récupérer les détails de l’utilisateur à chaque visite. Les cookies sont utilisés dans certaines sections de notre site pour permettre la fonctionnalité de cette section et la facilité d’utilisation pour les personnes qui la visitent. Certains de nos fournisseurs de services affiliés / tiers peuvent également utiliser des témoins.',
    },
    license: {
      title: 'License',
      paragraph1:
        'Sauf indication contraire, Romanceala2fik et/ou ses concédants de licence détiennent les droits de propriété intellectuelle pour tout le matériel sur Romanceala2fik. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez visualiser et/ou imprimer des pages de romanceala2fik.com pour votre usage personnel sous réserve des restrictions définies dans les présentes conditions d’utilisation.',
      paragraph2: 'Vous ne devez pas :',
      listItem1:
        'Publier à nouveau du matériel provenant de romanceala2fik.com',
      listItem2:
        'Vendre, louer ou sous-licencier le matériel de romanceala2fik.com',
      listItem3:
        'Reproduire, dupliquer ou copier du matériel provenant de romanceala2fik.com',
      listItem4:
        'Redistribuer le contenu de Romanceala2fik (sauf si le contenu est spécifiquement fait pour être redistribué).',
    },
    user: {
      title: 'Commentaires des utilisateurs',
      userListItem1:
        'Le présent accord prend effet à la date de consultation du site web.',
      userListItem2:
        'Certaines parties de ce site web offrent aux utilisateurs la possibilité de publier et d’échanger des opinions, des informations, du matériel et des données (commentaires et discussions) dans certaines sections du site. Romanceala2fik ne filtre pas, n’édite pas, ne publie pas ou ne révise pas les commentaires et discussions avant leur apparition sur le site web et ceux-ci ne reflètent pas les vues ou les opinions de Romanceala2fik, de ses agents ou de ses partenaires. Les commentaires et discussions reflètent le point de vue et l’opinion de la personne qui publie ce point de vue ou cette opinion. Dans la mesure permise par les lois applicables, Romanceala2fik ne sera pas responsable des commentaires et discussions ou de tout coût de perte, responsabilité, dommages ou dépenses causés et ou subis suite à l’utilisation et/ou la publication et/ou l’apparition de ceux-ci sur ce site web.',
      userListItem3:
        'Romanceala2fik se réserve le droit de surveiller tous les commentaires et discussions et de supprimer tout ceux qu’il considère, à son entière discrétion, comme inappropriés, offensants ou autrement en violation des présentes conditions d’utilisation.',
      userListItem4: 'Vous certifiez et déclarez que:',
      nestedListItem1:
        'Vous êtes autorisé à publier sur notre site web et avez fourni toutes les informations requises pour ce faire;',
      nestedListItem2:
        'Les propos n’enfreignent aucun droit de propriété intellectuelle, y compris, sans limitation, les droits d’auteur, les brevets ou les marques déposées, ou tout autre droit de propriété d’un tiers;',
      nestedListItem3:
        'Les propos ne contiennent aucun matériel diffamatoire, calomnieux, offensant, indécent ou autrement illégal, ni aucun matériel constituant une atteinte à la vie privée.',
      nestedListItem4:
        'Les propos ne seront pas utilisés pour solliciter ou promouvoir des affaires ou des coutumes ou présenter des activités commerciales ou des activités illégales.',
      userListItem5:
        'Vous accordez par la présente à Romanceala2fik une licence non exclusive et libre de droits d’utilisation, de reproduction, d’édition et d’autorisation d’autres personnes à utiliser, reproduire et éditer l’un de vos propos sous quelque forme, format ou média que ce soit, y compris lors des représentations publiques de La Romance est pas morte, 2Fik!.',
    },
    hyperlink: {
      title: 'Hyperliens vers notre contenu',
      listItem1: {
        body:
          'Les organisations suivantes peuvent créer des liens vers notre site web sans autorisation écrite préalable:',
        nestedListItem1: 'Les organismes gouvernementaux;',
        nestedListItem2: 'Nos partenaires financiers;',
        nestedListItem3: 'Les diffuseurs des spectacles de 2Fik;',
        nestedListItem4: 'Les moteurs de recherche;',
        nestedListItem5: 'Médias; et',
        nestedListItem6:
          'Les distributeurs d’annuaires en ligne, lorsqu’ils nous inscrivent dans l’annuaire, peuvent établir un lien vers notre site web de la même manière qu’ils établissent un hyperlien vers les sites web d’autres entreprises inscrites.',
      },
      listItem2:
        'Ces organisations peuvent créer des liens vers notre page d’accueil, vers des publications ou vers d’autres informations sur le site web, tant que le lien est bien établi et qu’il : a) ne soit pas trompeur; b) n’implique pas faussement le parrainage, l’approbation ou l’aval de la partie qui établit le lien et de ses produits ou services; et c) s’inscrive dans le contexte du site de la partie qui établit le lien.',
      listItem3: {
        body:
          'Nous pouvons examiner et approuver, à notre entière discrétion, d’autres demandes de liens provenant des types d’organisations suivants: ',
        nestedListItem1: 'sites communautaires; ',
        nestedListItem2:
          'associations ou autres groupes représentant des organismes caritatifs,',
        nestedListItem3: 'les distributeurs d’annuaires en ligne; et ',
        nestedListItem4: 'les portails internet.',
      },
      paragraph1:
        'Nous approuverons les demandes de liens de ces organisations si nous le jugeons que : (a) le lien n’aura pas une incidence négative sur nous ou sur nos entreprises accréditées (par exemple, les associations commerciales ou autres organisations représentant des types d’entreprises intrinsèquement suspects, comme les possibilités de travail à domicile, ne seront pas autorisées à établir un lien); (b) l’organisation n’a pas un dossier insatisfaisant avec nous; (c) le bénéfice que nous tirons de la visibilité associée à l’hyperlien l’emporte sur l’absence de Romanceala2fik; et (d) lorsque le lien se situe dans le contexte d’informations de ressources générales ou est autrement cohérent avec le contenu éditorial d’un bulletin d’information ou d’un produit similaire favorisant la mission de l’organisation.',
      paragraph2:
        'Si vous faites partie des organisations énumérées au paragraphe 3 ci-dessus et que vous souhaitez créer un lien vers notre site web, vous devez nous en informer en envoyant un courrier électronique à info.2fik@gmail.com. Veuillez indiquer votre nom, le nom de votre organisation, vos coordonnées (telles qu’un numéro de téléphone et/ou une adresse électronique) ainsi que l’URL de votre site, une liste des URL à partir desquelles vous avez l’intention d’établir un lien vers notre site web, et une liste des URL de notre site vers lesquelles vous souhaitez établir un lien. Prévoyez un délai de 2 à 3 semaines pour la réponse.',
      paragraph3: {
        body:
          'Les organisations agréées peuvent créer des liens hypertextes vers notre site web de la manière suivante:',
        listItem1: 'En utilisant notre dénomination sociale; ou',
        listItem2:
          'En utilisant le localisateur de ressources uniformes (adresse Internet) auquel il est lié; ou',
        listItem3:
          'En utilisant notre site Web ou tout autre matériel lié à celui-ci qui a un sens dans le contexte et le format du contenu du site de la partie qui a établi le lien.',
      },
      paragraph4:
        'Aucune utilisation du logo de Romanceala2fik ou d’autres illustrations ne sera autorisée pour la création de liens en l’absence d’un accord de licence de marque.',
    },
    iframe: {
      title: 'Cadre en ligne (Iframes)',
      body:
        'Sans autorisation préalable et écrite expresse, vous ne pouvez pas créer de cadres autour de nos pages Web ou utiliser d’autres techniques qui modifient de quelque manière que ce soit la présentation visuelle ou l’apparence de notre site Web.',
    },
    liability: {
      title: 'Responsabilité du contenu',
      body:
        'Nous n’avons aucune responsabilité quant au contenu qui apparaît sur votre site web. Vous acceptez de retirer immédiatement tout lien / contenu nous appartenant si demandé. Aucun lien ne peut apparaître sur une page de votre site web ou dans un contexte contenant un contenu ou des éléments pouvant être interprétés comme diffamatoires, obscènes ou criminels, ou qui enfreint, viole ou préconise l’infraction ou toute autre violation des droits d’un tiers.',
    },
    reservation: {
      title: 'Droits réservés',
      body:
        'Nous nous réservons le droit, à tout moment et à notre seule discrétion, de vous demander de supprimer tous les liens ou un lien particulier vers notre site Web. Vous acceptez de supprimer immédiatement tous les liens vers notre site Web à la suite d’une telle demande. Nous nous réservons également le droit de modifier à tout moment les présentes conditions d’utilisation et notre politique en matière de liens. En continuant à établir des liens vers notre site Web, vous acceptez d’être lié à ces conditions de service et de les respecter.',
    },
    removal: {
      title: 'Suppression des liens de notre site web',
      paragraph1:
        'Si vous estimez qu’un lien sur notre site web ou tout autre site web lié est répréhensible pour une raison quelconque, contactez-nous à ce sujet. Nous examinerons les demandes de suppression de liens, mais nous n’aurons aucune obligation ni de le faire ni de vous répondre directement.',
      paragraph2:
        'Bien que nous nous efforcions de veiller à ce que les informations figurant sur ce site web soient correctes, nous ne garantissons pas leur exhaustivité ou leur exactitude; nous ne nous engageons pas non plus à faire en sorte que le site web reste disponible ou que le matériel qui s’y trouve soit maintenu à jour.',
    },
    disclaimer: {
      title: 'Clause de non-responsabilité',
      paragraph1:
        'Dans la mesure permise par la loi applicable, nous excluons toutes les déclarations, garanties et conditions relatives à notre site web et à l’utilisation de ce site (y compris, sans limitation, toute garantie implicite de la loi concernant la qualité satisfaisante, l’aptitude à l’emploi et/ou l’utilisation de soins et de compétences raisonnables). Rien dans la présente clause de non-responsabilité: ',
      paragraph2:
        'Les limitations et exclusions de responsabilité énoncées dans la présente section et ailleurs dans la présente clause de non-responsabilité : (a) sont soumises au paragraphe précédent; et (b) régissent toutes les responsabilités découlant de la clause de non-responsabilité ou en rapport avec l’objet de la présente clause de non-responsabilité, y compris les responsabilités contractuelles, délits (y compris la négligence) et pour manquement à une obligation légale.',
      paragraph3:
        'Dans la mesure où le site web et les informations et services qu’il contient sont fournis gratuitement, nous ne sommes pas responsables des pertes ou dommages de quelque nature que ce soit.',
      listItem1:
        'ne limite ou n’exclut notre ou votre responsabilité en cas de décès ou de blessure corporelle résultant d’une négligence;',
      listItem2:
        'ne limite ni n’exclut notre ou votre responsabilité en cas de fraude ou de déclaration frauduleuse;',
      listItem3:
        'ne limite notre ou vos responsabilités d’une manière qui n’est pas autorisée par la loi applicable; ou',
      listItem4:
        'n’exclut toute responsabilité de notre part ou de la vôtre qui ne peut être exclue en vertu du droit applicable.',
    },
    participation: {
      title: 'Participation à l’œuvre d’art',
      body:
        'En acceptant ces conditions d’utilisation, je comprends que je participe à l’application de rencontre Romance à la 2Fik, qui fait parti de l’œuvre d’art La Romance est pas morte, 2Fik!. Cette participation peut entraîner l’utilisation et/ou la projection de toute information (mots, photos ou images) que j’ai soumise au site web dans le cadre de mon profil ou dans le cadre d’une interaction que j’ai faite sur la plate-forme. Ces informations peuvent être utilisées dans le cadre de la ou des représentations publiques liées à l’œuvre d’art.',
    },
    credit: {
      title: 'Informations de crédit et de contact',
      body:
        'Cette page de conditions de service a été créée sur le générateur de conditions générales de privacyterms.io. Si vous avez des questions concernant l’une de nos conditions, veuillez nous contacter.',
    },
    bottomBanner:
      'Cette performance ne convient qu’aux 18 ans et plus. Veuillez confirmer votre âge ci-dessous.',
    checkBox1: 'Je confirme que j’ai 18 ans ou plus',
    checkBox2:
      'J’accepte ces conditions, qui incluent l’utilisation de mes images à des fins de performance.',
    buttonTitle: 'accepter les termes',
  },
};
