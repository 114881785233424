import classnames from 'classnames';
import { useSelect } from 'downshift';
import './SelectInput.scss';
import { Icon } from '../Icon/Icon';

export interface SelectInputOption {
  value: string;
  jsx: JSX.Element;
}

export interface SelectProps {
  buttonLabel: string;
  className?: string;
  initial?: string;
  options: SelectInputOption[];
  handleSelect: (value: string) => void;
}

export function SelectInput(props: SelectProps) {
  const { buttonLabel, className, options, initial, handleSelect } = props;

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    // TODO: Do you want to use these?
    //getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options.map((o) => o.value),
    initialSelectedItem: initial,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        handleSelect(changes.selectedItem);
      }
    },
  });

  const find = (value: string | null) => (o: SelectInputOption) =>
    o.value === value;

  const hasOptionText = (value: string | null) => {
    return options.findIndex(find(value)) >= 0;
  };

  const getOptionText = (value: string | null) => {
    return options.find(find(value))?.jsx;
  };

  return (
    <div
      className={classnames(
        className,
        'select-input',
        isOpen ? 'select-input--open' : 'select-input--closed'
      )}
    >
      <button
        {...getToggleButtonProps()}
        className="select-input__label-button"
      >
        {hasOptionText(selectedItem)
          ? getOptionText(selectedItem)
          : buttonLabel}
        <Icon theme="default" name={isOpen ? 'close' : 'arrow-down'} />
      </button>
      <ul
        {...getMenuProps()}
        className={classnames('select-input__option-list', {
          'select-input--open': isOpen,
        })}
      >
        {isOpen &&
          options.map((option, index) => (
            <li className="select-input__option" key={option.value}>
              <button
                className={classnames('select-input__option-button', {
                  'select-input--highlighted': index === highlightedIndex,
                })}
                {...getItemProps({ item: option.value, index })}
              >
                {option.jsx}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
