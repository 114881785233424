import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Actor as SignUpActor } from '../../machines/MagicLinkMachine';
import Button from '../Button/Button';
import { Feedback } from '../Feedback/Feedback';
import { Form } from '../Form/Form';
import { Fieldset } from '../Form/Fieldset';
import { TextInput } from '../Form/TextInput';
import { Confirmation } from '../Confirmation/Confirmation';
import { Loader } from '../Loader/Loader';
import { CheckboxInput } from '../Form/CheckboxInput';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';

import './SignUp.scss';

export function SignUp({ actor }: { actor: SignUpActor }) {
  const [state, send] = useActor(actor);
  const { t } = useTranslation();

  switch (true) {
    case state.matches('idle'):
      return (
        <main className="signup">
          <Helmet>
            <title>
              {t('title')} - {t('signUp.title')}
            </title>
          </Helmet>
          <h1 className="heading signup__heading">{t('signUp.title')}</h1>
          <h3 className="item-heading signup__subheading">
            {t('signUp.subtitle')}
          </h3>
          <p className="signup__description">{t('signUp.body')}</p>
          <div className="signup__form">
            <Form
              className="form__container"
              onSubmit={() => send({ type: 'sendSignInLink' })}
            >
              <Fieldset label={t('signUp.form.nameLabel')}>
                <TextInput
                  name="name"
                  value={state.context.name}
                  type="text"
                  onChange={(event) =>
                    send({ type: 'setName', name: event.target.value })
                  }
                />
              </Fieldset>
              {state.matches('idle.invalid') && (
                <Feedback className="signup__feedback">
                  {t('signUp.form.feedback')}
                </Feedback>
              )}
              <Fieldset label={t('signUp.form.emailLabel')}>
                <TextInput
                  name="email"
                  value={state.context.email}
                  type="email"
                  onChange={(event) =>
                    send({ type: 'setEmail', email: event.target.value })
                  }
                />
              </Fieldset>
              <Button
                className="signup__button"
                buttonType="primary"
                type="submit"
              >
                {t('signUp.form.buttonTitle')}
              </Button>
            </Form>
          </div>
        </main>
      );

    case state.matches('agreeToTOS'):
      return (
        <Confirmation className="confirmation-tos" pageType="wide-button">
          <ScrollToTop />
          <h1 className="heading">{t('tos.title')}</h1>
          <Feedback msgType="strong" className="tos__highlight">
            {t('tos.topBanner')}
          </Feedback>
          <p className="paragraph">{t('tos.paragraph1')}</p>
          <p className="paragraph">{t('tos.paragraph2')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.cookies.title')}
          </h2>
          <p className="paragraph">{t('tos.cookies.paragraph1')}</p>
          <p className="paragraph">{t('tos.cookies.paragraph2')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.license.title')}
          </h2>
          <p className="paragraph">{t('tos.license.paragraph1')}</p>
          <p className="paragraph">{t('tos.license.paragraph2')}</p>
          <ol className="list list--ol">
            <li>{t('tos.license.listItem1')}</li>
            <li>{t('tos.license.listItem2')}</li>
            <li>{t('tos.license.listItem3')}</li>
            <li>{t('tos.license.listItem4')}</li>
          </ol>
          <h2 className="subheading tos__subheading">{t('tos.user.title')}</h2>
          <ol className="list list--ol">
            <li>{t('tos.user.userListItem1')}</li>
            <li>{t('tos.user.userListItem2')}</li>
            <li>{t('tos.user.userListItem3')}</li>
            <li>{t('tos.user.userListItem4')}</li>
            <ol className="list list--ol">
              <li>{t('tos.user.nestedListItem1')}</li>
              <li>{t('tos.user.nestedListItem2')}</li>
              <li>{t('tos.user.nestedListItem3')}</li>
              <li>{t('tos.user.nestedListItem4')}</li>
            </ol>
            <li>{t('tos.user.userListItem5')}</li>
          </ol>
          <h2 className="subheading tos__subheading">
            {t('tos.hyperlink.title')}
          </h2>
          <ol className="list list--ol">
            <li>{t('tos.hyperlink.listItem1.body')}</li>
            <ol className="list list--ol">
              <li>{t('tos.hyperlink.listItem1.nestedListItem1')}</li>
              <li>{t('tos.hyperlink.listItem1.nestedListItem2')}</li>
              <li>{t('tos.hyperlink.listItem1.nestedListItem3')}</li>
              <li>{t('tos.hyperlink.listItem1.nestedListItem4')}</li>
              <li>{t('tos.hyperlink.listItem1.nestedListItem5')}</li>
              <li>{t('tos.hyperlink.listItem1.nestedListItem6')}</li>
            </ol>
            <li>{t('tos.hyperlink.listItem2')}</li>
            <li>{t('tos.hyperlink.listItem3.body')}</li>
            <ol className="list list--ol">
              <li>{t('tos.hyperlink.listItem3.nestedListItem1')}</li>
              <li>{t('tos.hyperlink.listItem3.nestedListItem2')}</li>
              <li>{t('tos.hyperlink.listItem3.nestedListItem3')}</li>
              <li>{t('tos.hyperlink.listItem3.nestedListItem4')}</li>
            </ol>
          </ol>
          <p className="paragraph">{t('tos.hyperlink.paragraph1')}</p>
          <p className="paragraph">{t('tos.hyperlink.paragraph2')}</p>
          <p className="paragraph">{t('tos.hyperlink.paragraph3.body')}</p>
          <ol className="list list--ol">
            <li>{t('tos.hyperlink.paragraph3.listItem1')}</li>
            <li>{t('tos.hyperlink.paragraph3.listItem2')}</li>
            <li>{t('tos.hyperlink.paragraph3.listItem3')}</li>
          </ol>
          <p className="paragraph">{t('tos.hyperlink.paragraph4')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.iframe.title')}
          </h2>
          <p className="paragraph">{t('tos.iframe.body')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.liability.title')}
          </h2>
          <p className="paragraph">{t('tos.liability.body')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.reservation.title')}
          </h2>
          <p className="paragraph">{t('tos.reservation.body')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.removal.title')}
          </h2>
          <p className="paragraph">{t('tos.removal.paragraph1')}</p>
          <p className="paragraph">{t('tos.removal.paragraph2')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.disclaimer.title')}
          </h2>
          <p className="paragraph">{t('tos.disclaimer.paragraph1')}</p>
          <ol className="list list--ol">
            <li>{t('tos.disclaimer.listItem1')}</li>
            <li>{t('tos.disclaimer.listItem2')}</li>
            <li>{t('tos.disclaimer.listItem3')}</li>
            <li>{t('tos.disclaimer.listItem4')}</li>
          </ol>
          <p className="paragraph">{t('tos.disclaimer.paragraph2')}</p>
          <p className="paragraph">{t('tos.disclaimer.paragraph3')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.participation.title')}
          </h2>
          <p className="paragraph">{t('tos.participation.body')}</p>
          <h2 className="subheading tos__subheading">
            {t('tos.credit.title')}
          </h2>
          <p className="paragraph">{t('tos.credit.body')}</p>
          <Feedback msgType="strong" className="tos__highlight">
            {t('tos.bottomBanner')}
          </Feedback>
          <CheckboxInput
            name="test1"
            value="yur"
            checked={state.context.is18}
            onClick={() => send({ type: 'setAge' })}
          >
            {t('tos.checkBox1')}
          </CheckboxInput>
          <CheckboxInput
            name="test2"
            value="yur"
            checked={state.context.isAgreedTo}
            onClick={() => send({ type: 'setAgreedTo' })}
          >
            {t('tos.checkBox2')}
          </CheckboxInput>
          <Button
            className="tos__button"
            buttonType="primary"
            onClick={() => send({ type: 'sendSignInLink' })}
            disabled={!(state.context.is18 && state.context.isAgreedTo)}
          >
            {t('tos.buttonTitle')}
          </Button>
        </Confirmation>
      );
    case state.matches('sending'):
      return <Loader theme="full-page" />;

    case state.matches('success'):
      return (
        <main className="email-sent">
          <Confirmation pageType="default">
            <h1 className="heading">{t('signUp.emailSent.title')}</h1>
            <p>
              <strong>{t('signUp.emailSent.descriptionStrong')}</strong>
              &nbsp;{t('signUp.emailSent.descriptionInstruction')}
            </p>
            <p>{t('signUp.emailSent.descriptionCheck')}</p>
            <Button
              onClick={() => send({ type: 'done' })}
              buttonType="secondary"
            >
              {t('signUp.emailSent.buttonTitle')}
            </Button>
          </Confirmation>
        </main>
      );

    case state.matches('failure'):
      return (
        <main className="email-failure">
          <Confirmation>
            <h1 className="heading">{t('signUp.failure.title')}</h1>
            <p>{t('signUp.failure.description')}</p>
            <Button onClick={() => send({ type: 'done' })}>
              {t('signUp.failure.buttonTitle')}
            </Button>
          </Confirmation>
        </main>
      );

    default:
      return null;
  }
}
